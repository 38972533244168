var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('show-code',{attrs:{"show":_vm.showCode,"code":_vm.code,"condition":_vm.condition,"logoURL":_vm.shopLogo,"rewardName":_vm.selectedRewardName,"showCondition":true,"mode":"otpCheck"},on:{"update:show":function($event){_vm.showCode=$event}}}),_c('div',{staticClass:"history-title"},[_vm._v("ประวัติการแลกของ")]),(_vm.isLoading)?_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":"","height":"6"}}):_c('v-tabs',{staticStyle:{"margin-left":"-0.8rem","width":"107%"},attrs:{"fixed-tabs":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(item){return _c('v-tab',{key:item.tab},[_vm._v(" "+_vm._s(item.name)+" ")])}),1),(_vm.isLoading)?_c('v-skeleton-loader',{staticStyle:{"margin-top":"32px"},attrs:{"type":"list-item@3"}}):_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(item){return _c('v-tab-item',{key:item.tab},[(item.list.length > 0)?_c('v-row',{attrs:{"dense":""}},_vm._l((item.list),function(coupon,i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[_c('v-card',{staticClass:"coupon",class:{
              'custom-disable': item.name!=='ใช้งานได้',
            },attrs:{"elevation":"0"},on:{"click":function($event){return _vm.showCouponCode(coupon)}}},[_c('v-list-item',{staticStyle:{"padding":"0px"}},[_c('v-list-item-avatar',{staticClass:"coupon-avatar",class:{
                  'coupon-disable': item.name!=='ใช้งานได้',
                },attrs:{"tile":"","width":"126","height":"81"}},[_c('v-img',{attrs:{"src":coupon.img}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"coupon-title",class:{
                    'coupon-disable': item.name!=='ใช้งานได้',
                  }},[_vm._v(" "+_vm._s(coupon.coupon_name)+" ")]),_c('div',{staticClass:"coupon-detail",class:{
                    'coupon-disable': item.name!=='ใช้งานได้',
                  }},[_c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"mr-2"},[_vm._v("แลกเมื่อ")]),_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.momentDate(coupon.receive_datetime)))]),_c('span',[_vm._v(_vm._s(_vm.momentTime(coupon.receive_datetime)))])]),(item.name==='ใช้แล้ว')?_c('div',[_c('span',{staticClass:"mr-2"},[_vm._v("ใช้เมื่อ")]),_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.momentDate(coupon.used_datetime)))]),_c('span',[_vm._v(_vm._s(_vm.momentTime(coupon.used_datetime)))])]):(_vm.isCouponRevoked(coupon))?_c('div',[_vm._v(" คูปองถูกยกเลิก ")]):(item.name==='หมดอายุแล้ว')?_c('div',[_c('span',{staticClass:"mr-2"},[_vm._v("หมดอายุ")]),_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.momentDate(coupon.expired_datetime)))]),_c('span',[_vm._v(_vm._s(_vm.momentTime(coupon.expired_datetime)))])]):(
                    coupon.is_no_expire_limit && (
                      coupon.is_no_expire_use_date === undefined || 
                      coupon.is_no_expire_use_date === true
                    )
                  )?_c('div',[_vm._v(" ไม่มีวันหมดอายุการใช้งาน ")]):_c('div',[_c('span',{staticClass:"mr-2"},[_vm._v("ใช้ได้ถึง")]),_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.momentDate(coupon.expired_datetime)))]),_c('span',[_vm._v(_vm._s(_vm.momentTime(coupon.expired_datetime)))])])])],1)],1)],1)],1)}),1):_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"no-coupon",attrs:{"align":"center","justify":"center"}},[(item.name==='ใช้งานได้')?_c('div',[_c('span',[_vm._v("คุณยังไม่ได้แลกรีวอร์ด")])]):(item.name==='ใช้แล้ว')?_c('div',[_c('span',[_vm._v("คุณยังไม่ได้ใช้รีวอร์ด")])]):(item.name==='หมดอายุแล้ว')?_c('div',[_c('span',[_vm._v("คุณยังไม่มีรีวอร์ดที่หมดอายุ")])]):_vm._e()])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }