



















import { Component, Vue, Watch } from "vue-property-decorator";
import AppBar from "@/components/AppBar.vue";
import { Route } from "vue-router";
import { mapGetters } from "vuex";

@Component({
  components: {
    AppBar,
  },
  computed: {
    ...mapGetters("brand", ["isSingleBranch"]),
    ...mapGetters("branch", ["branchBrandLogo"]),
  },
})
export default class Branch extends Vue {
  shopLogoUrl: string = require(`@/assets/img/sable_logo.svg`);
  sableDefault: string = require(`@/assets/img/sable_default.svg`);
  appBarMode: string = "home";
  isSingleBranch!: boolean;
  branchBrandLogo!: string;
  isShowContentHeader: boolean = true;
  backRouterMapping: any = {
    Branch_GivePoint: "Branch_Menu",
    Branch_RedeemCoupon: "Branch_Menu",
    Branch_CouponDetail: "Branch_Menu",
    Branch_History: "Branch_Menu",
    Branch_ActiveQR: "Branch_Menu",
  };

  get shopLogo () {
    if(this.branchBrandLogo){
      return this.branchBrandLogo;
    }
    return this.sableDefault
  }

  mounted() {
    this.updateAppBar(this.$router.currentRoute.name + "");
    if(this.$route.name != "Branch_QR"){
      this.$store.dispatch("branch/fetchBranchProfile");
    }
  }

  

  @Watch("$route")
  onChildChanged(to: Route, from: Route) {
    this.updateAppBar(to.name + "");
    this.$store.dispatch("branch/fetchBranchProfile");
  }

  updateAppBar(routeName: string) {
    this.appBarMode = routeName == "Branch_Menu" ? "home" : "backward";
    console.log(routeName);
    if (routeName === "Branch_CouponDetail") {
      this.isShowContentHeader = false;
    } else {
      this.isShowContentHeader = true;
    }
  }

  back() {
    if(
        (
          this.$route.name === 'Branch_RedeemCoupon' 
          || this.$route.name === 'Branch_GivePoint'
          || this.$route.name === 'Branch_ActiveQR'
          || this.$route.name === 'Branch_ConfirmQR'
        ) 
        && this.isSingleBranch
      ) {
      this.$router.push({ name: 'ShopOwner' });
    }else {
      const backRouteName = this.backRouterMapping[this.$route.name + ""];
      if (backRouteName) {
        this.$router.push({ name: backRouteName });
      } else {
        this.$router.go(-1);
      }
    }
  }

  logout() {
    this.$store.dispatch("branch/logout");
  }
}
