






































import { Component, Vue } from "vue-property-decorator";
import CustomerAPIConnector from "@/service/CustomerAPIConnector";

import loyaltyConfig from '../../../loyalty_config.json'

@Component({
})
export default class CustomerCheckPhone extends Vue {
  // phoneImg: string = require(`@/assets/img/phone.png`);
  valid: boolean = true
  telNo: string = ""
  errorMessages: string = ""
  //@ts-ignore
  liff: Liff = this.$liff;
  rules:Array<Function> = [
    (value: string) => !!value || '* กรุณากรอกเบอร์โทรศัพท์',
    (value: string) => !!(new RegExp('^([0]){1}([689]){1}([0-9]){8}$')).test(value) || '* เบอร์โทรศัพท์ของคุณไม่ถูกต้อง'
  ]

  
  get phoneImg(): string {
    const backgroundColor1 = loyaltyConfig?.phone_icon?.background_color_1 || '#FFF1EF'
    const backgroundColor2 = loyaltyConfig?.phone_icon?.background_color_2 || '#FFE6E2'
    const phoneColor = loyaltyConfig?.phone_icon?.phone_color || '#FF5733'

    const phoneDynamicImg = `<svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="33.5" cy="33.5" r="33.5" fill="${backgroundColor1}"/>
    <circle cx="34" cy="33" r="25" fill="${backgroundColor2}"/>
    <path d="M44.0004 37.9201V40.9201C44.0016 41.1986 43.9445 41.4743 43.8329 41.7294C43.7214 41.9846 43.5577 42.2137 43.3525 42.402C43.1473 42.5902 42.905 42.7336 42.6412 42.8228C42.3773 42.912 42.0978 42.9452 41.8204 42.9201C38.7433 42.5857 35.7874 41.5342 33.1904 39.8501C30.7742 38.3148 28.7258 36.2663 27.1904 33.8501C25.5004 31.2413 24.4487 28.2711 24.1204 25.1801C24.0954 24.9036 24.1283 24.6249 24.2169 24.3617C24.3055 24.0986 24.448 23.8568 24.6352 23.6517C24.8224 23.4466 25.0502 23.2828 25.3042 23.1706C25.5582 23.0584 25.8328 23.0004 26.1104 23.0001H29.1104C29.5957 22.9953 30.0662 23.1672 30.4342 23.4836C30.8022 23.8001 31.0425 24.2395 31.1104 24.7201C31.237 25.6802 31.4719 26.6228 31.8104 27.5301C31.945 27.888 31.9741 28.277 31.8943 28.651C31.8146 29.0249 31.6293 29.3682 31.3604 29.6401L30.0904 30.9101C31.514 33.4136 33.5869 35.4865 36.0904 36.9101L37.3604 35.6401C37.6323 35.3712 37.9756 35.1859 38.3495 35.1062C38.7235 35.0264 39.1125 35.0556 39.4704 35.1901C40.3777 35.5286 41.3204 35.7635 42.2804 35.8901C42.7662 35.9586 43.2098 36.2033 43.527 36.5776C43.8441 36.9519 44.0126 37.4297 44.0004 37.9201Z" stroke="${phoneColor}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `

    return 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(phoneDynamicImg)
  }

  async confirmTelNo() {
    //@ts-ignore
    if(this.$refs.form.validate()){
      const accessToken = this.liff.getAccessToken() + "";
      const idToken = this.liff.getIDToken() + "";
      const response = await CustomerAPIConnector.checkIsOldUser(this.telNo, accessToken, idToken)
      this.$store.commit('customer/SET_USER_PROFILE_PHONE', this.telNo)
      if(response.isOldUser){
        this.$store.commit('customer/SET_USER_ID', response.id)
        this.$store.commit('customer/SET_IS_OLD_USER', true)
        this.$router.replace({ name: "Customer_OTP" });
      } else {
        if(response.id != null) {
          this.valid = false
          this.errorMessages = "เบอร์โทรศัพท์นี้ได้ถูกลงทะเบียนเรียบร้อยแล้ว"
        }else {
          this.$router.replace({ name: "Customer_Register" });
        }
      }
    }
  }

  handleChange () {
    if (this.errorMessages != "") {
      this.errorMessages = ""
    }
  }

}
