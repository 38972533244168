















import { Vue, Prop, Component } from "vue-property-decorator";

@Component
export default class BackIcon extends Vue {
  @Prop({ default: 15 })
  width!: number;
  @Prop({ default: 17 })
  height!: number;
  @Prop({ default: "#FF5733" })
  color!: string;
}
