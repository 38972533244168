




import { Component, Vue } from "vue-property-decorator";
import Loading from "@/components/Loading.vue";

@Component({
  components: { Loading },
})
export default class RedeemReward extends Vue {
  mounted() {
    this.$router.push({ name: 'Branch_RedeemCoupon', params: { rewardCode: this.$route.params.rewardCode}})
  }
}
