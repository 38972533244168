





























































































































import { Component, Vue } from "vue-property-decorator";
import AppBar from "@/components/AppBar.vue";
import Loading from "@/components/Loading.vue";
import CustomerAPIConnector from "@/service/CustomerAPIConnector";
import { mapGetters } from "vuex";
import { CustomerProfile } from "@/common/type";
import ConsentModal from "@/components/register/ConsentModal.vue";
import BaseAPI from "@/service/BasedAPIConnector";
import Cookies from 'js-cookie';
    
import loyaltyConfig from "../../loyalty_config.json";

@Component({
  components: {
    AppBar,
    ConsentModal,
    Loading
  },
  computed: {
    ...mapGetters("customer", ["userProfile"]),
    aipenLogoURL() {
      if (!loyaltyConfig?.loyalty_logo || loyaltyConfig?.loyalty_logo === "") {
        return require(`@/assets/img/sable_logo.svg`);
      }
      return loyaltyConfig.loyalty_logo
    }
  },
})
export default class ShopList extends Vue {
  loading = false;
  searchText = "";
  shopList: any = [];
  shopListActualData: any = [];
  // aipenLogoURL: string = require(`@/assets/img/sable_logo.svg`);
  sableDefault: string = require(`@/assets/img/sable_default.svg`);
  marketIcon: string = require(`@/assets/img/market.svg`);
  isGoToRewards = false;
  userProfile!: CustomerProfile
  isShowConsentModal: boolean = false;
  isGoToShop: boolean = true;
  dialog: boolean = false;
  //@ts-ignore
  liff: Liff = this.$liff;

  aipenLogoURL!: string;

  async mounted() {
    this.loading = true;
    this.isGoToRewards = this.$route.query.toRewards == "true";
    const shopIdToRedirect = this.$route?.query?.shopId || loyaltyConfig?.brand_id;
    if(shopIdToRedirect){
      localStorage.setItem("redirect_shopId", JSON.stringify(shopIdToRedirect));
      this.isGoToShop = true
    } else {
      this.isGoToShop = false
    }
    // next tick is needed because we need to login after line liff init
    this.$nextTick(async () => {
      this.$store.dispatch(
        "customer/lineLogin",
        async (isRegister: boolean) => {
          const isLoggedIn = this.$store.getters["customer/isLoggedIn"];
          console.log("Shop List Callback from line login invoke");
          console.log(`Customer isLoggedIn: ${isLoggedIn}`);
          if (isLoggedIn) {
            localStorage.setItem("mode", "CUSTOMER");
            this.liff.getProfile().then((profile: any) => {
              CustomerAPIConnector.updateLineUserData(profile)
            });
            const shopIdFormLocalStorage = JSON.parse(localStorage.getItem("redirect_shopId") + "")
            if (shopIdToRedirect) { 
              this.goToShop(shopIdToRedirect + "");
            }else if(shopIdFormLocalStorage){
              this.goToShop(shopIdFormLocalStorage + "");
            }
            localStorage.removeItem("redirect_shopId");

            const qrCookie = Cookies.get('rod')
            if (qrCookie) {
              let qrData = await BaseAPI.getQRData(qrCookie)
              Cookies.remove('rod')
              this.$router.push({ name: "Customer_Home", params: { brandId: qrData.data[0].brand_id }, query: { qr: qrCookie } });
            }
            this.shopList = await CustomerAPIConnector.getCustomerShopList("");
            if(!this.userProfile.consent_accept) {
              this.toggleConsentModal(true)
            }
            this.loading = false;
          } else {
            if (isRegister) {
              console.log("Create new account");
            } else {
              this.$store.dispatch(
                "alert/error",
                "Login failed, Please try again"
              );
            }
          }
        }
      );
    });
    if (this.$route.query.qr === "failed") {
      this.dialog = true;
    }
  }

  toggleConsentModal (value: boolean) {
    this.isShowConsentModal = value
  }

  async searchingData() {
    this.loading = true;
    this.shopList = [];
    this.shopList = await CustomerAPIConnector.getCustomerShopList(
      this.searchText
    );
    this.loading = false;
  }

  back() {
    this.$router.go(-1);
  }

  goToShop(brandId: string) {
    if (this.isGoToRewards) {
      this.$router.push({ name: "Customer_Reward", params: { brandId } });
    } else {
      this.$router.push({ name: "Customer_Home", params: { brandId } });
    }
  }
}
