














import { Component, Vue } from "vue-property-decorator";
import {  BrandData } from "@/common/type";
import BottomNavigation from "@/components/customer/BottomNavigation.vue";
import AppBar from "@/components/AppBar.vue";
import { mapGetters } from "vuex";

import loyaltyConfig from '../../loyalty_config.json'

@Component({
  components: {
    BottomNavigation,
    AppBar,
  },
  computed: {
    ...mapGetters("customer", ["selectedBrandLogo", "isBrandReady", "selectedBrandName"]),
  },
})
export default class Customer extends Vue {
  shopLogoUrl: string = require(`@/assets/img/sable_logo.svg`);
  sableDefault: string = require(`@/assets/img/sable_default.svg`);
  selectedBrandLogo!: string
  selectedBrandName!: string
  backRouterMapping: any = {
    Customer_Home: "Customer_Shoplist",
    Customer_Reward: "Customer_Home",
    Customer_History: "Customer_Home",
    Customer_Profile: "Customer_Home",
  };


  get shopLogo () {
    if (loyaltyConfig?.loyalty_logo) {
      return loyaltyConfig?.loyalty_logo
    }
    if(this.$route.name === 'Customer_Profile') {
      return this.shopLogoUrl;
    } else if(this.selectedBrandLogo){
      return this.selectedBrandLogo;
    }
    return this.sableDefault
  }

  get middleMode () {
    if (loyaltyConfig?.loyalty_logo) {
      return "image"
    }
    if(this.$route.name == 'Customer_Profile'){
      return "image"
    }
    return "text"
  }

  get shopName () {
    if(this.selectedBrandName){
      return this.selectedBrandName
    }
    return '-'
  }

  back() {
    const backRouteName = this.backRouterMapping[this.$route.name + ""];
    if (backRouteName) {
      this.$router.push({ name: backRouteName });
    } else {
      this.$router.go(-1);
    }
  }
}
