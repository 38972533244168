














































import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class InsertPointModal extends Vue {
  @Prop({ default: false })
  private isShow!: boolean;

  point: string = ''
  valid: boolean = false;
  errorMessages: string = ""

  rules:Array<Function> = [
    (value: number) => !!value || '* กรุณากรอกตัวเลข',
    (value: string) => !(+value <= 0) || '* มากกว่า 0',
    (value: string) => !(+value > 100000) || '* ไม่เกิน 100,000'
  ]

  toggleModal(value: boolean) {
    //@ts-ignore
    if(this.$refs.form.validate()){
      this.$emit("toggleModal", { isShow: value, point: +this.point});
    }
  }

  handleChange () {
    if (this.errorMessages != "") {
      this.errorMessages = ""
    }
  }

}
