


















































































































































import { Component, Vue } from "vue-property-decorator";
import AppBar from "@/components/AppBar.vue";
import validator from "validator";
import BrandAPIConnector from "@/service/BrandAPIConnector";

import { mapGetters } from "vuex";

@Component({
  components: {
    AppBar,
  },
  computed: {
    ...mapGetters("brand", ["isSingleBranch"])
  }
})
export default class BranchEditor extends Vue {
  mode: "new" | "edit" = "new";
  branchIdTarget: string | null = null;

  branchData: any = {
    name: {
      data: "",
      warning: "",
      isChange: false, // a flag for edit purpose only
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุชื่อสาขา";
        },
      ],
    },
    defaultExchangePurchasePerPoint: {
      data: undefined,
      warning: "",
      isChange: false, // a flag for edit purpose only
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุอัตราการให้แต้ม";
        },
        function (this: any, v: number) {
          return v > 0 || "จำนวนต้องมากกว่า 0 บาท";
        },
      ],
    },
    username: {
      data: "",
      warning: "",
      isChange: false, // a flag for edit purpose only
      rules: [
        function (this: any, v: number) {
          return !!v || "กรุณาระบุ Username";
        },

        function (this: any, v: string) {
          return (
            validator.matches(v, "^[a-zA-Z0-9_\.\-]*$") ||
            "กรุณาใช้ตัวอักษรภาษาอังกฤษหรือตัวเลข"
          );
        },
        function (this: any, v: string) {
          return (
            validator.isLength(v, { min: 4, max: undefined }) ||
            "กรุณาใช้ชื่อที่มีความยาวมากกว่า 4 ตัวอักษร"
          );
        },
      ],
    },
    password: {
      data: "",
      warning: "",
      isChange: false, // a flag for edit purpose only
      rules: [
        function (this: any, v: string) {
          return (
            // if in edit mode and password is not change then skip
            (this.mode == "edit" && !this.branchData.password.isChange) ||
            !!v ||
            "กรุณาระบุรหัสผ่าน"
          );
        },
        function (this: any, v: string) {
          return (
            // if in edit mode and password is not change then skip
            (this.mode == "edit" && !this.branchData.password.isChange) ||
            validator.isLength(v, { min: 6, max: undefined }) ||
            "กรุณาใช้รหัสผ่านที่มีความยาวกว่า 6 ตัวอักษร"
          );
        },
      ],
    },
    retypePassword: {
      data: "",
      warning: "",
      rules: [
        function (this: any, v: string) {
          return (
            // if in edit mode and password is not change then skip
            (this.mode == "edit" && !this.branchData.password.isChange) ||
            !!v ||
            "กรุณายืนยันรหัสผ่าน"
          );
        },
        function (this: any, v: string) {
          return (
            // if in edit mode and password is not change then skip
            (this.mode == "edit" && !this.branchData.password.isChange) ||
            v === this.branchData.password.data ||
            "รหัสผ่านไม่ตรงกัน"
          );
        },
      ],
    },
  };

  shopCode: string = "";

  isLoading = false;
  showPassword = false;
  showRetypePassword = false;

  mounted() {
    if (this.$route.params.id) {
      console.log("Edit mode");
      this.mode = "edit";
      this.branchIdTarget = this.$route.params.id;
      this.loadBranchData(this.branchIdTarget);
    } else {
      console.log("New mode");
      this.mode = "new";
    }
  }

  async loadBranchData(branchId: string) {
    this.isLoading = true;
    const userBranchResult = await BrandAPIConnector.getUserBranch(branchId);
    if (userBranchResult) {
      this.branchData.name.data = userBranchResult.branch_name;
      this.branchData.username.data = userBranchResult.username;
      this.branchData.password.data = "";
      this.branchData.retypePassword.data = "";
      this.branchData.defaultExchangePurchasePerPoint.data =
        userBranchResult.default_exchange_purchase_per_point;
      this.shopCode = userBranchResult.shop_code;
    } else {
      this.$store.dispatch("alert/error", "ไม่สามารถดึงข้อมูลได้");
      this.$router.push({ name: "ShopOwner_BranchesSummary" });
    }
    this.isLoading = false;
  }

  doValidate(field: any) {
    let valid = true;
    field.rules.forEach((validateCheck: any) => {
      const result = validateCheck.bind(this)(field.data);
      if (result != true) {
        field.warning = result;
        valid = false;
      }
    });
    if (valid) field.warning = "";
    return valid;
  }

  // Be careful with this recursive function
  validateAll(data: any[] = this.branchData) {
    let valid = true;
    for (const key in data) {
      if (data[key].hasOwnProperty("data")) {
        const result = this.doValidate(data[key]);
        if (!result) valid = false;
      } else {
        const result = this.validateAll(data[key]);
        if (!result) valid = false;
      }
    }
    return valid;
  }

  async createBranch() {
    const valid = this.validateAll();
    console.log("Validate result : " + valid);
    if (valid) {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("branch_name", this.branchData.name.data);
      formData.append("username", this.branchData.username.data);
      formData.append("password", this.branchData.password.data);
      formData.append(
        "default_exchange_purchase_per_point",
        this.branchData.defaultExchangePurchasePerPoint.data
      );
      const createResult = await BrandAPIConnector.createBranch(formData);
      if (createResult) {
        if ("errorMessage" in createResult) {
          this.$store.dispatch("alert/error", createResult.errorMessage);
          this.branchData.username.warning = createResult.errorMessage;
        } else {
          this.$store.dispatch("alert/success", "สร้างสาขาเรียบร้อยแล้ว");
          this.$router.push({ name: "ShopOwner_BranchesSummary" });
        }
      } else {
        this.$store.dispatch("alert/error", "ไม่สามารถสร้างสาขาได้");
      }
      this.isLoading = false;
    }
  }

  async editBranch() {
    const valid = this.validateAll();
    console.log("Validate result : " + valid);
    if (valid) {
      this.isLoading = true;
      const formData = new FormData();
      this.branchData.name.isChange &&
        formData.append("branch_name", this.branchData.name.data);
      this.branchData.username.isChange &&
        formData.append("username", this.branchData.username.data);
      this.branchData.password.isChange &&
        formData.append("password", this.branchData.password.data);
      this.branchData.defaultExchangePurchasePerPoint.isChange &&
        formData.append(
          "default_exchange_purchase_per_point",
          this.branchData.defaultExchangePurchasePerPoint.data
        );
      // Check if formData empty because of edit nothing.
      const isEdit = formData.entries().next().value ? true : false;
      if (!isEdit) {
        this.isLoading = false;
        return;
      }
      const patchResult = await BrandAPIConnector.updateUserBranch(
        this.branchIdTarget + "",
        formData
      );
      if (patchResult) {
        if ("errorMessage" in patchResult) {
          this.$store.dispatch("alert/error", patchResult.errorMessage);
          this.branchData.username.warning = patchResult.errorMessage;
        } else {
          this.$store.dispatch("alert/success", "แก้ไขข้อมูลเรียบร้อย");
          this.loadBranchData(this.branchIdTarget + "");
        }
      } else {
        this.$store.dispatch("alert/error", "ไม่สามารถแก้ไขข้อมูลได้");
      }
      this.isLoading = false;
    }
  }
}
