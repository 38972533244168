import { BranchLoginResult, BrandLoginResult } from "@/common/type";
import router from "@/router";
import axios, { AxiosError } from "axios";
import qs from "qs";
import APIConnector from "./APIConnector";
class BaseAPIConnector extends APIConnector {
  constructor() {
    super();
  }

  async login(
    username: string,
    password: string
  ): Promise<BranchLoginResult | BrandLoginResult | undefined> {
    try {
      const authData = {
        strategy: "local",
        username,
        password,
      };
      const authResult = await axios({
        method: "post",
        url: `${this.APIPath}/authentication`,
        data: qs.stringify(authData),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const { data } = authResult;
      const result = {
        accessToken: data.accessToken,
        user: data.user,
        role: data.user.role,
      };
      return result;
    } catch (e: any) {
      console.log("Base Login Failed");
      const agencyLogin = await this.loginAgency(username, password)
      // this.handleAPIFailed(e, false);
      return agencyLogin;
    }
  }

  async loginAgency(
    username: string,
    password: string
  ): Promise<BranchLoginResult | BrandLoginResult | undefined> {
    try {
      const authData = {
        strategy: "agency",
        username,
        password,
      };
      const authResult = await axios({
        method: "post",
        url: `${this.APIPath}/authentication`,
        data: qs.stringify(authData),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const { data } = authResult;
      const result = {
        accessToken: data.accessToken,
        user: data.user,
        role: data.user.role,
      };
      return result;
    } catch (e: any) {
      console.log("Base Login Failed");
      this.handleAPIFailed(e, false);
      return undefined;
    }
  }

  async register(formData: FormData) {
    try {
      const registerResult = await axios({
        method: "post",
        url: `${this.APIPath}/register/brand/`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return registerResult.data;
    } catch (e: any) {
      if (e.response.status == 409) {
        return {
          error:
            "ไม่สามารถใช้ Username นี้ได้ เนื่องจาก Username นี้ถูกใช้ไปแล้ว",
        };
      }
      console.log("Base Register Failed");
      this.handleAPIFailed(e, false);
      return undefined;
    }
  }

  async getLoyaltyCodeDetail(
    code: string
  ): Promise< any | undefined> {
    try {
      const codeDetail = await axios({
        method: "get",
        url: `${this.APIPath}/loyalty-code/get-code-detail/${code}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return codeDetail.data;
    } catch (e: any) {
      if (e.response.status == 404) {
        return {
          error:
            "รหัสไม่ถูกต้อง",
        };
      }
      console.log("Get code detail failed");
      this.handleAPIFailed(e, false);
      return undefined;
    }
  }

  async forgotPassword(
    data: string,
  ): Promise< any | undefined> {
    try {
      let formatData = {
        phone: '',
        // email: '',
      }
      if((new RegExp('^([0]){1}([689]){1}([0-9]){8}$')).test(data)) {
        formatData.phone = data
      }
      const result = await axios({
        method: "patch",
        url: `${this.APIPath}/forget-password`,
        data: formatData,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return result;
    } catch (e: any) {
      console.log("Base forgotPasswordByPhone Failed");
      this.handleAPIFailed(e, false);
      return undefined;
    }
  }

  async updatePassword(
    brandId: string,
    password: string,
    token: string,
  ): Promise< any | undefined> {
    try {
      const data = {
        password,
        token
      }

      const result = await axios({
        method: "patch",
        url: `${this.APIPath}/update-password/${brandId}`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return result;
    } catch (e: any) {
      console.log("Base Update Password Failed");
      this.handleAPIFailed(e, false);
      return undefined;
    }
  }

  async getQRData(
    code: string,
  ): Promise<any | undefined> {
    try {

      const result = await axios({
        method: "get",
        url: `${this.APIPath}/qr-code-generate?qr_code=${code}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return result;
    } catch (e: any) {
      console.log("Base get QR Data Failed");
      this.handleAPIFailed(e, false);
      return undefined;
    }
  }

  async handleAPIFailed(e: AxiosError, isRedirect = true) {
    if (e.response?.status === 401) {
      console.log("Token is not valid");
      isRedirect && router.push({ name: "Login" });
    } else {
      console.dir(e);
      return undefined;
    }
  }
}

export default new BaseAPIConnector();
