
































































import { Component, Prop, Vue } from "vue-property-decorator";
import BackIcon from "@/components/icons/BackIcon.vue";
import PowerIcon from "@/components/icons/PowerIcon.vue";
import { Liff } from "@line/liff/dist/lib";

@Component({
  components: { BackIcon, PowerIcon },
})
export default class AppBar extends Vue {
  @Prop({})
  private logoURL!: string;
  @Prop({ default: "backward" })
  private mode!: "backward" | "home" | "blend";
  @Prop({ default: "white" })
  private backwardBGColor!: string;
  @Prop({ default: "image" })
  private middleMode!: string;
  @Prop({ default: "-" })
  private text!: string;

  sableDefault: string = require(`@/assets/img/sable_default.svg`);

  private backIconToggle = true;
  //@ts-ignore
  private liff: Liff = this.$liff;

  get shopLogo () {
    if(this.logoURL){
      return this.logoURL;
    }
    return this.sableDefault
  }

  mounted() {
    if (this.liff.isInClient()) {
      this.backIconToggle = false;
    }
  }

  onClickBack() {
    this.$emit("back");
  }

  onClickPower() {
    this.$emit("exit");
  }
}
