















import { Vue, Prop, Component } from "vue-property-decorator";

@Component
export default class BackIcon extends Vue {
  @Prop({ default: 8 })
  width!: number;
  @Prop({ default: 14 })
  height!: number;
  @Prop({ default: "black" })
  color!: string;
}
