var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"register-form-container"},[_c('div',{staticClass:"form-title"},[_vm._v(_vm._s(_vm.isEditMode ? 'แก้ไขข้อมูลผู้ใช้': 'เพิ่มผู้ใช้ใหม่'))]),_c('v-form',{ref:"form"},[_c('div',{staticClass:"input-field"},[_vm._v(" สิทธิการเข้าถึง"),_c('span',{staticClass:"text-red"},[_vm._v("*")]),_c('v-select',{class:{
            'warning-input-box': _vm.staffProfileData.role.warning,
          },attrs:{"items":_vm.roleItems,"dense":"","outlined":"","rounded":"","hide-details":"","append-icon":"mdi-triangle-small-down"},on:{"change":function($event){_vm.staffProfileData.role.isChange = true;}},model:{value:(_vm.staffProfileData.role.data),callback:function ($$v) {_vm.$set(_vm.staffProfileData.role, "data", $$v)},expression:"staffProfileData.role.data"}}),_c('span',{staticClass:"warning-label"},[_vm._v(_vm._s(_vm.staffProfileData.role.warning))])],1),(_vm.brandProfile.has_branch && _vm.staffProfileData.role.data=='staff')?_c('div',{staticClass:"input-field"},[_vm._v(" สาขา"),_c('span',{staticClass:"text-red"},[_vm._v("*")]),_c('v-select',{class:{
            'warning-input-box': _vm.staffProfileData.branch.warning,
          },attrs:{"items":_vm.branchName,"dense":"","outlined":"","hide-details":"","rounded":"","append-icon":"mdi-triangle-small-down"},on:{"change":function($event){_vm.staffProfileData.branch.isChange = true;}},model:{value:(_vm.staffProfileData.branch.data),callback:function ($$v) {_vm.$set(_vm.staffProfileData.branch, "data", $$v)},expression:"staffProfileData.branch.data"}}),_c('span',{staticClass:"warning-label"},[_vm._v(_vm._s(_vm.staffProfileData.branch.warning))])],1):_vm._e(),_c('div',{staticClass:"input-field"},[_vm._v(" ชื่อผู้ใช้"),_c('span',{staticClass:"text-red"},[_vm._v("*")]),_c('v-text-field',{staticClass:"input-box",class:{
            'warning-input-box': _vm.staffProfileData.username.warning,
          },attrs:{"type":"text","rounded":"","hide-details":"","required":"","disabled":_vm.isEditMode},on:{"change":function($event){_vm.staffProfileData.username.isChange = true;
            _vm.doValidate(_vm.staffProfileData.username);}},model:{value:(_vm.staffProfileData.username.data),callback:function ($$v) {_vm.$set(_vm.staffProfileData.username, "data", $$v)},expression:"staffProfileData.username.data"}}),_c('span',{staticClass:"warning-label"},[_vm._v(_vm._s(_vm.staffProfileData.username.warning))])],1),_c('div',{staticClass:"input-field"},[_vm._v(" รหัสผ่าน"),_c('span',{staticClass:"text-red"},[_vm._v("*")]),_c('v-text-field',{staticClass:"input-box",class:{
            'warning-input-box': _vm.staffProfileData.password.warning,
          },attrs:{"type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"rounded":"","hide-details":"","required":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword},"change":function($event){_vm.staffProfileData.password.isChange = true;
            _vm.doValidate(_vm.staffProfileData.password);}},model:{value:(_vm.staffProfileData.password.data),callback:function ($$v) {_vm.$set(_vm.staffProfileData.password, "data", $$v)},expression:"staffProfileData.password.data"}}),_c('span',{staticClass:"warning-label"},[_vm._v(_vm._s(_vm.staffProfileData.password.warning))])],1),_c('div',{staticClass:"input-field"},[_vm._v(" ยืนยันรหัสผ่าน"),_c('span',{staticClass:"text-red"},[_vm._v("*")]),_c('v-text-field',{staticClass:"input-box",class:{
            'warning-input-box': _vm.staffProfileData.retypePassword.warning,
          },attrs:{"type":_vm.showPasswordRetype ? 'text' : 'password',"append-icon":_vm.showPasswordRetype ? 'mdi-eye' : 'mdi-eye-off',"rounded":"","hide-details":"","required":""},on:{"click:append":function($event){_vm.showPasswordRetype = !_vm.showPasswordRetype},"change":function($event){_vm.staffProfileData.retypePassword.isChange = true;
            _vm.doValidate(_vm.staffProfileData.retypePassword);}},model:{value:(_vm.staffProfileData.retypePassword.data),callback:function ($$v) {_vm.$set(_vm.staffProfileData.retypePassword, "data", $$v)},expression:"staffProfileData.retypePassword.data"}}),_c('span',{staticClass:"warning-label"},[_vm._v(_vm._s(_vm.staffProfileData.retypePassword.warning))])],1),_c('div',{staticClass:"input-field"},[_vm._v(" ชื่อ"),_c('span',{staticClass:"text-red"},[_vm._v("*")]),_c('v-text-field',{staticClass:"input-box",class:{
            'warning-input-box': _vm.staffProfileData.firstname.warning,
          },attrs:{"type":"text","rounded":"","hide-details":"","required":""},on:{"change":function($event){_vm.staffProfileData.firstname.isChange = true;
            _vm.doValidate(_vm.staffProfileData.firstname);}},model:{value:(_vm.staffProfileData.firstname.data),callback:function ($$v) {_vm.$set(_vm.staffProfileData.firstname, "data", $$v)},expression:"staffProfileData.firstname.data"}}),_c('span',{staticClass:"warning-label"},[_vm._v(_vm._s(_vm.staffProfileData.firstname.warning))])],1),_c('div',{staticClass:"input-field"},[_vm._v(" นามสกุล"),_c('span',{staticClass:"text-red"},[_vm._v("*")]),_c('v-text-field',{staticClass:"input-box",class:{
            'warning-input-box': _vm.staffProfileData.lastname.warning,
          },attrs:{"type":"text","rounded":"","hide-details":"","required":""},on:{"change":function($event){_vm.staffProfileData.lastname.isChange = true;
            _vm.doValidate(_vm.staffProfileData.lastname);}},model:{value:(_vm.staffProfileData.lastname.data),callback:function ($$v) {_vm.$set(_vm.staffProfileData.lastname, "data", $$v)},expression:"staffProfileData.lastname.data"}}),_c('span',{staticClass:"warning-label"},[_vm._v(_vm._s(_vm.staffProfileData.lastname.warning))])],1)]),_c('v-btn',{staticClass:"save-btn",attrs:{"text":"","rounded":"","disabled":_vm.isLoading,"loading":_vm.isLoading},on:{"click":function($event){return _vm.save()}}},[_vm._v(" บันทึกข้อมูล ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }