const state = {
  type: null,
  message: "test",
  isShowing: false,
};

const actions = {
  success({ commit }: any, message: string) {
    commit("clear");
    commit("success", message);
  },
  info({ commit }: any, message: string) {
    commit("clear");
    commit("info", message);
  },
  warning({ commit }: any, message: string) {
    commit("clear");
    commit("warning", message);
  },
  error({ commit }: any, message: string) {
    commit("clear");
    commit("error", message);
  },
  clear({ commit }: any) {
    commit("clear");
  },
};

const mutations = {
  success(state: any, message: string) {
    state.type = "success";
    state.isShowing = true;
    state.message = message;
  },
  info(state: any, message: string) {
    state.type = "info";
    state.isShowing = true;
    state.message = message;
  },
  warning(state: any, message: string) {
    state.type = "warning";
    state.isShowing = true;
    state.message = message;
  },
  error(state: any, message: string) {
    state.type = "error";
    state.isShowing = true;
    state.message = message;
  },
  clear(state: any) {
    state.type = null;
    state.message = null;
    state.isShowing = false;
  },
};

const getters = {
  message: (state: any) => state.message,
  type: (state: any) => state.type,
  isShowing: (state: any) => state.isShowing,
};

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
