

































































































import { Component, Vue, Watch } from "vue-property-decorator";
import validator from "validator";
import BrandAPIConnector from "@/service/BrandAPIConnector";
import { mapGetters } from 'vuex'
@Component({
  components: {},
  computed: {
    ...mapGetters('branch', ["branchName"]),
    ...mapGetters('brand', ["isSingleBranch"])
  }
})
export default class BranchStaff extends Vue {
  shopOwnerProfileData: any = {
    username: {
      data: "",
      warning: "",
      isChange: false,
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุชื่อผู้ใช้";
        },
        function (this: any, v: string) {
          return (
            validator.matches(v, "^[a-zA-Z0-9_\.\-]*$") ||
            "กรุณาใช้ตัวอักษรภาษาอังกฤษหรือตัวเลข"
          );
        },
        function (this: any, v: string) {
          return (
            validator.isLength(v, { min: 4, max: undefined }) ||
            "กรุณาใช้ชื่อที่มีความยาวมากกว่า 4 ตัวอักษร"
          );
        },
      ],
    },
    password: {
      data: "",
      warning: "",
      isChange: false,
      rules: [
        function (this: any, v: string) {
          return (
            // if in edit mode and password is not change then skip
            (this.isEditMode && !this.shopOwnerProfileData.password.isChange) ||
            !!v ||
            "กรุณาระบุรหัสผ่าน"
          );
        },
        function (this: any, v: string) {
          return (
            // if in edit mode and password is not change then skip
            (this.isEditMode && !this.shopOwnerProfileData.password.isChange) ||
            validator.isLength(v, { min: 6, max: undefined }) ||
            "กรุณาใช้รหัสผ่านที่มีความยาวกว่า 6 ตัวอักษร"
          );
        },
      ],
    },
    retypePassword: {
      data: "",
      warning: "",
      isChange: false,
      rules: [
        function (this: any, v: string) {
          return (
            // if in edit mode and password is not change then skip
            (this.isEditMode && !this.shopOwnerProfileData.password.isChange) ||
            !!v ||
            "กรุณายืนยันรหัสผ่าน"
          );
        },
        function (this: any, v: string) {
          return (
            // if in edit mode and password is not change then skip
            (this.isEditMode && !this.shopOwnerProfileData.password.isChange) ||
            v === this.shopOwnerProfileData.password.data ||
            "รหัสผ่านไม่ตรงกัน"
          );
        },
      ],
    },
  };

  brandId: string = "";
  userId: string = "";
  // branchName!: string;
  // isSingleBranch!: boolean;

  isEditMode = false;
  isLoading = false;

  showPassword = false;
  showPasswordRetype = false;

  // @Watch("branchName")
  // watchBranchNameAfterReload() {
  //   this.shopOwnerProfileData.shopBranch.data = this.branchName;
  // }

  mounted() {
    this.loadShopOwnerProfile();
  }

  async loadShopOwnerProfile() {
    this.isLoading = true;
    const userData = localStorage.getItem("user");
    if (userData) {
      const localData = JSON.parse(userData);
      const currentUser = await BrandAPIConnector.getUserById(localData.user._id);
      if (currentUser) {
        this.userId = localData.user._id;
        this.shopOwnerProfileData.username.data = currentUser[0].username;
        this.shopOwnerProfileData.password.data = ""; // default
      } else {
        this.$store.dispatch("alert/error", "ไม่สามารถดึงข้อมูลได้");
        this.$router.push({ name: "Branch_Menu" });
      }
      
      // const logoImageUrl = brandUser.logo_loyalty;
      // const posterImageUrl = brandUser.poster_image_loyalty;
      // this.shopOwnerProfileData.shopLogoImage.data = new File(
      //   [""],
      //   "placeHolder"
      // );
      // if(logoImageUrl === undefined) {
      //   this.shopOwnerProfileData.shopLogoImage.data = null
      // } else {
      //   this.fileUploader.logo.preview = logoImageUrl;
      // }
      // this.shopOwnerProfileData.posterImage.data = new File(
      //   [""],
      //   "placeHolder"
      // );
      // if(posterImageUrl === undefined) {
      //   this.shopOwnerProfileData.posterImage.data = null
      // } else {
      //   this.fileUploader.poster.preview = posterImageUrl;
      // }
      // this.shopOwnerProfileData.username.data = brandUser.username;
      // this.shopOwnerProfileData.password.data = ""; // default
      // this.shopOwnerProfileData.shopName.data = brandUser.brand_name_loyalty;
      // this.shopOwnerProfileData.email.data = brandUser.email_loyalty;
      // this.shopOwnerProfileData.phone.data = brandUser.brand_phone_loyalty;
      // this.shopOwnerProfileData.contact.firstname.data =
      //   brandUser.firstname_loyalty;
      // this.shopOwnerProfileData.contact.lastname.data =
      //   brandUser.lastname_loyalty;
      // this.shopOwnerProfileData.contact.phone.data = brandUser.phone_loyalty;
      // if(this.isSingleBranch){
      //   this.shopOwnerProfileData.shopBranch.data = this.branchName;
      // }
    } else {
      this.$store.dispatch("alert/error", "ไม่สามารถดึงข้อมูลได้");
      this.$router.push({ name: "ShopOwner_Menu" });
    }
    this.isLoading = false;
  }

  toggleEditMode() {
    this.isEditMode = true;
  }

  doValidate(field: any) {
    let valid = true;
    field.rules.forEach((validateCheck: any) => {
      const result = validateCheck.bind(this)(field.data);
      if (result != true) {
        field.warning = result;
        valid = false;
      }
    });
    if (valid) field.warning = "";
    return valid;
  }

  // Be careful with this recursive function
  validateAll(data: any[] = this.shopOwnerProfileData) {
    let valid = true;
    for (const key in data) {
      if (data[key].hasOwnProperty("data")) {
        // if(!this.isSingleBranch && key == 'shopBranch'){
        //   continue;
        // }else if (key == 'email' || key == 'shopLogoImage' || key == 'posterImage'){
        //   continue;
        // }else {
        const result = this.doValidate(data[key]);
        if (!result) {
          valid = false;
        // }
        }
      } else {
        // if(!this.isSingleBranch && key == 'shopBranch'){
        //   continue;
        // }else if (key == 'email' || key == 'shopLogoImage' || key == 'posterImage'){
        //   continue;
        // }else {
        const result = this.validateAll(data[key]);
        if (!result) {
          valid = false;
        }
        // }
      }
    }
    return valid;
  }

  async save() {
    const valid = this.validateAll();
    console.log("Validate result : " + valid);
    if (valid) {
      this.isEditMode = false;
      this.isLoading = true;
      const formData = new FormData();
      this.shopOwnerProfileData.username.isChange &&
        formData.append("username", this.shopOwnerProfileData.username.data);

      this.shopOwnerProfileData.password.isChange &&
        formData.append("password", this.shopOwnerProfileData.password.data);
        
      const isEdit = formData.entries().next().value ? true : false;
      if (!isEdit) {
        console.log("nothing change");
        this.isEditMode = false;
        this.isLoading = false;
        return;
      }
      const patchResult = await BrandAPIConnector.editProfile(
        this.userId,
        formData
      );
      if (patchResult) {
        this.$store.dispatch("alert/success", "แก้ไขข้อมูลเรียบร้อย");
        // this.loadShopOwnerProfile();
        // this.isEditMode = false;
        // Need to refresh because file loader still stored the previous image
        this.$router.go(0);
      } else {
        this.$store.dispatch("alert/error", "ไม่สามารถแก้ไขข้อมูลได้");
      }
      this.isEditMode = true;
      this.isLoading = false;
    }
  }

  // redirectClickUpload(refs: string) {
  //   (this.$refs[refs] as any).$children[0].$el.click();
  // }

  // filesChange(field: "logo" | "poster", file: File) {
  //   if (!file) {
  //     this.fileUploader[field].preview = "";
  //     return;
  //   }
  //   const propertyName = field == "logo" ? "shopLogoImage" : "posterImage";
  //   if (file.size > 5000000) {
  //     this.$store.dispatch("alert/error", "รูปภาพมีขนาดใหญ่เกินไป");
  //     this.shopOwnerProfileData[propertyName].warning = "รูปมีขนาดใหญ่เกินไป";
  //     return;
  //   }
  //   console.log("reading");
  //   const reader = new FileReader();
  //   reader.onload = (e: any) => {
  //     console.log("reader onload");
  //     this.fileUploader[field].preview = e.target.result + "";
  //     this.shopOwnerProfileData[propertyName].warning = "";
  //   };
  //   reader.readAsDataURL(file);
  // }
}
