


























































import { Vue, Prop, Component } from "vue-property-decorator";

@Component
export default class UsedIcon extends Vue {
  @Prop({ default: 80 })
  width!: number;
  @Prop({ default: 80 })
  height!: number;
  @Prop({ default: "#FF5733" })
  color!: string;
}
