















































import { Component, Vue, Prop } from "vue-property-decorator";
import AppBar from "@/components/AppBar.vue";
import BasedAPIConnector from "@/service/BasedAPIConnector";

@Component({
  components: {
    AppBar
  },
})

export default class ResetByTel extends Vue {
  password = {
    data: '',
    errorMessages: '',
    rules: [
      (value: string) => value.length > 5 || 'กรุณาใช้รหัสผ่านที่มีความยาวกว่า 6 ตัวอักษร',
    ]
  }
  confirmPassword = {
    data: '',
    errorMessages: '',
    rules: [
      (value: string) => !!value || '* กรุณายืนยันรหัสผ่านใหม่',
      (value: string) => value === this.password.data || '* กรุณากรอกรหัสผ่านให้ตรงกัน',
    ]
  }
  valid: boolean = true
  @Prop()
  private brandId!: string
  @Prop()
  private token!: string

  async updatePassword() {
    //@ts-ignore
    if(this.$refs.form.validate()){
      const response = await BasedAPIConnector.updatePassword(this.brandId, this.password.data, this.token)
      if (response.data.status === "SUCCESS") {
        this.$store.dispatch("alert/success", "เปลี่ยนรหัสผ่านสำเร็จ");
        this.$emit("callbackToParent", true)
      } else {
        this.$store.dispatch(
          "alert/error",
          "ไม่สามารถเปลี่ยนรหัสผ่านได้ กรุณาลองใหม่อีกครั้ง"
        );
        this.$emit("callbackToParent", true)
      }
    }
  }

}
