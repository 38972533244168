




import { Component, Vue } from "vue-property-decorator";
import Loading from "@/components/Loading.vue";
import CustomerAPI from "@/service/CustomerAPIConnector";
import BaseAPI from "@/service/BasedAPIConnector";
import Cookies from 'js-cookie';

@Component({
  components: { Loading },
})
export default class GetPointFromQR extends Vue {

  mockData: any = {
    point: 10,
    agency_id: 1,
    shop_id: 1,
    type: "UNUSED"
  }

  mounted() {
    this.checkingUserFromQR()
  }

  async checkingUserFromQR () {

    let mode = localStorage.getItem("mode")
    if (mode) {
      if (mode === "AGENCY") { //Agency
        let agencyData = localStorage.getItem("agency")
        if(agencyData) {
          this.$router.push({ name: "Agency_ActiveQR", query: { qr: this.$route.query.qr } });
        } else {
          this.goToLogin()
        }
      } else if (mode === "USER") { //Shop
        let userData = JSON.parse(String(localStorage.getItem("user")))
        if (userData?.role === "admin") {
          this.$router.push({ name: "Branch_ActiveQR", query: { qr: this.$route.query.qr } });
        } else if (userData?.role === "staff") {
          this.$router.push({ name: "Branch_ActiveQR", query: { qr: this.$route.query.qr } });
        } else {
          this.goToLogin()
        }
      } else if (mode === "CUSTOMER") { //Customer
        try {
          const qrData = await CustomerAPI.getQr(this.$route.query.qr.toString());
          if (qrData[0].brand_id) {
              this.$router.push({ name: "Customer_Home", params: { brandId: qrData[0].brand_id }, query: { qr: this.$route.query.qr } });
          } else {
            this.$router.push({ name: "Customer", query: { qr: "failed" } });
          }
        } catch {
          this.$router.push({ name: "Customer", query: { qr: "failed" } });
        }
      } else {
        this.$router.push({ name: "Index" });
      }
    } else {
      this.goToLogin()
    }
  }

  async goToLogin () {
    Cookies.set('rod', this.$route.query.qr.toString(), { expires: (1 / 1440) * 10 })
    let qrData = await BaseAPI.getQRData(this.$route.query.qr.toString())
    if(qrData.data[0].brand_id !== '' && qrData.data[0].branch_id !== '') { //Customer
      this.$router.push({ name: "Customer", params: { brandId: qrData.data[0].brand_id }, query: { qr: this.$route.query.qr } });
    } else { //Shop and Agency
      this.$router.push({ name: "Login" });
    }
    // this.$router.push({ name: "Index" });
  }
}
