

























































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import validator from "validator";
import AppBar from "@/components/AppBar.vue";
import ContactModal from "@/components/register/ContactModal.vue";
import InsertPointModal from "@/components/register/InsertPointModal.vue";
import BasedAPIConnector from "@/service/BasedAPIConnector";

interface FileUploadData {
  preview: string;
  warning: boolean;
  fileReaderTimeout?: number;
  imageReading: boolean;
}

interface InsertPoint {
  isShow: boolean;
  point: number;
}

@Component({
  components: {
    AppBar,
    ContactModal,
    InsertPointModal
  },
})

export default class Register extends Vue {
  aipenLogoURL: string = require(`@/assets/img/sable_logo.svg`);
  registerData: any = {
    username: {
      data: "",
      warning: "",
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุชื่อผู้ใช้";
        },
        function (this: any, v: string) {
          return (
            validator.matches(v, "^[a-zA-Z0-9_\.\-]*$") ||
            "กรุณาใช้ตัวอักษรภาษาอังกฤษหรือตัวเลข"
          );
        },
        function (this: any, v: string) {
          return (
            validator.isLength(v, { min: 4, max: undefined }) ||
            "กรุณาใช้ชื่อที่มีความยาวมากกว่า 4 ตัวอักษร"
          );
        },
      ],
    },
    password: {
      data: "",
      warning: "",
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุรหัสผ่าน";
        },
        function (this: any, v: string) {
          return (
            validator.isLength(v, { min: 6, max: undefined }) ||
            "กรุณาตั้งรหัสผ่านที่มีความยาว 6 ตัวขึ้นไป"
          );
        },
      ],
    },
    retypePassword: {
      data: "",
      warning: "",
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณายืนยันรหัสผ่าน";
        },
        function (this: any, v: string) {
          return v === this.registerData.password.data || "รหัสผ่านไม่ตรงกัน";
        },
      ],
    },
    shopLogoImage: {
      data: null,
      warning: "",
      rules: [],
    },
    posterImage: {
      data: null,
      warning: "",
      rules: [],
    },
    shopName: {
      data: "",
      warning: "",
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุชื่อร้านค้า";
        },
        function (this: any, v: string) {
          if((v.length + this.registerData.shopBranch.data.length) > 35) return "ชื่อร้านและชื่อสาขารวมกันมีความยาวเกิน 35 ตัวอักษร"
          return true
        },
      ],
    },
    shopBranch: {
      data: "",
      warning: "",
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุชื่อสาขา"
        },
        function (this: any, v: string) {
          if((v.length + this.registerData.shopName.data.length) > 35) return "ชื่อร้านและชื่อสาขารวมกันมีความยาวเกิน 35 ตัวอักษร"
          return true
        },
        function (this: any, v: string) {
          if(v.includes('สาขา')) return "ไม่จำเป็นต้องกรอกคำว่า 'สาขา'"
          return true
        },
      ],
    },
    email: {
      data: "",
      warning: "",
      rules: [
        function (this: any, v: string) {
          if(v) return validator.isEmail(v) || "อึเมลไม่ถูกต้อง";
          return true
        },
      ],
    },
    brand_type: {
      data: "single",
      warning: "",
      rules: [],
    },
    phone: {
      data: "",
      warning: "",
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุเบอร์โทรร้านค้า";
        },
        function (this: any, v: string) {
          return (
            validator.isMobilePhone(v, "th-TH") || "เบอร์โทรศัพท์ไม่ถูกต้อง"
          );
        },
      ],
    },
    contact: {
      firstname: {
        data: "",
        warning: "",
        rules: [
          function (this: any, v: string) {
            return !!v || "กรุณาระบุชื่อผู้ติดต่อ";
          },
        ],
      },
      lastname: {
        data: "",
        warning: "",
        rules: [
          function (this: any, v: string) {
            return !!v || "กรุณาระบุนามสกุลผู้ติดต่อ";
          },
        ],
      },
      phone: {
        data: "",
        warning: "",
        rules: [
          function (this: any, v: string) {
            return !!v || "กรุณาระบุเบอร์โทรของผู้ติดต่อ";
          },
          function (this: any, v: string) {
            return (
              validator.isMobilePhone(v, "th-TH") || "เบอร์โทรศัพท์ไม่ถูกต้อง"
            );
          },
        ],
      },
    },
    plan: {
      data: ""
    },
    codeId: {
      data: ""
    }
  };

  mounted () {
    this.registerData.plan.data = this.$route.params.plan;
    this.registerData.codeId.data = this.$route.params.code_id;
    if (this.registerData.plan.data === "" || this.registerData.plan.data === undefined || this.registerData.plan.data === null
      || this.registerData.codeId.data === "" || this.registerData.codeId.data === undefined || this.registerData.codeId.data === null) {
      this.$router.push({ name: "Register_PromoCode" })
    }
  }

  branchPoint: number = 0
  isLoading = false;

  showPassword = false;
  showPasswordRetype = false;

  fileUploader: { logo: FileUploadData; poster: FileUploadData } = {
    logo: {
      preview: "",
      warning: true,
      fileReaderTimeout: undefined,
      imageReading: false,
    },
    poster: {
      preview: "",
      warning: false,
      fileReaderTimeout: undefined,
      imageReading: false,
    },
  };

  isShowComingSoonModal: boolean = false
  isShowInsertPointModal: boolean = false

  get brandTypeValue() {
    return this.registerData.brand_type.data
  }

  // @Watch("brandTypeValue")
  // showComingSoonModel() {
  //   if(this.registerData.brand_type.data == "multiple"){
  //     this.toggleComingSoonModal(true)
  //   }
  // }

  // toggleComingSoonModal (value: boolean) {
  //   if(!value) {
  //     this.registerData.brand_type.data = "single"
  //   }
  //   this.isShowComingSoonModal = value
  // }

  toggleInsertPointModal (value: InsertPoint) {
    if(value.point !== 0) {
      this.branchPoint = value.point
      this.register()
      this.isShowInsertPointModal = value.isShow
    }
  }

  back() {
    this.$router.push({ name: "Login" });
  }

  doValidate(field: any) {
    let valid = true;
    field.rules.forEach((validateCheck: any) => {
      const result = validateCheck.bind(this)(field.data);
      if (result != true) {
        field.warning = result;
        valid = false;
      }
    });
    if (valid) field.warning = "";
    return valid;
  }

  // Be careful with this recursive function
  validateAll(data: any[] = this.registerData) {
    let valid = true;
    for (const key in data) {
      if((key == 'email' && !this.registerData.email.data) || key == 'shopLogoImage' || key == 'posterImage'){
        continue;
      }else if (key == 'shopBranch' && this.registerData.brand_type.data !== 'multiple') {
        continue;
      } else if (key === 'plan' || key === 'codeId') {
        continue;
      }else {
        if (data[key].hasOwnProperty("data")) {
          const result = this.doValidate(data[key]);
          if (!result) valid = false;
        } else {
          const result = this.validateAll(data[key]);
          if (!result) valid = false;
        }
      }
    }
    return valid;
  }

  async register() {
    const valid = this.validateAll();
    console.log("Validate result : " + valid);
    if (valid) {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("username", this.registerData.username.data);
      formData.append("password", this.registerData.password.data);
      formData.append("logo", this.registerData.shopLogoImage.data);
      formData.append("poster", this.registerData.posterImage.data);
      formData.append("brand_name_loyalty", this.registerData.shopName.data);
      formData.append("brand_type", this.registerData.brand_type.data);
      formData.append("email_loyalty", this.registerData.email.data);
      formData.append("brand_phone_loyalty", this.registerData.phone.data);
      formData.append(
        "firstname_loyalty",
        this.registerData.contact.firstname.data
      );
      formData.append(
        "lastname_loyalty",
        this.registerData.contact.lastname.data
      );
      formData.append("phone_loyalty", this.registerData.contact.phone.data);
      
      if (this.registerData.brand_type.data === 'multiple') {
        formData.append("hasBranch", "true");
        formData.append("shopBranch", this.registerData.shopBranch.data);
      } else if (this.registerData.brand_type.data === 'single') {
        formData.append("hasBranch", "false");
        formData.append("shopBranch", "สำนักงานใหญ่");
      }
      formData.append("loyalty_plan", this.registerData.plan.data);
      formData.append("code_id", this.registerData.codeId.data);
      formData.append("branchPoint", this.branchPoint.toString());

      const registerResult = await BasedAPIConnector.register(formData);
      if (registerResult) {
        if (registerResult.error) {
          this.$store.dispatch("alert/error", registerResult.error);
        } else {
          this.$store.dispatch("alert/success", "สมัครสมาชิกเรียบร้อย");
          this.$router.push({ name: "Login" });
        }
      } else {
        this.$store.dispatch(
          "alert/error",
          "ไม่สามารถสมัครสมาชิกได้ กรุณาลองใหม่อีกครั้ง"
        );
      }
      this.isLoading = false;
    }
  }

  redirectClickUpload(refs: string) {
    (this.$refs[refs] as any).$children[0].$el.click();
    // Fix for Ghost Case file choose broke at least show error
    const targetWarning: any = (
      {
        logoUpload: "shopLogoImage",
        posterUpload: "posterImage",
      } as any
    )[refs];
    targetWarning &&
      (this.registerData[targetWarning].warning =
        "ไฟล์รูปไม่รองรับ/ไม่ถูกต้อง");
  }

  resetUploadField(
    target: "logo" | "poster",
    propertyName: "shopLogoImage" | "posterImage"
  ) {
    this.registerData[propertyName].data = null;
    this.registerData[propertyName].warning = "";
    this.fileUploader[target].preview = "";
    this.fileUploader[target].imageReading = false;
  }

  filesChange(field: "logo" | "poster", file: File) {
    const propertyName = field == "logo" ? "shopLogoImage" : "posterImage";
    console.log("Enter", file);
    clearTimeout(this.fileUploader[field].fileReaderTimeout);
    if (!file) {
      this.resetUploadField(field, propertyName);
      return;
    }
    if (file.size > 5000000) {
      this.$store.dispatch("alert/error", "รูปภาพมีขนาดใหญ่เกินไป");
      this.registerData[propertyName].warning = "รูปมีขนาดใหญ่เกินไป";
      this.resetUploadField(field, propertyName);
      return;
    }

    this.fileUploader[field].imageReading = true;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      // File reading complete
      this.fileUploader[field].preview = e.target.result + "";
      this.registerData[propertyName].warning = "";
      clearTimeout(this.fileUploader[field].fileReaderTimeout);
      this.fileUploader[field].imageReading = false;
    };
    reader.onerror = (e: any) => {
      this.$store.dispatch("alert/error", "เกิดข้อผิดพลาด กรุณาลองใหม่");
      console.error(e);
      this.resetUploadField(field, propertyName);
      clearTimeout(this.fileUploader[field].fileReaderTimeout);
    };
    reader.readAsDataURL(file);
    if (reader.readyState == 0) {
      this.$store.dispatch("alert/error", "ไฟล์รูปไม่รองรับ/ไม่ถูกต้อง");
      this.resetUploadField(field, propertyName);
    }
    this.fileUploader[field].fileReaderTimeout = setTimeout(() => {
      this.$store.dispatch("alert/error", "ไฟล์รูปไม่รองรับ/ไม่ถูกต้อง");
      this.resetUploadField(field, propertyName);
    }, 5000);
  }
}
