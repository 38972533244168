














import { Component, Vue } from "vue-property-decorator";
import LocationIcon from "@/components/icons/LocationIcon.vue";
import MenuCouponIcon from "@/components/icons/menu/MenuCouponIcon.vue";
import MenuHistoryIcon from "@/components/icons/menu/MenuHistoryIcon.vue";
import MenuGivePointIcon from "@/components/icons/menu/MenuGivePoint.vue";
import MenuQRIcon from "@/components/icons/menu/MenuQRIcon.vue";
import MenuYellowQRIcon from "@/components/icons/menu/MenuYellowQRIcon.vue";
import MenuEditProfileIcon from "@/components/icons/menu/MenuEditProfileIcon.vue";
import MenuCustomerListIcon from "@/components/icons/menu/MenuCustomerListIcon.vue";
import MenuBox from "@/components/MenuBox.vue";
import { MenuBoxData } from "@/common/type";
import { mapGetters } from "vuex";

@Component({
  components: {
    LocationIcon,
    MenuGivePointIcon,
    MenuCouponIcon,
    MenuYellowQRIcon,
    MenuHistoryIcon,
    MenuEditProfileIcon,
    MenuCustomerListIcon,
    MenuBox,
  },
  computed: {
    ...mapGetters("branch", ["branchName", "branchBrandName"]),
  },
})
export default class BranchMenu extends Vue {
  menuItems: MenuBoxData[] = [
    {
      name: "giving_point",
      label: "ให้แต้ม",
      icon: MenuGivePointIcon,
      highlightColor: 'Yellow',
      isHighlight: true,
      routeName: "Branch_GivePoint",
    },
    {
      name: "redeem_coupon",
      label: "QR code ของฉัน",
      icon: MenuQRIcon,
      highlightColor: 'Green',
      isHighlight: true,
      routeName: "Branch_QR",
    },
    {
      name: "redeem_coupon",
      label: "ใช้รีวอร์ด",
      icon: MenuCouponIcon,
      isHighlight: true,
      highlightColor: 'Red',
      routeName: "Branch_RedeemCoupon",
    },
    // {
    //   name: "active_qr",
    //   label: "เปิดใช้งาน QR",
    //   icon: MenuYellowQRIcon,
    //   highlightColor: 'Yellow',
    //   isHighlight: true,
    //   routeName: "Branch_ActiveQR",
    // },
    {
      name: "branch_customer_list",
      label: "รายชื่อลูกค้า",
      icon: MenuCustomerListIcon,
      routeName: "Branch_CustomerList",
    },
    {
      name: "redeem_history",
      label: "ประวัติ",
      icon: MenuHistoryIcon,
      routeName: "Branch_History",
    },
    {
      name: "branch_staff",
      label: "แก้ไขโปรไฟล์",
      icon: MenuEditProfileIcon,
      routeName: "Branch_Staff",
    },
  ];
}
