








































































































































































































































































import { Gender, GenderItems, GenderLabels, stringToGender } from "@/common";
import { CustomerDetail } from "@/common/type";
import CustomerAPIConnector from "@/service/CustomerAPIConnector";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import validator from "validator";

@Component({
  components: {},
  computed: {
    ...mapGetters("customer", ["customerPoint", "customerFullName", "isOTPVerified"]),
  },
})
export default class CustomerProfile extends Vue {
  userAvatarUrl: string = require(`@/assets/img/avatar.png`);
  genderItems = GenderItems;
  genderLabel = GenderLabels;
  // userDetail: UserDetail = {
  //   firstname: "John",
  //   lastname: "Doe",
  //   birthdate: moment(new Date()).format("YYYY-MM-DD"),
  //   phone: "0800000000",
  //   gender: Gender.male,
  //   email: "test@test.com",
  // };

  userDetailData: any = {
    firstname: {
      data: "",
      warning: "",
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุชื่อ";
        },
      ],
    },
    lastname: {
      data: "",
      warning: "",
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุนามสกุล";
        },
      ],
    },
    birthdate: {
      data: moment(new Date()).format("YYYY-MM-DD"),
      warning: "",
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุวันเกิด";
        },
      ],
    },
    phone: {
      data: "",
      warning: "",
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุเบอร์โทรศัพท์";
        },
        function (this: any, v: string) {
          return (
            validator.isMobilePhone(v, "th-TH") || "เบอร์โทรศัพท์ไม่ถูกต้อง"
          );
        },
      ],
    },
    gender: {
      data: Gender.male,
      warning: "",
      rules: [],
    },
    email: {
      data: "",
      warning: "",
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุอีเมล";
        },
        function (this: any, v: string) {
          return validator.isEmail(v) || "อึเมลไม่ถูกต้อง";
        },
      ],
    },
  };

  showButton = true;
  datePickerModal = false;
  activePicker = "YEAR";

  editMode = false;
  isLoading = false;

  resetActivePicker() {
    setTimeout(() => (this.activePicker = "YEAR"));
  }

  async mounted() {
    this.$store.dispatch(
      "customer/fetchCustomerProfile",
      { 
        brandId: this.$route.params.brandId
      });
      this.loadCustomerProfile();
  }

  loadCustomerProfile(){
    const userProfile = this.$store.getters["customer/userProfile"];
    this.userAvatarUrl = userProfile.img;
    this.userDetailData.firstname.data = userProfile.firstname
    this.userDetailData.lastname.data = userProfile.lastname
    this.userDetailData.birthdate.data = moment(userProfile.birthdate).format("YYYY-MM-DD")
    this.userDetailData.phone.data = userProfile.phone
    this.userDetailData.gender.data = stringToGender[userProfile.gender]
    this.userDetailData.email.data = userProfile.email
  }

  doValidate(field: any) {
    let valid = true;
    field.rules.forEach((validateCheck: any) => {
      const result = validateCheck.bind(this)(field.data);
      if (result != true) {
        field.warning = result;
        valid = false;
      }
    });
    if (valid) field.warning = "";
    return valid;
  }

  // Be careful with this recursive function
  validateAll(data: any = this.userDetailData) {
    let valid = true;
    for (const key in data) {
      if (data[key].hasOwnProperty("data")) {
        const result = this.doValidate(data[key]);
        if (!result) valid = false;
      } else {
        const result = this.validateAll(data[key]);
        if (!result) valid = false;
      }
    }
    return valid;
  }

  async saveProfile() {
    this.isLoading = true;
    const validateResult = this.validateAll();
    if(validateResult){
      await CustomerAPIConnector.updateCustomerProfile({
        firstname: this.userDetailData.firstname.data,
        lastname: this.userDetailData.lastname.data,
        birthdate: moment(this.userDetailData.birthdate.data).format("YYYY-MM-DD"),
        phone: this.userDetailData.phone.data,
        gender: stringToGender[this.userDetailData.gender.data] || this.userDetailData.gender.data,
        email: this.userDetailData.email.data,
      });
      this.$store.dispatch(
      "customer/fetchCustomerProfile",
      { 
        brandId: this.$route.params.brandId
      });
      this.isLoading = false;
      this.editMode = false;
    } else {
      this.isLoading = false;
    }
  }

  onTextFieldFocus(event: Event){
    this.showButton = false;
    this.setCaretAtEnd(event);
  }

  onTextFieldBlur(event: Event){
    this.showButton = true;
  }

  setCaretAtEnd(event: Event){
    //@ts-ignore
    const target: any = event.target;
    const length = target.value.length;
    setTimeout(() => {
      let changeBackToText = false;
      if(target.type == "email"){
        // type email will throw error for setSelectionRange
        changeBackToText = true;
        target.type = "text";
      }
      target.focus();
      target.setSelectionRange(length,length);
      if(changeBackToText){
        target.type = "text";
      }
    })
  }

  goToVerifyOTP () {
    this.$store.commit('customer/SET_REDIRECT_AFTER_VERIFIED', 'Customer_Profile')
    this.$router.push({ name: "Customer_OTP" });
  }
}
