



























import { Component, Vue } from "vue-property-decorator";
import Loading from "@/components/Loading.vue";
@Component({
  components: {
    Loading
  },
})
export default class Index extends Vue {

  loading: boolean = true;

  mounted() {
    setTimeout(() => {
      this.loading = false
    }, 1500)
  }
}
