






























































































































































































































































import { Component, Vue } from "vue-property-decorator";
import UsedIcon from "@/components/icons/UsedIcon.vue";
import ExpiredIcon from "@/components/icons/ExpiredIcon.vue";
import RevokeIcon from "@/components/icons/RevokeIcon.vue";
import ShowCode from "@/components/customer/ShowCode.vue";
import CustomerAPIConnector from "@/service/CustomerAPIConnector";
import { mapGetters } from "vuex";
import moment from "moment";
import { CouponHistory } from "@/common/type/coupon";

interface Coupon {
  name: string,
  list: Array<CouponHistory>
};

@Component({
  components: { UsedIcon, ExpiredIcon, ShowCode, RevokeIcon },
  computed: {
    ...mapGetters("customer", ["selectedBrandLogo", "isOTPVerified"]),
  },
})

export default class RedeemHistory extends Vue {
  showCode: boolean = false;
  isOTPVerified!: boolean;
  code: string = "";
  selectedRewardName: string = "-";
  selectedBrandLogo!: string;
  condition: string = "";
  isLoading = false;
  isNoCoupon = false;
  sableDefault: string = require(`@/assets/img/sable_default.svg`);
  tab: string = "ใช้งานได้";
  tabs: Array<Coupon> = [
    {
      name: "ใช้งานได้",
      list: []
    }, 
    {
      name: "ใช้แล้ว",
      list: []
    }, 
    {
      name: "หมดอายุแล้ว",
      list: []
    }];

  get shopLogo () {
    if(this.selectedBrandLogo){
      return this.selectedBrandLogo;
    }
    return this.sableDefault
  }
  async mounted() {
    this.$store.dispatch("customer/fetchCustomerProfile", {
      brandId: this.$route.params.brandId,
    });
    await this.fetchingCouponHistory();
  }

  async fetchingCouponHistory() {
    this.isLoading = true;
    const allCoupon =
      (await CustomerAPIConnector.getCustomerCouponHistory(
        this.$route.params.brandId
      )) ?? [];
    // this.isNoCoupon = !this.couponList || this.couponList.length == 0;
    if (allCoupon && allCoupon.length > 0) {
      for (let i = 0; i < allCoupon.length; i++) {
        if (this.isCouponUsed(allCoupon[i])) {
          this.tabs[1].list.push(allCoupon[i]);
        } else if (this.isCouponExpired(allCoupon[i]) || this.isCouponRevoked(allCoupon[i])) {
          this.tabs[2].list.push(allCoupon[i]);
        } else {
          this.tabs[0].list.push(allCoupon[i]);
        }
      }
    } else {
      this.tabs[0].list = [];
      this.tabs[1].list = [];
      this.tabs[2].list = [];
    }
    this.isLoading = false;
  }

  showCouponCode(coupon: any) {
    // if(this.isOTPVerified){
      console.log(`Show Coupon Code #${coupon.coupon_code}`);
      this.code = coupon.coupon_code;
      this.condition = coupon.condition;
      this.selectedRewardName = coupon.coupon_name;
      this.showCode = true;
    // }
  }

  isCouponUsed(coupon: any) {
    return coupon.status === "used";
  }

  isCouponExpired(coupon: any) {
    if (coupon.is_no_expire_limit) {
      if (coupon.is_no_expire_use_date === undefined || coupon.is_no_expire_use_date === true) {
        return false;
      }
    }
    return moment() > moment(coupon.expired_datetime);
  }

  isCouponRevoked(coupon: any) {
    return coupon.soft_delete && coupon.soft_delete_at !== null;
  }

  momentDate(date: string) {
    return moment(date).format('D/M/YYYY')
  }

  momentTime(date: string) {
    return moment(date).format('HH.mm')
  }
}
