





























































import { Component, Vue } from "vue-property-decorator";
import AppBar from "@/components/AppBar.vue";
import validator from "validator";
import BrandAPIConnector from "@/service/BrandAPIConnector";
import { BrandProfile } from "@/common/type";

import { mapGetters } from "vuex";

@Component({
  components: {
    AppBar,
  },
  computed: {
    ...mapGetters("brand", ["brandProfile"]),
  },
})
export default class BrandPointEditor extends Vue {
  pointData: any = {
    defaultExchangePurchasePerPoint: {
      data: undefined,
      warning: "",
      isChange: false, // a flag for edit purpose only
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุอัตราการให้แต้ม";
        },
        function (this: any, v: number) {
          return v > 0 || "จำนวนต้องมากกว่า 0 บาท";
        },
      ],
    },
  };
  brandProfile!: BrandProfile;
  isLoading = false;
  brandId: string = "";

  mounted() {
    this.loadExchangePoint()
  }

  async loadExchangePoint() {
    this.isLoading = true;
    this.brandId = this.brandProfile._id;
    const brandExchangePoint = await BrandAPIConnector.getBrandExchangePoint(this.brandId);
    if (brandExchangePoint) {
      this.pointData.defaultExchangePurchasePerPoint.data = brandExchangePoint.default_exchange_purchase_per_point
    } else {
      this.$store.dispatch("alert/error", "ไม่สามารถดึงข้อมูลได้");
      this.$router.push({ name: "Shop_Editor_Menu" });
    }
    this.isLoading = false;
  }

  // async loadBranchData(branchId: string) {
  //   this.isLoading = true;
  //   const userBranchResult = await BrandAPIConnector.getUserBranch(branchId);
  //   if (userBranchResult) {
  //     this.branchData.name.data = userBranchResult.branch_name;
  //     this.branchData.username.data = userBranchResult.username;
  //     this.branchData.password.data = "";
  //     this.branchData.retypePassword.data = "";
  //     this.branchData.defaultExchangePurchasePerPoint.data =
  //       userBranchResult.default_exchange_purchase_per_point;
  //     this.shopCode = userBranchResult.shop_code;
  //   } else {
  //     this.$store.dispatch("alert/error", "ไม่สามารถดึงข้อมูลได้");
  //     this.$router.push({ name: "ShopOwner_BranchesSummary" });
  //   }
  //   this.isLoading = false;
  // }

  doValidate(field: any) {
    let valid = true;
    field.rules.forEach((validateCheck: any) => {
      const result = validateCheck.bind(this)(field.data);
      if (result != true) {
        field.warning = result;
        valid = false;
      }
    });
    if (valid) field.warning = "";
    return valid;
  }

  // Be careful with this recursive function
  validateAll(data: any[] = this.pointData) {
    let valid = true;
    for (const key in data) {
      if (data[key].hasOwnProperty("data")) {
        const result = this.doValidate(data[key]);
        if (!result) valid = false;
      } else {
        const result = this.validateAll(data[key]);
        if (!result) valid = false;
      }
    }
    return valid;
  }
  async editPoint() {
    const valid = this.validateAll();
    console.log("Validate result : " + valid);
    if (valid) {
      this.isLoading = true;
      const formData = new FormData();
      this.pointData.defaultExchangePurchasePerPoint.isChange &&
        formData.append(
          "default_exchange_purchase_per_point",
          this.pointData.defaultExchangePurchasePerPoint.data
        );
      // Check if formData empty because of edit nothing.
      const isEdit = formData.entries().next().value ? true : false;
      if (!isEdit) {
        this.isLoading = false;
        return;
      }
      const patchResult = await BrandAPIConnector.updateExchangePoint(
        this.brandId + "",
        formData
      );
      if (patchResult) {
        // if ("errorMessage" in patchResult) {
        //   this.$store.dispatch("alert/error", patchResult.errorMessage);
        //   this.branchData.username.warning = patchResult.errorMessage;
        // } else {
        this.$store.dispatch("alert/success", "แก้ไขข้อมูลเรียบร้อย");
        this.loadExchangePoint();
        // }
      } else {
        this.$store.dispatch("alert/error", "ไม่สามารถแก้ไขข้อมูลได้");
      }
      this.isLoading = false;
    }
  }
}
