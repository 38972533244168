import { BrandLoginResult, BrandProfile, BrandState } from "@/common/type";
import router from "@/router";
import BrandAPIConnector from "@/service/BrandAPIConnector";
import { ActionContext } from "vuex";
import Cookies from 'js-cookie';

const state: BrandState = {
  accessToken: "",
  brandProfile: {
    _id: "",
    brand_name_loyalty: "",
    logo_loyalty: "",
    poster_image_loyalty: "",
    email_loyalty: "",
    brand_phone_loyalty: "",
    firstname_loyalty: "",
    lastname_loyalty: "",
    phone_loyalty: "",
    has_branch: false,
    isSingleBranch: false
  },
};
const actions = {
  login: async (
    { dispatch, commit }: ActionContext<BrandState, {}>,
    loginResult: BrandLoginResult
  ) => {
    if (loginResult) {
      console.log("Brand Login");
      commit("updateLoginData", loginResult);
      dispatch("fetchBrandProfile");
      const qrData = Cookies.get('rod')
      if (qrData) {
        Cookies.remove('rod')
        router.push({ name: "Branch_ActiveQR", query: { qr: qrData } });
      } else {
        router.push({ name: "ShopOwner_Menu" });
      }
    }
  },
  logout: async (
    { dispatch, commit }: ActionContext<BrandState, {}>,
    isRedirect = true
  ) => {
    commit("logout", isRedirect);
    console.log("Brand Logout");
  },
  fetchBrandProfile: async (
    { dispatch, commit, state }: ActionContext<BrandState, {}>,
    onDone: (success: boolean) => void
  ) => {
    console.log(`Fetching Brand profile`);
    const brandProfile = await BrandAPIConnector.fetchBrandProfile();
    console.log(brandProfile);
    if (!brandProfile) {
      console.log("Credential is missing");
      dispatch("alert/warning", "Session หมดอายุแล้ว กรุณา login ใหม่อีกครั้ง");
      onDone?.(false);
      router.push({
        name: "Login",
      });
    } else {
      onDone?.(true);
      if(brandProfile.isSingleBranch){
        dispatch("branch/fetchBranchProfile", '', { root: true })
      }
      commit("setBrandProfile", brandProfile);
    }
  },
};
const mutations = {
  updateLoginData: (state: BrandState, data: BrandLoginResult) => {
    state.accessToken = data.accessToken;
    localStorage.setItem("user", JSON.stringify(data));
    localStorage.setItem("mode", "USER");
  },
  logout: (state: BrandState, isRedirect: boolean) => {
    state.accessToken = "";
    state.brandProfile = {
      _id: "",
      brand_name_loyalty: "",
      logo_loyalty: "",
      poster_image_loyalty: "",
      email_loyalty: "",
      brand_phone_loyalty: "",
      firstname_loyalty: "",
      lastname_loyalty: "",
      phone_loyalty: "",
      has_branch: false,
      isSingleBranch: false
    };
    localStorage.removeItem("user");
    localStorage.removeItem("mode");
    isRedirect && router.push({ name: "Login" });
  },
  setBrandProfile: (state: BrandState, brandProfile: BrandProfile) => {
    state.brandProfile = brandProfile;
  },
};
const getters = {
  brandProfile: (state: BrandState) => state.brandProfile,
  brandName: (state: BrandState) => state.brandProfile.brand_name_loyalty,
  brandLogo: (state: BrandState) => state.brandProfile.logo_loyalty,
  isSingleBranch: (state: BrandState) => state.brandProfile.isSingleBranch,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
