<template>
  <v-app class="gp-full-height">
    <router-view />
    <error-snack />
  </v-app>
</template>
<script>
import { Component, Vue } from "vue-property-decorator";
import ErrorSnack from "@/components/ErrorSnack.vue";
import debounce from "lodash/debounce";
import loyaltyConfig from "../loyalty_config.json"

Component.registerHooks(["metaInfo"]);
@Component({
  components: { ErrorSnack },
  metaInfo: {
    title: "สะสมแต้ม by SABLE",
    viewport:
      "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no",
  },
  computed: {
    primaryLinearButtonColor () {
      if (!loyaltyConfig?.primary_linear_button_color || loyaltyConfig?.primary_linear_button_color === "") {
        return 'linear-gradient(269.1deg, #FF8D7B 0%, #FF2C00 162.63%)';
      }
      return loyaltyConfig.primary_linear_button_color
    },
    primaryColor () {
      if (!loyaltyConfig?.primary_color || loyaltyConfig?.primary_color === "") {
        return '#FF5733';
      }
      return loyaltyConfig.primary_color
    },
    pointColor () {
      if (!loyaltyConfig?.point_color || loyaltyConfig?.point_color === "") {
        return '#545554';
      }
      return loyaltyConfig.point_color
    },
    primaryLinearButtonColorDisable () {
      if (!loyaltyConfig?.primary_linear_button_color_disable || loyaltyConfig?.primary_linear_button_color_disable === "") {
        return '#D4D9E2';
      }
      return loyaltyConfig.primary_linear_button_color_disable
    },
    tertiaryBGLinearButtonColor () {
      if (!loyaltyConfig?.tertiary_linear_button_color?.background_color || loyaltyConfig?.tertiary_linear_button_color?.background_color === "") {
        return '#FFDBD6';
      }
      return loyaltyConfig.primary_linear_button_color_disable
    },
    tertiaryTextLinearButtonColor () {
      if (!loyaltyConfig?.tertiary_linear_button_color?.text_color  || loyaltyConfig?.tertiary_linear_button_color?.text_color  === "") {
        return '#FF5733';
      }
      return loyaltyConfig.tertiary_linear_button_color.text_color 
    }
  }
})

export default class App extends Vue {
  primaryLinearButtonColor
  primaryLinearButtonColorDisable
  primaryColor
  pointColor
  tertiaryBGLinearButtonColor
  tertiaryTextLinearButtonColor
  async beforeCreate() {
    try {
      console.log("Init Line Liff");
      const liffInitOption = {
        liffId: process.env.VUE_APP_LINELIFF_ID,
      };
      //@ts-ignore
      await this.$liff.init(liffInitOption);
    } catch (e) {
      console.log("Couldn't init line liff");
    }
  }

  mounted() {
    this.setViewHeight();

    const debouncedSetHeight = debounce(this.setViewHeight, 50);

    window.addEventListener("resize", debouncedSetHeight);

    this.$once("destroyed", () => {
      window.removeEventListener("resize", debouncedSetHeight);
    });

    const root = document.documentElement;
    root.style.setProperty('--sable-primary-linear-button-color', this.primaryLinearButtonColor);
    root.style.setProperty('--sable-primary-linear-button-color-disable', this.primaryLinearButtonColorDisable);
    root.style.setProperty('--sable-primary-color', this.primaryColor);
    root.style.setProperty('--sable-point-color', this.pointColor);
    root.style.setProperty('--sable-tertiary-bg-linear-button-color', this.tertiaryBGLinearButtonColor);
    root.style.setProperty('--sable-tertiary-text-linear-button-color', this.tertiaryTextLinearButtonColor);
  }

  setViewHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }
}
</script>
<style>
#app {
  font-family: "Kanit", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #171717;
}

v-app {
  font-family: "Kanit", sans-serif !important;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #171717;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<style lang="scss">
.not-select {
  // make label not selectable
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.v-menu__content {
  border-radius: 18px !important;
}

:root {
  touch-action: pan-x pan-y;
  height: 100%;
}
// Override Disable Input, increase alpha to gain readability
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgb(0 0 0 / 70%) !important;
}
</style>
<style scoped lang="scss">
/**
    Overwrite vuetify's default v-application--wrap min-height: 100vh;
    Due to the many problems with vh on mobile devices.

    Read more https://github.com/vuetifyjs/vuetify/issues/11452
*/
.gp-full-height ::v-deep .v-application--wrap {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}
</style>
