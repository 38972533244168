
















































import { Component, Vue } from "vue-property-decorator";

import { BrandProfile } from "@/common/type";

import { mapGetters } from "vuex";

interface MenuDetail {
  title: string;
  desc: string;
  icon: string;
  pathName: string;
  show: boolean;
}

@Component({
  components: {},
  computed: {
    ...mapGetters("brand", ["brandProfile"]),
  },
})

export default class ShopEditorMenu extends Vue {
  brandProfile!: BrandProfile;

  plans: Array<MenuDetail> = [
    {
      title: "ข้อมูลธุรกิจ",
      desc: "แก้ไขชื่อและรูปที่แสดงผล",
      icon: "mdi-office-building-cog",
      pathName: "ShopOwner_Profile",
      show: true,
    },
    {
      title: "ตั้งค่าแต้ม",
      desc: "กำหนดอัตราการแปลงแต้ม",
      icon: "mdi-circle-multiple-outline",
      pathName: "Brand_Point_Editor",
      show: true,
    },
    {
      title: "จัดการผู้ดูแลระบบ",
      desc: "เพิ่ม ลบ แก้ไข พนักงาน",
      icon: "mdi-card-account-details-outline",
      pathName: "Shop_All_Staff",
      show: true,
    },
    {
      title: "จัดการสาขา",
      desc: "เพิ่ม ลบ แก้ไข สาขา",
      icon: "mdi-domain",
      pathName: "Shop_All_Branch",
      show: false,
    }
  ]

  goToSetting(pathName: string) {
    this.$router.push({ name: pathName })
  }

  backToMenu() {
    this.$router.push({ name: "ShopOwner_Menu" })
  }
}
