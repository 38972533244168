



















































































































import { Component, Vue, Watch } from "vue-property-decorator";
import ShowCode from "@/components/customer/ShowCode.vue";
import { mapGetters } from "vuex";

@Component({
  components: { ShowCode },
  computed: {
    ...mapGetters("customer", [
      "customerFullName",
      "customerPoint",
      "customerImg",
      "customerReceiveCode",
      "selectedBrandLogo",
      "selectedBrandPoster",
      "isBrandReady",
      "getBrandPoint",
      "redeemText",
      "selectedBrandName",
      "getShowPointRate"
    ]),
  },
})
export default class CustomerHome extends Vue {
  showCode: boolean = false;
  selectedBrandLogo!: boolean;
  selectedBrandPoster!: boolean;
  code: string = "";
  sableDefault: string = require(`@/assets/img/sable_default.svg`);
  posterDefault: string = require(`@/assets/img/default_poster.png`);
  isGetQrReward: boolean = false;
  dialog: boolean = false;

  get shopLogo () {
    if(this.selectedBrandLogo){
      return this.selectedBrandLogo;
    }
    return this.sableDefault
  }

  get shopPoster () {
    if(this.selectedBrandPoster){
      return this.selectedBrandPoster;
    }
    return this.posterDefault
  }
  @Watch("showCode")
  onChildChanged(to: boolean, from: boolean) {
    if (from) {
      console.log("Fetch point");
      this.fetchCustomerProfile();
    }
  }

  async mounted() {
    await this.fetchCustomerProfile();
    if (this.$route.query.qr) {
      await this.$store.dispatch("customer/redeemQrCode", this.$route.query.qr);
      this.dialog = true;
      if (this.$store.getters["customer/isGetQrReward"]) {
        this.isGetQrReward = true;
      } else {
        this.isGetQrReward = false;
      }
    }
  }

  async fetchCustomerProfile() {
    const customerProfile = await this.$store.dispatch("customer/fetchCustomerProfile", {
      brandId: this.$route.params.brandId,
    });
    if (this.$route.params.brandId === '6389768326fdfadf4f61f650' && !customerProfile.external_id?.POS_TAVONKANKASET) {
      this.$router.replace({ name: "Customer_Check_External" , params: { brandId: '6389768326fdfadf4f61f650' } });
    }
  }
}
