




import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Logout extends Vue {
  async mounted() {
    console.log("logout11");
    await this.$store.dispatch("customer/logout");
    //@ts-ignore
    this.$liff.logout();
    console.log("logout");
  }
}
