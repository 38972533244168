export function authHeader() {
  // return authorization header with jwt token
  let mode = localStorage.getItem("mode")
  let user = JSON.parse(localStorage.getItem((mode + '').toLocaleLowerCase()) + "");

  if (user && user.accessToken) {
    return { Authorization: "Bearer " + user.accessToken };
  } else {
    return undefined;
  }
}
