

























































import { Component, Vue } from "vue-property-decorator";
import DateRangePicker from "@/components/DateRangePicker.vue";
import HistoryList from "@/components/HistoryList.vue";
import BrandAPIConnector from "@/service/BrandAPIConnector";
import { Moment } from "moment";
import moment from "moment";
import { mapGetters } from "vuex";
import { BrandProfile } from "@/common/type";

declare type DateRange = {
  start: Moment;
  end: Moment;
};

@Component({
  components: { DateRangePicker, HistoryList },
  computed: {
    ...mapGetters("brand", ["brandProfile"])
  },
})
export default class ShopOwnerHistory extends Vue {
  brandProfile!: BrandProfile;
  mode: "point" | "redeem" = "point";
  range: DateRange = {
    start: moment().set({ hour: 0, minute: 0 }),
    end: moment(),
  };
  loading = false;
  data: any = {
    mode: this.mode,
    data: [],
  };
  branchSelect: any = { branch_name: "ทั้งหมด", _id: -1 };
  branchList: any[] = [];
  branchListLoading: boolean = true;

  mounted() {
    this.loadingBranch();
  }

  async loadingBranch() {
    this.branchList = [{ branch_name: "ทั้งหมด", _id: -1 }];
    this.branchList.push(...((await BrandAPIConnector.getBranchList()) ?? []));
    console.log(this.branchList);

    this.branchListLoading = false;
    this.loadData();
  }

  changeMode(mode: "point" | "redeem") {
    this.mode = mode;
    this.loadData();
  }

  selectDate(range: DateRange) {
    this.range = range;
    this.loadData();
  }

  async loadData() {
    this.loading = true;
    if (this.mode == "point") {
      if (this.branchSelect._id == -1) {
        const result = await BrandAPIConnector.getBrandPointHistory(
          this.range.start,
          this.range.end
        );
        if (result) {
          this.data = {
            mode: this.mode,
            data: result,
          };
        } else {
          this.$store.dispatch("alert/error", "ไม่สามารถดึงข้อมูลได้");
        }
      } else {
        const result = await BrandAPIConnector.getBranchPointHistory(
          this.branchSelect._id,
          this.range.start,
          this.range.end
        );

        if (result) {
          this.data = {
            mode: this.mode,
            data: result,
          };
        } else {
          this.$store.dispatch("alert/error", "ไม่สามารถดึงข้อมูลได้");
        }
      }
    } else {
      if (this.branchSelect._id == -1) {
        const result = await BrandAPIConnector.getBrandCouponHistory(
          this.range.start,
          this.range.end
        );
        if (result) {
          this.data = {
            mode: this.mode,
            data: result,
          };
        } else {
          this.$store.dispatch("alert/error", "ไม่สามารถดึงข้อมูลได้");
        }
      } else {
        const result = await BrandAPIConnector.getBranchCouponHistory(
          this.branchSelect._id,
          this.range.start,
          this.range.end
        );
        if (result) {
          this.data = {
            mode: this.mode,
            data: result,
          };
        } else {
          this.$store.dispatch("alert/error", "ไม่สามารถดึงข้อมูลได้");
        }
      }
    }
    this.loading = false;
  }
}
