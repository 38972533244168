




import { Component, Vue } from "vue-property-decorator";
import Loading from "@/components/Loading.vue";
import BrandAPIConnector from "@/service/BrandAPIConnector";

@Component({ 
  components: { Loading },
})
export default class Sable extends Vue {
  async mounted() {
    const customToken = await BrandAPIConnector.getCustomToken()
    //@ts-ignore
    window.location.replace(process.env.VUE_APP_SABLE_APP + '/login?token=' + customToken)
  }
}
