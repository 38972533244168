




































































import { Component, Vue } from "vue-property-decorator";
import CalendarIcon from "@/components/icons/CalendarIcon.vue";
import moment from "moment";

@Component({
  components: { CalendarIcon },
})
export default class DateRangPicker extends Vue {
  modal: boolean = false;
  rawDateRange = [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
  dateStart = moment();
  dateEnd = moment();

  onDateRangePick(to: any[], from: any[]) {
    to.sort();
    this.dateStart = moment(to[0], "YYYY-MM-DD");
    this.dateEnd = to[1] ? moment(to[1], "YYYY-MM-DD") : moment();

    this.onSelect();
  }

  onSelect() {
    this.$emit("dateRangeSelect", {
      start: moment(this.dateStart).set({ hour: 0, minute: 0 }),
      end: moment(this.dateEnd).set({ hour: 23, minute: 59, seconds: 59 }),
    });
  }
}
