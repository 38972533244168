import moment from "moment";
import Vue from "vue";

moment.locale("th");

const filters: { [key: string]: any } = {
  nl2br: (text: string) => text.replace(/(?:\r\n|\r|\n)/g, "<br>"),
  regularDate: (date: Date) => (date ? moment(date).format("ll") : ""),
  fullDateTime: (date: Date) => {
    const momentConvert = moment(date);
    return `${momentConvert.format("L")} ${momentConvert.format("HH:mm")} น.`;
  },
  historyDateTime: (date: Date) => {
    const momentConvert = moment(date);
    return `${momentConvert.format("ll")} ${momentConvert.format("HH:mm")} น.`;
  },
};

for (let filterName in filters) {
  Vue.filter(filterName, filters[filterName]);
}
