






























import BrandAPIConnector from "@/service/BrandAPIConnector";
import ContactModal from "@/components/register/ContactModal.vue";

import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  components: { 
    ContactModal
  },
  computed: {
    ...mapGetters("brand", ["isSingleBranch"]),
  }
})
export default class BranchesSummary extends Vue {
  loading = false;
  data: any = [];
  isSingleBranch!: boolean;
  isShowComingSoonModal: boolean= false

  mounted() {
    this.loadData();
  }

  async loadData() {
    // load Data
    this.loading = true;
    this.data = await BrandAPIConnector.getBranchesListWithPurchaseValue();

    this.loading = false;
  }

  showBranchSummary(branchId: string) {
    this.$router.push({
      name: "ShopOwner_BranchSummary",
      params: { id: branchId },
    });
  }
  toggleComingSoonModal (value: boolean) {
    this.isShowComingSoonModal = value
  }

  newBranch() { 
    if(this.isSingleBranch){
      this.toggleComingSoonModal(true)
    }else {
      this.$router.push({
        name: "ShopOwner_BranchNew",
      });
    }
  }
}
