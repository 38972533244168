

























import { Vue, Prop, Component } from "vue-property-decorator";

@Component
export default class CalendarIcon extends Vue {
  @Prop({ default: 20 })
  width!: number;
  @Prop({ default: 20 })
  height!: number;
  @Prop({ default: "#FF5733" })
  color!: string;
}
