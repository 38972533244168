import { authHeader } from "@/helper/auth-header";
import router from "@/router";
import axios, { AxiosError } from "axios";
import store from "@/store";
import APIConnector from "./APIConnector";
import {
  BranchList,
  BranchListWitchPurchaseValue,
  BranchListWithSummary,
  BrandCouponList,
  BrandProfile,
  CouponData,
  CouponHistoryList,
  CreateUserBranchResult,
  CustomerOfBrandDetail,
  CustomerOfBrandList,
  FailedResult,
  PointHistoryList,
  SummaryData,
  UserBranch,
  UserBrand,
  OtpData
} from "@/common/type";
import { Moment } from "moment";

import loyaltyConfig from '../../loyalty_config.json'

class BrandAPIConnector extends APIConnector {
  constructor() {
    super();
  }

  async fetchBrandProfile(): Promise<BrandProfile | undefined> {
    try {
      const fetchResult = await axios({
        method: "get",
        url: `${this.APIPath}/brand/profile`,
        headers: authHeader(),
      });
      const { data } = fetchResult;
      return data;
    } catch (e: any) {
      console.log("Brand fetch profile failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getBranchList(): Promise<BranchList[] | undefined> {
    try {
      const fetchResult = await axios({
        method: "get",
        url: `${this.APIPath}/brand/branch`,
        headers: authHeader(),
      });
      const { data } = fetchResult;
      return data;
    } catch (e: any) {
      console.log("Brand get branch list failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getBrandSummary(
    startDate: Moment,
    endDate: Moment
  ): Promise<SummaryData | undefined> {
    try {
      const dateRangeParams = this.createDateQueryParam(startDate, endDate);
      const result = await axios({
        method: "get",
        url: `${this.APIPath}/brand/summary/stats${dateRangeParams}`,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand get brand summary failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getBranchSummary(
    branchId: string,
    startDate: Moment,
    endDate: Moment
  ): Promise<SummaryData | undefined> {
    try {
      const dateRangeParams = this.createDateQueryParam(startDate, endDate);
      const result = await axios({
        method: "get",
        url: `${this.APIPath}/brand/summary/stats/branch/${branchId}${dateRangeParams}`,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand get branch summary failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getBrandCoupon(): Promise<BrandCouponList[] | undefined> {
    try {
      const result = await axios({
        method: "get",
        url: `${this.APIPath}/brand/coupon`,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand get brand coupon failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getCoupon(couponId: string): Promise<CouponData | undefined> {
    try {
      const result = await axios({
        method: "get",
        url: `${this.APIPath}/coupon/${couponId}`,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand get coupon failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async deleteCoupon(couponId: string): Promise<CouponData | undefined> {
    try {
      const result = await axios({
        method: "delete",
        url: `${this.APIPath}/coupon/${couponId}`,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand delete coupon failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async createCoupon(formData: FormData): Promise<CouponData | undefined> {
    try {
      const result = await axios({
        method: "post",
        url: `${this.APIPath}/coupon`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          ...authHeader(),
        },
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand create coupon failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async updateCoupon(
    couponId: string,
    formData: FormData
  ): Promise<CouponData | undefined> {
    try {
      const result = await axios({
        method: "patch",
        url: `${this.APIPath}/coupon/${couponId}`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          ...authHeader(),
        },
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand update coupon failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getBrandPointHistory(
    startDate: Moment,
    endDate: Moment
  ): Promise<PointHistoryList | undefined> {
    try {
      const dateRangeParams = this.createDateQueryParam(startDate, endDate);
      const result = await axios({
        method: "get",
        url: `${this.APIPath}/brand/history/point/branches${dateRangeParams}`,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand get brand point history failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getBranchPointHistory(
    branchId: string,
    startDate: Moment,
    endDate: Moment
  ): Promise<PointHistoryList | undefined> {
    try {
      const dateRangeParams = this.createDateQueryParam(startDate, endDate);
      const result = await axios({
        method: "get",
        url: `${this.APIPath}/brand/history/point/branch/${branchId}${dateRangeParams}`,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand get branch point history failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getBrandCouponHistory(
    startDate: Moment,
    endDate: Moment
  ): Promise<CouponHistoryList | undefined> {
    try {
      const dateRangeParams = this.createDateQueryParam(startDate, endDate);
      const result = await axios({
        method: "get",
        url: `${this.APIPath}/brand/history/coupon/branches${dateRangeParams}`,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand get brand coupon history failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getBranchCouponHistory(
    branchId: string,
    startDate: Moment,
    endDate: Moment
  ): Promise<CouponHistoryList | undefined> {
    try {
      const dateRangeParams = this.createDateQueryParam(startDate, endDate);
      const result = await axios({
        method: "get",
        url: `${this.APIPath}/brand/history/coupon/branch/${branchId}${dateRangeParams}`,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand get branch coupon history failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getCustomerList(
    keyword: string
  ): Promise<CustomerOfBrandList[] | undefined> {

    const params = {
      keyword
    }
    if (loyaltyConfig?.brand_id && loyaltyConfig?.brand_id !== '') {
      // @ts-ignore
      params.brand_id = loyaltyConfig.brand_id;
      // @ts-ignore
      params.type = 'CDP';
    }

    try {
      const result = await axios({
        method: "get",
        url: `${this.APIPath}/brand/history/customers`,
        params,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand get brand customer list failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getCustomerProfile(
    customerId: string
  ): Promise<CustomerOfBrandDetail | undefined> {
    try {
      const result = await axios({
        method: "get",
        url: `${this.APIPath}/brand/history/customer/${customerId}`,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand get brand customer profile failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getBranchesListWithPurchaseValue(): Promise<
    BranchListWitchPurchaseValue | undefined
  > {
    try {
      const result = await axios({
        method: "get",
        url: `${this.APIPath}/brand/summary/branches`,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand get branches list with purchase value failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getBranchAllTimeSummary(
    branchId: string
  ): Promise<BranchListWithSummary | undefined> {
    try {
      const result = await axios({
        method: "get",
        url: `${this.APIPath}/brand/summary/branch/${branchId}`,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand get branch stats summary all time failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getUserBranch(branchId: string): Promise<UserBranch | undefined> {
    try {
      const result = await axios({
        method: "get",
        url: `${this.APIPath}/user/branch/${branchId}`,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand get user branch failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async updateUserBranch(
    branchId: string,
    updateData: FormData
  ): Promise<UserBranch | FailedResult> {
    try {
      const result = await axios({
        method: "patch",
        url: `${this.APIPath}/edit/branch/${branchId}`,
        data: updateData,
        headers: {
          "Content-Type": "multipart/form-data",
          ...authHeader(),
        },
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand update user branch failed");
      this.handleAPIFailed(e);
      const errorMessage =
        e.response?.status === 409 ? "ชื่อผู้ใช้นี้ไม่สามารถใช้ได้" : undefined;
      return { errorMessage };
    }
  }

  async createBranch(
    createData: FormData
  ): Promise<CreateUserBranchResult | FailedResult> {
    try {
      const result = await axios({
        method: "post",
        url: `${this.APIPath}/register/branch`,
        data: createData,
        headers: {
          "Content-Type": "multipart/form-data",
          ...authHeader(),
        },
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand create user branch failed");
      this.handleAPIFailed(e);
      const errorMessage =
        e.response?.status === 409 ? "ชื่อผู้ใช้นี้ไม่สามารถใช้ได้" : undefined;
      return { errorMessage };
    }
  }

  async getUserBrand(): Promise<UserBrand | undefined> {
    try {
      const result = await axios({
        method: "get",
        url: `${this.APIPath}/user/brand/`,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand get user branch failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async updateUserBrand(
    brandId: string,
    updateData: FormData
  ): Promise<UserBrand | undefined> {
    try {
      const result = await axios({
        method: "patch",
        url: `${this.APIPath}/edit/brand/${brandId}`,
        data: updateData,
        headers: {
          "Content-Type": "multipart/form-data",
          ...authHeader(),
        },
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand update user brand failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async generateBrandOTP(
    brandId: string,
  ): Promise<any | undefined> {
    try {
      const result = await axios({
        method: "patch",
        url: `${this.APIPath}/brand/otp/${brandId}`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand generate OTP failed");
      this.handleAPIFailed(e);
      return { isError: true };
    }
  }

  async getCustomToken(): Promise<any | undefined> {
    try {
      const result = await axios({
        method: "get",
        url: `${this.APIPath}/login-to-sable`,
        headers: {
          ...authHeader(),
          "Content-Type": "application/json",
        },
      });
      const { data } = result;
      return data.customToken;
    } catch (e: any) {
      console.log("getCustomToken failed");
      this.handleAPIFailed(e);
      return { isError: true };
    }
  }

  async checkIsSableUser(): Promise<any | undefined> {
    try {
      const result = await axios({
        method: "get",
        url: `${this.APIPath}/check-is-sable-user`,
        headers: {
          ...authHeader(),
          "Content-Type": "application/json",
        },
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("checkIsSableUser failed");
      this.handleAPIFailed(e);
      return { isError: true };
    }
  }

  async verifyOTP(
    brandId: string,
    otp: string,
    ref_code: string
  ): Promise<any | undefined> {
    try {
      const requestData: OtpData = {
        otp:otp,
        ref_code:ref_code
      }
      const result = await axios({
        method: "patch",
        url: `${this.APIPath}/brand/verify-otp/${brandId}`,
        data: requestData,
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand verify OTP failed");
      this.handleAPIFailed(e);
      return { isError: true };
    }
  }
  
  async getBrandExchangePoint(
    brandId: string
  )
  :Promise<any | undefined> {
    try {
      const result = await axios({
        method: "get",
        url: `${this.APIPath}/branch/point-exchange/${brandId}`,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Get brand exchange point failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async updateExchangePoint(
    brandId: string,
    updateData: FormData,
  )
  :Promise<any | undefined> {
    try {
      const result = await axios({
        method: "patch",
        url: `${this.APIPath}/branch/edit-point-exchange/${brandId}`,
        data: updateData,
        headers: {
          "Content-Type": "multipart/form-data",
          ...authHeader(),
        },
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Update exchange point failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async fetchAllStaff(
    brandId: string
  )
  :Promise<any | undefined> {
    try {
      const result = await axios({
        method: "get",
        url: `${this.APIPath}/user/all-staff/${brandId}`,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand get all staff failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async fetchAllBranch(
    brandId: string
  )
  :Promise<any | undefined> {
    try {
      const result = await axios({
        method: "get",
        url: `${this.APIPath}/user/get-branch/${brandId}`,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Brand get all branch failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getUserById (
    userId: string,
  )
  :Promise<any | undefined> {
    try {
      const result = await axios({
        method: "get",
        url: `${this.APIPath}/get/staff/${userId}`,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Get user data failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }
  
  async editProfile (
    userId: string,
    updateData: FormData,
  )
  :Promise<any | undefined> {
    try {
      const result = await axios({
        method: "patch",
        url: `${this.APIPath}/edit-profile/${userId}`,
        data: updateData,
        headers: {
          "Content-Type": "multipart/form-data",
          ...authHeader(),
        },
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Edit profile failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async createUser (
    createData: FormData,
  )
  :Promise<any | undefined> {
    try {
      const result = await axios({
        method: "post",
        url: `${this.APIPath}/edit/staff/`,
        data: createData,
        headers: {
          "Content-Type": "multipart/form-data",
          ...authHeader(),
        },
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      if (e.response.status == 409) {
        return {
          error:
            "ไม่สามารถใช้ Username นี้ได้ เนื่องจาก Username นี้ถูกใช้ไปแล้ว",
        };
      }
      console.log("Create user failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async editUser (
    userId: string,
    updateData: FormData,
  )
  :Promise<any | undefined> {
    try {
      const result = await axios({
        method: "patch",
        url: `${this.APIPath}/edit/staff/${userId}`,
        data: updateData,
        headers: {
          "Content-Type": "multipart/form-data",
          ...authHeader(),
        },
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Edit user failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async deleteUser (
    userId: string,
  )
  :Promise<any | undefined> {
    try {
      const result = await axios({
        method: "delete",
        url: `${this.APIPath}/remove/staff/${userId}`,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Delete user failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async createNewBranch (
    createData: FormData,
  )
  :Promise<any | undefined> {
    try {
      const result = await axios({
        method: "post",
        url: `${this.APIPath}/edit/branches/`,
        data: createData,
        headers: {
          "Content-Type": "multipart/form-data",
          ...authHeader(),
        },
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Create branch failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async editBranch (
    branchId: string,
    updateData: FormData,
  )
  :Promise<any | undefined> {
    try {
      const result = await axios({
        method: "patch",
        url: `${this.APIPath}/edit/branches/${branchId}`,
        data: updateData,
        headers: {
          "Content-Type": "multipart/form-data",
          ...authHeader(),
        },
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Edit branch failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async deleteBranch (
    branchId: string,
  )
  :Promise<any | undefined> {
    try {
      const result = await axios({
        method: "delete",
        url: `${this.APIPath}/remove/branches/${branchId}`,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Delete branch failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async handleAPIFailed(e: AxiosError) {
    if (e.response?.status === 401) {
      console.log("Brand token is not valid");
      store.dispatch("brand/logout");
      store.dispatch("alert/error", 'Session ของหมดอายุ กรุณาลงชื่อเข้าใช้อีกครั้ง', { root: true });
      router.push({ name: "Login" });
    } else {
      console.log("Brand API Failed");
      console.dir(e);
    }
  }

  createDateQueryParam(start: Moment, end: Moment): string {
    return start
      ? `?start=${encodeURIComponent(start.format())}${
          end ? `&end=${encodeURIComponent(end.format())}` : ""
        }`
      : "";
  }
}

export default new BrandAPIConnector();
