import { authHeader } from "@/helper/auth-header";
import router from "@/router";
import axios, { AxiosError } from "axios";
import qs from "qs";
import APIConnector from "./APIConnector";

class AgencyAPIConnector extends APIConnector {
  constructor() {
    super();
  }

  async fetchAgencyProfile() {
    try {
      const fetchResult = await axios({
        method: "get",
        url: `${this.APIPath}/agency-users/profile`,
        headers: authHeader(),
      });
      const { data } = fetchResult;
      return data;
    } catch (e: any) {
      console.log("Agency fetch profile failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async activateQR(data: {}) {
    try {
      const activateResult = await axios({
        method: "post",
        url: `${this.APIPath}/qr-code-generate/agency-activate`,
        data,
        headers: authHeader(),
      });
      return activateResult.data;
    } catch (e: any) {
      if (e.response.status == 400) {
        return {
          error:
            "ไม่สามารถ activate QR code set นี้ได้ เนื่องจากถูก activated ไปแล้ว",
        };
      }
      console.log("Activate QR Failed");
      this.handleAPIFailed(e, false);
      return undefined;
    }
  }

  async getQr(qrCode: string) {
    try {
      const qr = await axios({
        method: "get",
        url: `${this.APIPath}/qr-code-generate?qr_code=${qrCode}`,
      });
      return qr.data;
    } catch (e: any) {
      console.log("Error get QRCode");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getBrandList(keyword: string) {
    try {
      const result = await axios({
        method: "get",
        url: `${this.APIPath}/agency-users/brands${
          keyword ? `?keyword=${keyword}` : ""
        }`,
        headers: authHeader(),
      });
      const { data } = result;
      return data;
    } catch (e: any) {
      console.log("Agency get brand list failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async handleAPIFailed(e: AxiosError, isRedirect = true) {
    if (e.response?.status === 401) {
      console.log("Token is not valid");
      isRedirect && router.push({ name: "Login" });
    } else {
      console.dir(e);
      return undefined;
    }
  }
}

export default new AgencyAPIConnector();