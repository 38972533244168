

























import { Vue, Prop, Component } from "vue-property-decorator";

@Component
export default class ProfileIcon extends Vue {
  @Prop({ default: 22 })
  width!: number;
  @Prop({ default: 24 })
  height!: number;
  @Prop({ default: "#6E7191" })
  color!: string;
}
