


















import { Component, Vue, Watch } from "vue-property-decorator";
import AppBar from "@/components/AppBar.vue";
import { Route } from "vue-router";
import { mapGetters } from "vuex";

@Component({
  components: {
    AppBar,
  },
  computed: {
    ...mapGetters("brand", ["brandLogo"]),
  },
})
export default class ShopOwner extends Vue {
  shopLogoUrl: string = require(`@/assets/img/sable_logo.svg`);
  sableDefault: string = require(`@/assets/img/sable_default.svg`);
  appBarMode: string = "home";
  brandLogo!: string;
  backRouterMapping: any = {
    ShopOwner_Summary: "ShopOwner_Menu",
    ShopOwner_CouponManage: "ShopOwner_Menu",
    ShopOwner_History: "ShopOwner_Menu",
    ShopOwner_CustomerList: "ShopOwner_Menu",
    Shop_Editor_Menu: "ShopOwner_Menu",
    ShopOwner_Staff: "ShopOwner_Menu",
    ShopOwner_BranchesSummary: "Shop_Editor_Menu",
    ShopOwner_Profile: "Shop_Editor_Menu",
    Shop_All_Staff: "Shop_Editor_Menu",
    Brand_Point_Editor: "Shop_Editor_Menu",
    Shop_All_Branch: "Shop_Editor_Menu",
    Shop_Edit_Staff: "Shop_All_Staff",
    Shop_Edit_Branch: "Shop_All_Branch"
  };

  get shopLogo () {
    if(this.brandLogo){
      return this.brandLogo;
    }
    return this.sableDefault
  }
  mounted() {
    this.updateAppBar(this.$router.currentRoute.name + "");
    this.$store.dispatch("brand/fetchBrandProfile");
    console.log(this.$store.getters["brand/brandLogo"]);
  }

  @Watch("$route")
  onChildChanged(to: Route, from: Route) {
    this.updateAppBar(to.name + "");
    this.$store.dispatch("brand/fetchBrandProfile");
  }

  updateAppBar(routeName: string) {
    this.appBarMode = routeName == "ShopOwner_Menu" ? "home" : "backward";
    console.log(routeName);
    console.log(this.appBarMode);
  }

  back() {
    const backRouteName = this.backRouterMapping[this.$route.name + ""];
    if (backRouteName) {
      this.$router.push({ name: backRouteName });
    } else {
      this.$router.go(-1);
    }
  }

  logout() {
    this.$store.dispatch("brand/logout");
  }
}
