var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"branch-form-container"},[_c('div',{staticClass:"form-title"},[_vm._v(" "+_vm._s(_vm.mode == "new" ? "เพิ่มสาขาใหม่" : "แก้ไขข้อมูล")+" ")]),_c('v-form',{ref:"form"},[(!_vm.isSingleBranch)?_c('div',[_c('div',{staticClass:"input-field"},[_vm._v(" ชื่อสาขา "),_c('v-text-field',{staticClass:"input-box",class:{ 'warning-input-box': _vm.branchData.name.warning },attrs:{"type":"text","rounded":"","hide-details":"","required":"","disabled":_vm.isLoading},on:{"change":function($event){_vm.branchData.name.isChange = true;
              _vm.doValidate(_vm.branchData.name);}},model:{value:(_vm.branchData.name.data),callback:function ($$v) {_vm.$set(_vm.branchData.name, "data", $$v)},expression:"branchData.name.data"}}),_c('span',{staticClass:"warning-label"},[_vm._v(_vm._s(_vm.branchData.name.warning))])],1),_c('div',{staticClass:"input-field"},[_vm._v(" ชื่อผู้ใช้ "),_c('v-text-field',{staticClass:"input-box",class:{ 'warning-input-box': _vm.branchData.username.warning },attrs:{"type":"text","rounded":"","hide-details":"","required":"","disabled":_vm.isLoading},on:{"change":function($event){_vm.branchData.username.isChange = true;
              _vm.doValidate(_vm.branchData.username);}},model:{value:(_vm.branchData.username.data),callback:function ($$v) {_vm.$set(_vm.branchData.username, "data", $$v)},expression:"branchData.username.data"}}),_c('span',{staticClass:"warning-label"},[_vm._v(_vm._s(_vm.branchData.username.warning))])],1),_c('div',{staticClass:"input-field"},[_vm._v(" รหัสผ่าน "),_c('v-text-field',{staticClass:"input-box",class:{ 'warning-input-box': _vm.branchData.password.warning },attrs:{"type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"rounded":"","hide-details":"","required":"","disabled":_vm.isLoading},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword},"change":function($event){_vm.branchData.password.isChange = true;
              _vm.doValidate(_vm.branchData.password);}},model:{value:(_vm.branchData.password.data),callback:function ($$v) {_vm.$set(_vm.branchData.password, "data", $$v)},expression:"branchData.password.data"}}),_c('span',{staticClass:"warning-label"},[_vm._v(_vm._s(_vm.branchData.password.warning))])],1),_c('div',{staticClass:"input-field"},[_vm._v(" ยืนยันรหัสผ่าน "),_c('v-text-field',{staticClass:"input-box",class:{
              'warning-input-box': _vm.branchData.retypePassword.warning,
            },attrs:{"type":_vm.showRetypePassword ? 'text' : 'password',"append-icon":_vm.showRetypePassword ? 'mdi-eye' : 'mdi-eye-off',"rounded":"","hide-details":"","required":"","disabled":_vm.isLoading},on:{"click:append":function($event){_vm.showRetypePassword = !_vm.showRetypePassword},"change":function($event){return _vm.doValidate(_vm.branchData.retypePassword)}},model:{value:(_vm.branchData.retypePassword.data),callback:function ($$v) {_vm.$set(_vm.branchData.retypePassword, "data", $$v)},expression:"branchData.retypePassword.data"}}),_c('span',{staticClass:"warning-label"},[_vm._v(_vm._s(_vm.branchData.retypePassword.warning))])],1),(_vm.mode == 'edit')?_c('div',{staticClass:"input-field"},[_vm._v(" Shop Code "),_c('v-text-field',{staticClass:"input-box",attrs:{"type":"text","rounded":"","hide-details":"","disabled":""},model:{value:(_vm.shopCode),callback:function ($$v) {_vm.shopCode=$$v},expression:"shopCode"}})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"input-field"},[_vm._v(" จำนวนราคาต่อ 1 แต้ม "),_c('v-text-field',{staticClass:"input-box",class:{
            'warning-input-box':
              _vm.branchData.defaultExchangePurchasePerPoint.warning,
          },attrs:{"placeholder":"0","type":"text","rounded":"","hide-details":"","required":"","disabled":_vm.isLoading},on:{"change":function($event){_vm.branchData.defaultExchangePurchasePerPoint.isChange = true;
            _vm.doValidate(_vm.branchData.defaultExchangePurchasePerPoint);}},model:{value:(_vm.branchData.defaultExchangePurchasePerPoint.data),callback:function ($$v) {_vm.$set(_vm.branchData.defaultExchangePurchasePerPoint, "data", $$v)},expression:"branchData.defaultExchangePurchasePerPoint.data"}}),_c('span',{staticClass:"warning-label"},[_vm._v(_vm._s(_vm.branchData.defaultExchangePurchasePerPoint.warning))])],1)]),_c('v-btn',{staticClass:"save-btn",attrs:{"text":"","rounded":"","disabled":_vm.isLoading},on:{"click":function($event){_vm.mode == 'new' ? _vm.createBranch() : _vm.editBranch()}}},[_vm._v(" "+_vm._s(_vm.mode == "new" ? "สร้างสาขา" : "บันทึกข้อมูล")+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }