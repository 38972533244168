import { AxiosError } from "axios";

abstract class APIConnector {
  private _APIPath: string | undefined = "";

  constructor() {}

  get APIPath(): String | undefined {
    if (!this._APIPath) this._APIPath = process.env.VUE_APP_API;
    if (!this._APIPath) {
      console.error("API Path is not set!");
    }
    return this._APIPath;
  }

  abstract handleAPIFailed(e: AxiosError): void;
}

export default APIConnector;
