

























































































import { Component, Vue } from "vue-property-decorator";
import AppBar from "@/components/AppBar.vue";
import ProfileIcon from "@/components/icons/ProfileIcon.vue";
import PasswordIcon from "@/components/icons/login/PasswordIcon.vue";
import RewardIcon from "@/components/icons/RewardIcon.vue";
import BasedAPIConnector from "@/service/BasedAPIConnector";
import BranchAPIConnector from "@/service/BranchAPIConnector";
import {
  BranchLoginResult,
  BrandLoginResult,
  CustomerLoginResult,
} from "@/common/type";

@Component({
  components: {
    AppBar,
    ProfileIcon,
    PasswordIcon,
    RewardIcon,
  },
})
export default class Login extends Vue {
  username: string = "";
  password: string = "";
  shopcode: string = "";
  showPassword: boolean = false;
  sableLogin: string = require(`@/assets/img/sable_login.svg`);

  beforeCreate() {
    //Auto login any kind of user for this page
    const userData: BranchLoginResult | BrandLoginResult | CustomerLoginResult =
    JSON.parse(localStorage.getItem("user") + "");
    if (userData) {
      localStorage.setItem("mode", "USER");
      switch (userData.role) {
        case "admin":
          console.log("Found Brand account data");
          this.$store.dispatch("brand/fetchBrandProfile", (result: boolean) => {
            if (result) {
              console.log(
                "Authentication data still valid, Redirect to Shop Owner Menu"
              );
              this.$router.push({ name: "ShopOwner_Menu" });
            } else {
              console.log("Authentication data is not valid, do nothing");
            }
          });
          break;
        case "staff":
          console.log("Found Branch account data");
          this.$store.dispatch(
            "branch/fetchBranchProfile",
            (result: boolean) => {
              if (result) {
                console.log(
                  "Authentication data still valid, Redirect to Branch Menu"
                );
                this.$router.push({ name: "Branch_Menu" });
              } else {
                console.log("Authentication data is not valid, do nothing");
              }
            }
          );
          break;
        default:
          this.$store.dispatch("customer/logout");
          break;
      }
    } else {
      const agencyData: BranchLoginResult | BrandLoginResult | CustomerLoginResult =
      JSON.parse(localStorage.getItem("agency") + "");
      if (agencyData) {
        localStorage.setItem("mode", "AGENCY");
        console.log("Found Agency account data");
        this.$store.dispatch(
          "agency/fetchAgencyProfile",
          (result: boolean) => {
            if (result) {
              console.log(
                "Authentication data still valid, Redirect to Agency Menu"
              );
              this.$router.push({ name: "Agency_Menu" });
            } else {
              console.log("Authentication data is not valid, do nothing");
            }
          }
        );
      }
    }
  }

  async login(event: any) {
    event.preventDefault();
    if (this.username && this.password) {
      const loginResult = await BasedAPIConnector.login(
        this.username,
        this.password
      );
      if (loginResult) {
        switch (loginResult.role) {
          case "admin":
            this.$store.dispatch("brand/login", loginResult);
            break;
          case "staff":
            this.$store.dispatch("branch/login", loginResult);
            break;
          //@ts-ignore
          case "ADMIN":
            this.$store.dispatch("agency/login", loginResult);
            break;
        }
      } else {
        this.$store.dispatch("alert/error", "Invalid username or password");
      }
    } else if (this.shopcode) {
      const loginResult = await BranchAPIConnector.loginWithShopCode(
        this.shopcode
      );
      if (loginResult) {
        this.$store.dispatch("branch/login", loginResult);
      } else {
        this.$store.dispatch("alert/error", "Invalid Shop Code");
      }
    } else {
      this.$store.dispatch(
        "alert/error",
        "Please enter Username & Password or Shop Code"
      );
    }
  }

  register() {
    this.$router.push({ name: "Register_PromoCode" });
  }

  forgetPassword() {
    this.$router.push({ name: "Forget_Password" });
  }
}
