







































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class HistoryList extends Vue {
  @Prop({ required: true, default: { mode: "point", data: [] } })
  data!: any;
}
