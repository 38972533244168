

















































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import validator from "validator";
import BrandAPIConnector from "@/service/BrandAPIConnector";
import { mapGetters } from 'vuex'
@Component({
  components: {},
  computed: {
    ...mapGetters('branch', ["branchName"]),
    ...mapGetters('brand', ["isSingleBranch"])
  }
})
export default class ShopOwnerProfile extends Vue {
  shopOwnerProfileData: any = {
    // username: {
    //   data: "",
    //   warning: "",
    //   isChange: false,
    //   rules: [
    //     function (this: any, v: string) {
    //       return !!v || "กรุณาระบุชื่อผู้ใช้";
    //     },
    //     function (this: any, v: string) {
    //       return (
    //         validator.matches(v, "^[a-zA-Z0-9_\.\-]*$") ||
    //         "กรุณาใช้ตัวอักษรภาษาอังกฤษหรือตัวเลข"
    //       );
    //     },
    //     function (this: any, v: string) {
    //       return (
    //         validator.isLength(v, { min: 4, max: undefined }) ||
    //         "กรุณาใช้ชื่อที่มีความยาวมากกว่า 4 ตัวอักษร"
    //       );
    //     },
    //   ],
    // },
    // password: {
    //   data: "",
    //   warning: "",
    //   isChange: false,
    //   rules: [
    //     function (this: any, v: string) {
    //       return (
    //         // if in edit mode and password is not change then skip
    //         (this.isEditMode && !this.shopOwnerProfileData.password.isChange) ||
    //         !!v ||
    //         "กรุณาระบุรหัสผ่าน"
    //       );
    //     },
    //     function (this: any, v: string) {
    //       return (
    //         // if in edit mode and password is not change then skip
    //         (this.isEditMode && !this.shopOwnerProfileData.password.isChange) ||
    //         validator.isLength(v, { min: 6, max: undefined }) ||
    //         "กรุณาใช้รหัสผ่านที่มีความยาวกว่า 6 ตัวอักษร"
    //       );
    //     },
    //   ],
    // },
    // retypePassword: {
    //   data: "",
    //   warning: "",
    //   isChange: false,
    //   rules: [
    //     function (this: any, v: string) {
    //       return (
    //         // if in edit mode and password is not change then skip
    //         (this.isEditMode && !this.shopOwnerProfileData.password.isChange) ||
    //         !!v ||
    //         "กรุณายืนยันรหัสผ่าน"
    //       );
    //     },
    //     function (this: any, v: string) {
    //       return (
    //         // if in edit mode and password is not change then skip
    //         (this.isEditMode && !this.shopOwnerProfileData.password.isChange) ||
    //         v === this.shopOwnerProfileData.password.data ||
    //         "รหัสผ่านไม่ตรงกัน"
    //       );
    //     },
    //   ],
    // },
    shopLogoImage: {
      data: null,
      warning: "",
      isChange: false,
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาอัพโหลดรูปโลโก้ร้านค้า";
        },
      ],
    },
    posterImage: {
      data: null,
      warning: "",
      isChange: false,
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาอัพโหลดรูปโปสเตอร์ร้านค้า";
        },
      ],
    },
    shopName: {
      data: "",
      warning: "",
      isChange: false,
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุชื่อร้านค้า";
        },
        // function (this: any, v: string) {
        //   if((v.length + this.shopOwnerProfileData.shopBranch.data.length) > 35) return "ชื่อร้านและชื่อสาขารวมกันมีความยาวเกิน 35 ตัวอักษร"
        //   return true
        // },
      ],
    },
    hasBranch : {
      data: "false",
      warning: "",
      isChange: false,
      rules: [ 
        function (this: any, v: string) {
          !!v || "กรุณาเลือกรูปแบบสาขา";
          return true
        }, 
      ],
    },
    // shopBranch: {
    //   data: "",
    //   warning: "",
    //   rules: [
    //     function (this: any, v: string) {
    //       return !!v || "กรุณาระบุชื่อสาขา";
    //     },
    //     function (this: any, v: string) {
    //       if((v.length + this.shopOwnerProfileData.shopName.data.length) > 35) return "ชื่อร้านและชื่อสาขารวมกันมีความยาวเกิน 35 ตัวอักษร"
    //       return true
    //     },
    //     function (this: any, v: string) {
    //       if(v.includes('สาขา')) return "ไม่จำเป็นต้องกรอกคำว่า 'สาขา'"
    //       return true
    //     },
    //   ],
    // },
    email: {
      data: "",
      warning: "",
      isChange: false,
      rules: [
        function (this: any, v: string) {
          if(v) return validator.isEmail(v) || "อึเมลไม่ถูกต้อง";
          return true
        },
      ],
    },
    phone: {
      data: "",
      warning: "",
      isChange: false,
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุเบอร์โทรร้านค้า";
        },
        function (this: any, v: string) {
          return (
            validator.isMobilePhone(v, "th-TH") || "เบอร์โทรศัพท์ไม่ถูกต้อง"
          );
        },
      ],
    },
    contact: {
      firstname: {
        data: "",
        warning: "",
        isChange: false,
        rules: [
          function (this: any, v: string) {
            return !!v || "กรุณาระบุชื่อผู้ติดต่อ";
          },
        ],
      },
      lastname: {
        data: "",
        warning: "",
        isChange: false,
        rules: [
          function (this: any, v: string) {
            return !!v || "กรุณาระบุนามสกุลผู้ติดต่อ";
          },
        ],
      },
      phone: {
        data: "",
        warning: "",
        isChange: false,
        rules: [
          function (this: any, v: string) {
            return !!v || "กรุณาระบุเบอร์โทรของผู้ติดต่อ";
          },
          function (this: any, v: string) {
            return (
              validator.isMobilePhone(v, "th-TH") || "เบอร์โทรศัพท์ไม่ถูกต้อง"
            );
          },
        ],
      },
    },
  };

  brandId: string = "";
  branchName!: string;
  isSingleBranch!: boolean;

  isLoading = false;

  showPassword = false;
  showPasswordRetype = false;
  loyaltyPlan = "STARTER";

  fileUploader = {
    logo: {
      preview: "",
      warning: true,
    },
    poster: {
      preview: "",
      warning: false,
    },
  };

  @Watch("branchName")
  watchBranchNameAfterReload() {
    this.shopOwnerProfileData.shopBranch.data = this.branchName;
  }

  mounted() {
    this.loadShopOwnerProfile();
  }

  async loadShopOwnerProfile() {
    this.isLoading = true;
    const brandUser = await BrandAPIConnector.getUserBrand();
    if (brandUser) {
      this.brandId = brandUser.brand_id;
      const logoImageUrl = brandUser.logo_loyalty;
      const posterImageUrl = brandUser.poster_image_loyalty;
      this.shopOwnerProfileData.shopLogoImage.data = new File(
        [""],
        "placeHolder"
      );
      if(logoImageUrl === undefined) {
        this.shopOwnerProfileData.shopLogoImage.data = null
      } else {
        this.fileUploader.logo.preview = logoImageUrl;
      }
      this.shopOwnerProfileData.posterImage.data = new File(
        [""],
        "placeHolder"
      );
      if(posterImageUrl === undefined) {
        this.shopOwnerProfileData.posterImage.data = null
      } else {
        this.fileUploader.poster.preview = posterImageUrl;
      }
      // this.shopOwnerProfileData.username.data = brandUser.username;
      // this.shopOwnerProfileData.password.data = ""; // default
      this.shopOwnerProfileData.shopName.data = brandUser.brand_name_loyalty;
      this.shopOwnerProfileData.email.data = brandUser.email_loyalty;
      this.shopOwnerProfileData.phone.data = brandUser.brand_phone_loyalty;
      this.shopOwnerProfileData.contact.firstname.data =
        brandUser.firstname_loyalty;
      this.shopOwnerProfileData.contact.lastname.data =
        brandUser.lastname_loyalty;
      this.shopOwnerProfileData.contact.phone.data = brandUser.phone_loyalty;
      this.shopOwnerProfileData.hasBranch.data = brandUser.has_branch + "";
      // if(this.isSingleBranch){
      //   this.shopOwnerProfileData.shopBranch.data = this.branchName;
      // }
      this.loyaltyPlan = brandUser.loyalty_plan;
    } else {
      this.$store.dispatch("alert/error", "ไม่สามารถดึงข้อมูลได้");
      this.$router.push({ name: "ShopOwner_Menu" });
    }
    this.isLoading = false;
  }

  doValidate(field: any) {
    let valid = true;
    field.rules.forEach((validateCheck: any) => {
      const result = validateCheck.bind(this)(field.data);
      if (result != true) {
        field.warning = result;
        valid = false;
      }
    });
    if (valid) field.warning = "";
    return valid;
  }

  // Be careful with this recursive function
  validateAll(data: any[] = this.shopOwnerProfileData) {
    let valid = true;
    for (const key in data) {
      console.log(key);
      if (data[key].hasOwnProperty("data")) {
        // if(!this.isSingleBranch && key == 'shopBranch'){
        //   continue;
        // } else
        if (key == 'email' || key == 'shopLogoImage' || key == 'posterImage'){
          continue;
        }else {
          const result = this.doValidate(data[key]);
          if (!result) {
            valid = false;
          }
        }
      } else {
        // if(!this.isSingleBranch && key == 'shopBranch'){
        //   continue;
        // }else 
        if (key == 'email' || key == 'shopLogoImage' || key == 'posterImage'){
          continue;
        }else {
          const result = this.validateAll(data[key]);
          if (!result) {
            valid = false;
          }
        }
      }
    }
    return valid;
  }

  async save() {
    const valid = this.validateAll();
    console.log("Validate result : " + valid);
    if (valid) {
      this.isLoading = true;
      const formData = new FormData();
      // this.shopOwnerProfileData.username.isChange &&
      //   formData.append("username", this.shopOwnerProfileData.username.data);

      // this.shopOwnerProfileData.password.isChange &&
      //   formData.append("password", this.shopOwnerProfileData.password.data);

      this.shopOwnerProfileData.shopLogoImage.isChange &&
        formData.append("logo", this.shopOwnerProfileData.shopLogoImage.data);

      this.shopOwnerProfileData.posterImage.isChange &&
        formData.append("poster", this.shopOwnerProfileData.posterImage.data);

      this.shopOwnerProfileData.shopName.isChange &&
        formData.append(
          "brand_name_loyalty",
          this.shopOwnerProfileData.shopName.data
        );

      this.shopOwnerProfileData.email.isChange &&
        formData.append("email_loyalty", this.shopOwnerProfileData.email.data);

      this.shopOwnerProfileData.phone.isChange &&
        formData.append(
          "brand_phone_loyalty",
          this.shopOwnerProfileData.phone.data
        );

      this.shopOwnerProfileData.contact.firstname.isChange &&
        formData.append(
          "firstname_loyalty",
          this.shopOwnerProfileData.contact.firstname.data
        );

      this.shopOwnerProfileData.contact.lastname.isChange &&
        formData.append(
          "lastname_loyalty",
          this.shopOwnerProfileData.contact.lastname.data
        );

      this.shopOwnerProfileData.contact.phone.isChange &&
        formData.append(
          "phone_loyalty",
          this.shopOwnerProfileData.contact.phone.data
        );

      // in future Check for Active branch
      // if(this.isSingleBranch){
      //   formData.append(
      //     "branch_name",
      //     this.shopOwnerProfileData.shopBranch.data
      //   )
      // }

      formData.append(
        "has_branch",
        this.shopOwnerProfileData.hasBranch.data
      );

      // Check if formData empty because of edit nothing.
      const isEdit = formData.entries().next().value ? true : false;
      if (!isEdit) {
        console.log("nothing change");
        this.isLoading = false;
        return;
      }
      const patchResult = await BrandAPIConnector.updateUserBrand(
        this.brandId,
        formData
      );
      if (patchResult) {
        this.$store.dispatch("alert/success", "แก้ไขข้อมูลเรียบร้อย");
        // this.loadShopOwnerProfile();
        // this.isEditMode = false;
        // Need to refresh because file loader still stored the previous image
        this.$router.go(0);
      } else {
        this.$store.dispatch("alert/error", "ไม่สามารถแก้ไขข้อมูลได้");
      }
      this.isLoading = false;
    }
  }

  redirectClickUpload(refs: string) {
    (this.$refs[refs] as any).$children[0].$el.click();
  }

  filesChange(field: "logo" | "poster", file: File) {
    if (!file) {
      this.fileUploader[field].preview = "";
      return;
    }
    const propertyName = field == "logo" ? "shopLogoImage" : "posterImage";
    if (file.size > 5000000) {
      this.$store.dispatch("alert/error", "รูปภาพมีขนาดใหญ่เกินไป");
      this.shopOwnerProfileData[propertyName].warning = "รูปมีขนาดใหญ่เกินไป";
      return;
    }
    console.log("reading");
    const reader = new FileReader();
    reader.onload = (e: any) => {
      console.log("reader onload");
      this.fileUploader[field].preview = e.target.result + "";
      this.shopOwnerProfileData[propertyName].warning = "";
    };
    reader.readAsDataURL(file);
  }
}
