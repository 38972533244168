




















































import { Component, Vue } from "vue-property-decorator";
import AppBar from "@/components/AppBar.vue";
import BasedAPIConnector from "@/service/BasedAPIConnector";

type userData = {
  phone: string
  brandId: string
}

@Component({
  components: {
    AppBar
  },
})

export default class ResetByTel extends Vue {
  valid: boolean = true
  telNo: string = ""
  errorMessages: string = ""
  rules:Array<Function> = [
    (value: string) => !!value || '* กรุณากรอกเบอร์โทรศัพท์',
    (value: string) => !!(new RegExp('^([0]){1}([689]){1}([0-9]){8}$')).test(value) || '* เบอร์โทรศัพท์ของคุณไม่ถูกต้อง'
  ]

  async checkTelNo() {
    //@ts-ignore
    if(this.$refs.form.validate()){
      const response = await BasedAPIConnector.forgotPassword(this.telNo)
      if(response) {
        const data: userData = { phone: this.telNo, brandId: response.data.id }
        this.$emit("callbackToParent", data)
      }else {
        this.errorMessages = 'ไม่พบเบอร์โทรศัพท์ในระบบ'
      }
    }
  }

  handleChange () {
    if (this.errorMessages != "") {
      this.errorMessages = ""
    }
  }
}
