

















import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  components: {},
  computed: {
    ...mapGetters("alert", ["isShowing"]),
  },
})
export default class ShowCode extends Vue {
  isError: boolean = false;
  text: string = "";
  color: string = "pink";
  colorMapping: any = {
    success: "green",
    info: "blue",
    warning: "yellow",
    error: "red",
  };

  @Watch("isShowing")
  onAlertMessageChange(show: any): void {
    if (show) {
      const type = this.$store.getters["alert/type"] + "";
      this.text = this.$store.getters["alert/message"];
      this.color = this.colorMapping[type];
      this.$store.dispatch("alert/clear");
      this.isError = true;
    }
  }
}
