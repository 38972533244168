




















































































































import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import ScanCode from "@/components/branch/ScanCode.vue";

@Component({
  components: {
    ScanCode
  },

  computed: {
    ...mapGetters("agency", ["activeNumber", "errorText"]),
  }
})
export default class AgencyActiveQR extends Vue {
  toggleScanCode: boolean = false;

  qrPrefix: String = "";
  iconScanQrComplete: String = require(`@/assets/img/active_qr.png`);
  iconScanQr1: String = require(`@/assets/img/qr_not_active1.svg`);
  iconScanQrArrow: String = require(`@/assets/img/qr_not_arrow.svg`);
  iconScanQr2: String = require(`@/assets/img/qr_not_active2.svg`);
  iconScanQrWrong: String = require(`@/assets/img/qr_scan_wrong.svg`);
  scanCount: number = 0;
  activatedComplete: boolean = false;
  scanBtnText: String = "สแกน";
  beforeBtnScanText: String = "สแกนชุดแรกที่ต้องการเปิดใช้งาน";
  showModal: boolean = false;

  async mounted () {
    await this.$store.dispatch("agency/resetState");
    if (this.$route.query.qr) {
      const scanQr = this.$route.query.qr.toString();
      await this.$store.dispatch("agency/activatedFirstQr", scanQr);
      if(!this.$store.getters["agency/isError"]) {
        this.qrPrefix = this.$store.getters["agency/qrSet"];
        this.scanCount = 1;
        this.activatedComplete = true;
        this.scanBtnText = "เปิดเพิ่ม";
      } else {
        this.showModal = true;
      }
    }
    window.addEventListener("popstate", (event) => {
      this.$router.push({ name: "Agency" });
    });
  }

  backToMenu () {
    this.$router.push({ name: "Agency" });
  }

  async onDecode(qrCode: string) {
    const qr = qrCode;
    const qrSplit = qr.split("qr=");
    const codeRedeem = qrSplit[1] != undefined ? qrSplit[1] : "";
    if (this.scanCount === 0) {
      await this.$store.dispatch("agency/activatedFirstQr", codeRedeem);
      if(!this.$store.getters["agency/isError"]) {
        this.scanCount++;
        this.activatedComplete = true;
        this.scanBtnText = "สแกนเซตสุดท้าย"
        this.qrPrefix = this.$store.getters["agency/qrSet"]
      } else {
        this.showModal = true;
      }
    } else {
      await this.$store.dispatch("agency/activatedRangeQr", codeRedeem);
      if(!this.$store.getters["agency/isError"]) {
        this.scanCount++;
        this.qrPrefix = this.$store.getters["agency/qrSet"]
      } else {
        this.showModal = true;
      }
    }
  }

  beforeDestroy () {
    document.removeEventListener("popstate", (event) => {
      this.$router.push({ name: "Agency" });
    });
  }
}
