























import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
//@ts-ignore
import StreamBarcodeReader from "./StreamBarcodeReader.vue";

@Component({
  components: { StreamBarcodeReader },
})
export default class ScanCode extends Vue {
  @PropSync("show", { type: Boolean, default: false, required: true })
  showSync!: boolean;
  @Prop({ required: true })
  label!: string;

  close() {
    this.showSync = false;
  }

  onDecode(code: string) {
    // Prevent decode emit event when hiding
    if (this.showSync == false) return;
    console.log(`Scan Code result : ${code}`);
    this.showSync = false;
    this.$emit("onDecode", code);
  }
}
