
















































































import { Component, Vue } from "vue-property-decorator";
import CustomerAPIConnector from "@/service/CustomerAPIConnector";
import { CouponData } from "@/common/type/coupon";
import moment from "moment";
import dayjs from "dayjs";

@Component({
  components: {},
})

export default class CustomerReward extends Vue {
  userAvatarUrl: string = require(`@/assets/img/avatar.png`);
  shopLogoUrl: string = require(`@/assets/img/sable_logo.svg`);
  shopBranchImageUrl: string = require(`@/assets/img/branch_image.png`);

  couponList: Array<CouponData> = [];
  isCouponLoaded = false;

  isLoading = false;
  isNoCoupon = false;

  async mounted() {
    this.isLoading = true;
    this.$store.dispatch("customer/fetchCustomerProfile", {
      brandId: this.$route.params.brandId,
      callback: () => {
        if (!this.couponList) {
          this.fetchingCouponReward();
        }
      },
    });
    this.fetchingCouponReward();
  }

  checkAfterExpiredDate (date: string) {
    return moment(date, 'YYYY-MM-DD').add(1, 'day').isBefore(moment())
  }

  checkฺBeforeStartDate (date: string) {
    return dayjs().isBefore(dayjs(date))
  }

  moment(date: string) {
    return moment(date)
  }

  async fetchingCouponReward() {
    this.isLoading = true;
    this.couponList =
      (await CustomerAPIConnector.getBrandCouponList(
        this.$route.params.brandId,
        this.$store.getters["customer/userProfile"]._id
      )) ?? [];
    if (!this.couponList) {
      this.isCouponLoaded = false;
    } else {
      this.isCouponLoaded = true;
    }
    this.isNoCoupon = this.couponList.length == 0;
    this.isLoading = false;
  }

  openCoupon(couponId: String) {
    console.log(`Open Coupon #${couponId}`);
    this.$router.push({
      name: "Customer_Coupon",
      params: { id: "" + couponId },
    });
  }
}
