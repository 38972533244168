







import { Component, Vue } from "vue-property-decorator";

@Component({
})
export default class Loading extends Vue {
  loyaltyBySable: string = require(`@/assets/img/loyalty-by-sable.svg`);
  sableLoading: string = require(`@/assets/img/sable-loading.svg`);
}
