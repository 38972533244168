









































import { BrandCouponList } from "@/common/type";
import BrandAPIConnector from "@/service/BrandAPIConnector";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ShopOwnerCouponManage extends Vue {
  couponList: BrandCouponList[] = [];
  isLoading = false;

  mounted() {
    this.loadCouponData();
  }

  async loadCouponData() {
    this.isLoading = true;
    this.couponList = (await BrandAPIConnector.getBrandCoupon()) ?? [];
    this.isLoading = false;
  }

  newCoupon() {
    this.$router.push({ name: "ShopOwner_Coupon_New" });
  }

  editCoupon(couponId: string) {
    this.$router.push({
      name: "ShopOwner_Coupon_Edit",
      params: { id: couponId },
    });
  }
}
