


















import { Vue, Prop, Component } from "vue-property-decorator";

@Component
export default class RevokeIcon extends Vue {
  @Prop({ default: 74 })
  width!: number;
  @Prop({ default: 70 })
  height!: number;
  @Prop({ default: "#797979" })
  color!: string;
}
