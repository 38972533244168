






























































































































import { Component, Vue, Watch } from "vue-property-decorator";
import validator from "validator";
import AppBar from "@/components/AppBar.vue";
import ContactModal from "@/components/register/ContactModal.vue";
import InsertPointModal from "@/components/register/InsertPointModal.vue";
import BasedAPIConnector from "@/service/BasedAPIConnector";
import moment from "moment";

interface PlanDetail {
  maxUser: number;
  maxStaff: number;
  supportBranch: boolean;
}

interface PackageDetail {
  name: string;
  detail: PlanDetail;
}

@Component({
  components: {
    AppBar,
    ContactModal,
    InsertPointModal
  },
  computed: {
    nextTwoYears () {
      return moment().add('2', 'y').format('DD/MM/YYYY');
    }
  },
})

export default class PromoCode extends Vue {
  aipenLogoURL: string = require(`@/assets/img/sable_logo.svg`);
  greenCheck: String = require(`@/assets/img/green_check.svg`);
  plans: Array<PackageDetail> = [
    {
      name: "STARTER",
      detail: {
        maxUser: 400,
        maxStaff: 1,
        supportBranch: false
      }
    },
    {
      name: "STANDARD",
      detail: {
        maxUser: 1000,
        maxStaff: 5,
        supportBranch: true
      }
    },
    {
      name: "PREMIUM",
      detail: {
        maxUser: 5000,
        maxStaff: 20,
        supportBranch: true
      }
    }
  ]
  selectedPlan: any = {
    id: "",
    name: ""
  }
  // isShowContactModal: boolean = false
  // totalPrice: number = 990

  promoCode: any = {
    data: null,
    warning: ""
  }
  isDisabled: boolean = true
  isLoading: boolean = false
  isDirty: boolean = false

  // @Watch("selectedPlan")
  // showContactModel() {
  //   if(this.selectedPlan == "Business Plan"){
  //     this.toggleContactModal(true)
  //   }
  // }

  // toggleContactModal (value: boolean) {
  //   if(!value) {
  //     this.selectedPlan = "STARTER"
  //     this.totalPrice = 990
  //   }
  //   this.isShowContactModal = value
  // }

  back() {
    this.$router.push({ name: "Login" });
  }

  async doValidate(item: any){
    this.isDirty = true;
    this.selectedPlan.name = "";
    this.selectedPlan.id = "";
    this.promoCode.warning = "";

    if (item.data.length !== 6) {
      this.isDisabled = true;
      this.promoCode.warning = "กรุณากรอกรหัส 6 หลัก";
    } else {
      const codeDetail = await BasedAPIConnector.getLoyaltyCodeDetail(item.data);
      if (codeDetail) {
        if (codeDetail.error) {
          this.isDisabled = true;
          this.promoCode.warning = codeDetail.error;
        } else {
          this.selectedPlan.name = codeDetail.loyalty_plan;
          this.selectedPlan.id = codeDetail._id;
          if (codeDetail.status_code === "OPEN") {
            this.isDisabled = false;
          } else {
            this.isDisabled = true;
            this.promoCode.warning = "รหัสนี้เคยถูกเปิดใช้งานแล้ว";
          }
        }
      }
    }
  }

  verify() {
    this.isLoading = true
    setTimeout(() => {
      this.isLoading = false
      this.$router.push({ name: "Register", params: { plan: this.selectedPlan.name, code_id: this.selectedPlan.id } });
    }, 800)
  }

}
