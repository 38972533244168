







































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import AppBar from "@/components/AppBar.vue";
import DatePicker from "@/components/DatePicker.vue";
import ModalDelete from "@/components/owner/reward/ModalDelete.vue";
import BrandAPIConnector from "@/service/BrandAPIConnector";
import { Moment } from "moment";
import moment from "moment";

declare type DateRange = {
  start: Moment;
  end: Moment;
};
@Component({
  components: {
    AppBar,
    DatePicker,
    ModalDelete
  },
})
export default class CouponEditor extends Vue {
  mode: "new" | "edit" = "new";
  couponIdTarget: string | null = null;
  binImage: string = require('@/assets/img/bin.svg')
  isShow: boolean = false
  couponData: any = {
    couponImage: {
      data: null,
      warning: "",
      isChange: false, // a flag for edit purpose only
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาอัพโหลดรูปโลโก้ร้านค้า";
        },
      ],
    },
    name: {
      data: "",
      warning: "",
      isChange: false, // a flag for edit purpose only
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุชื่อรีวอร์ด";
        },
      ],
    },
    amount: {
      data: "",
      warning: "",
      isChange: false, // a flag for edit purpose only
      rules: [
        function (this: any, v: number) {
          if (this.couponData.isNOLimit.data) {
            return true
          }
          return !!v || "กรุณาระบุจำนวน";
        },
        function (this: any, v: number) {
          if (this.couponData.isNOLimit.data) {
            return true
          }
          return v >= 0 || "จำนวนต้องไม่น้อยกว่า 0";
        },
        function (this: any, v: number) {
          if (this.couponData.isNOLimit.data) {
            return true
          }
          return v <= 1000000 || "จำนวนต้องไม่เกิน 1,000,000";
        },
      ],
    },
    exchangePoint: {
      data: "",
      warning: "",
      isChange: false, // a flag for edit purpose only
      rules: [
        function (this: any, v: number) {
          if (v === 0) {
            return true
          }
          return !!v || "กรุณาระบุจำนวน";
        },
        function (this: any, v: number) {
          return v >= 0 || "จำนวนต้องมากกว่าหรือเท่ากับ 0";
        },
        function (this: any, v: number) {
          return v <= 1000000 || "จำนวนต้องน้อยกว่า 1,000,000";
        },
      ],
    },
    purchaseValue: {
      data: "",
      warning: "",
      isChange: false, // a flag for edit purpose only
      rules: [
        function (this: any, v: number) {
          if (this.couponData.isRewardNOValue.data) {
            return true
          }
          return !!v || "กรุณาระบุจำนวน";
        },
        function (this: any, v: number) {
          if (this.couponData.isRewardNOValue.data) {
            return true
          }
          return v > 0 || "จำนวนต้องมากกว่า 0";
        },
      ],
    },
    purchaseValueUnit: {
      data: "",
      warning: "",
      isChange: false,
      rules: [
        function (this: any, v: number) {
          if (this.couponData.isRewardNOValue.data) {
            return true
          }
          return !!v || "กรุณาระบุหน่วย";
        },
      ],
    },
    condition: {
      data: "",
      warning: "",
      isChange: false, // a flag for edit purpose only
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุเงื่อนไข";
        },
      ],
    },
    is_no_expire_limit: {
      data: 1,
      warning: "",
      rules: [],
      isChange: false, // a flag for edit purpose only
    },
    isNOLimit: {
      data: true,
      warning: "",
      rules: [],
      isChange: false, // a flag for edit purpose only
    },
    isRewardNOValue: {
      data: true,
      warning: "",
      rules: [],
      isChange: false, // a flag for edit purpose only
    },
    isRestrict: {
      data: true,
      warning: "",
      rules: [],
      isChange: false, // a flag for edit purpose only
    }
  };
  range: DateRange = {
    start: moment(),
    end: moment().add('1', 'month'),
  };
  rangeChange:boolean = false

  isLoading = false;

  couponImageUpload = {
    preview: "",
    warning: true,
  };
  fileReaderTimeout!: number;
  imageReading = false;
  purchaseUnits = ["บาท", "%"]

  mounted() {
    if (this.$route.params.id) {
      console.log("Edit mode");
      this.mode = "edit";
      this.couponIdTarget = this.$route.params.id;
      this.loadCouponData(this.couponIdTarget);
    } else {
      console.log("New mode");
      this.mode = "new";
      this.couponData.condition.data = `- ลูกค้าสามารถใช้งานรีวอร์ดนี้ได้ในระยะเวลาที่กำหนดเท่านั้น
- รีวอร์ด 1 รีวอร์ด สามารถใช้ได้เพียง 1 ครั้ง 
- รีวอร์ดนี้ไม่สามารถใช้แลกรับสินค้า/บริการ อื่น ๆ นอกเหนือจากที่กำหนดได้
- เมื่อลูกค้าแลกรับรีวอร์ดดังกล่างแล้ว จะไม่สามารถนำรีวอร์ดแลกกลับมาเป็นแต้มสะสม หรือขอเปลี่ยนเป็นเงินสดได้`
    }
  }

  async loadCouponData(couponId: string) {
    this.isLoading = true;

    const coupon = await BrandAPIConnector.getCoupon(couponId);
    if (coupon) {
      const couponImageUrl = coupon.img;
      this.couponData.couponImage.data = new File([""], "placeHolder");
      this.couponImageUpload.preview = couponImageUrl;
      this.couponData.name.data = coupon.name;
      this.couponData.exchangePoint.data = coupon.exchange_point;
      this.couponData.isNOLimit.data = coupon.amount === -1;
      if (coupon.amount !== -1) {
        this.couponData.amount.data = coupon.amount;
      }
      this.couponData.isRewardNOValue.data = coupon.purchase_value === -1;
      if (coupon.purchase_value !== -1) {
        this.couponData.purchaseValue.data = coupon.purchase_value;
        this.couponData.purchaseValueUnit.data = coupon.purchase_value_unit;
      }
      this.couponData.condition.data = coupon.condition;
      this.couponData.is_no_expire_limit.data = +coupon.is_no_expire_limit;
      this.couponData.isRestrict.data = coupon.is_restrict;
      this.range.start =  moment(moment(coupon.start_date).utcOffset('+0700').format('YYYY-MM-DD'))
      this.range.end =  moment(moment(coupon.expired_date).utcOffset('+0700').format('YYYY-MM-DD'))
    } else {
      this.$store.dispatch("alert/error", "ไม่สามารถดึงข้อมูลรีวอร์ดได้");
      this.$router.push({ name: "ShopOwner_CouponManage" });
    }
    this.isLoading = false;
  }

  doValidate(field: any) {
    let valid = true;
    field.rules.forEach((validateCheck: any) => {
      const result = validateCheck.bind(this)(field.data);
      if (result != true) {
        field.warning = result;
        valid = false;
      }
    });
    if (valid) field.warning = "";
    return valid;
  }

  // Be careful with this recursive function
  validateAll(data: any[] = this.couponData) {
    let valid = true;
    for (const key in data) {
      if (data[key].hasOwnProperty("data")) {
        const result = this.doValidate(data[key]);
        if (!result) valid = false;
      } else {
        const result = this.validateAll(data[key]);
        if (!result) valid = false;
      }
    }
    return valid;
  }

  selectDate(value: DateRange) {
    this.rangeChange = true
    this.range = value
  }

  async createCoupon() {
    const valid = this.validateAll();
    console.log("Validate result : " + valid);
    if (valid) {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("name", this.couponData.name.data);
      formData.append("img", this.couponData.couponImage.data);
      if (this.couponData.isNOLimit.data) {
        formData.append("amount", '-1');
        formData.append("max_amount", '-1');
        formData.append("purchase_value_unit", "บาท");
      } else {
        formData.append("amount", this.couponData.amount.data);
        formData.append("max_amount", this.couponData.amount.data);
        formData.append("purchase_value_unit", this.couponData.purchaseValueUnit.data);
      }
      formData.append("exchange_point", this.couponData.exchangePoint.data);
      if (this.couponData.isRewardNOValue.data) {
        formData.append("purchase_value", '-1');
      } else {
        formData.append("purchase_value", this.couponData.purchaseValue.data);
      }
      formData.append("condition", this.couponData.condition.data);
      formData.append("is_no_expire_limit", this.couponData.is_no_expire_limit.data);
      formData.append("is_restrict", this.couponData.isRestrict.data);
      if(!this.couponData.is_no_expire_limit.data) {
        formData.append("start_date", moment(this.range.start).startOf('day').toISOString());
        formData.append("expired_date", moment(this.range.end).endOf('day').toISOString());
      } 
      const result = await BrandAPIConnector.createCoupon(formData);
      if (result) {
        this.$store.dispatch("alert/success", "สร้างรีวอร์ดสำเร็จ");
        this.$router.push({ name: "ShopOwner_CouponManage" });
      } else {
        this.$store.dispatch("alert/error", "ไม่สามารถสร้างรีวอร์ดได้");
      }
      this.isLoading = false;
    }
  }

  async editCoupon() {
    const valid = this.validateAll();
    console.log("Validate result : " + valid);
    if (valid) {
      this.isLoading = true;
      const formData = new FormData();
      this.couponData.name.isChange &&
        formData.append("name", this.couponData.name.data);
      this.couponData.couponImage.isChange &&
        formData.append("img", this.couponData.couponImage.data);
      if (this.couponData.isNOLimit.data) {
        formData.append("amount", '-1');
        formData.append("max_amount", '-1');
        formData.append("purchase_value_unit", "บาท");
      } else {
        this.couponData.amount.isChange &&
          formData.append("amount", this.couponData.amount.data);
        this.couponData.amount.isChange &&
          formData.append("max_amount", this.couponData.amount.data);         
        this.couponData.purchaseValueUnit.isChange &&
          formData.append("purchase_value_unit", this.couponData.purchaseValueUnit.data); 
      }
      this.couponData.exchangePoint.isChange &&
        formData.append("exchange_point", this.couponData.exchangePoint.data);
      if (this.couponData.isRewardNOValue.data) {
        formData.append("purchase_value", '-1');
      } else {
        this.couponData.purchaseValue.isChange &&
          formData.append("purchase_value", this.couponData.purchaseValue.data);
      }
      this.couponData.condition.isChange &&
      formData.append("condition", this.couponData.condition.data);
      this.couponData.is_no_expire_limit.isChange &&
      formData.append("is_no_expire_limit", this.couponData.is_no_expire_limit.data);
      formData.append("is_restrict", this.couponData.isRestrict.data);
      if(!this.couponData.is_no_expire_limit.data) {
        this.rangeChange &&  formData.append("start_date", moment(this.range.start).startOf('day').toISOString());
        this.rangeChange &&  formData.append("expired_date", moment(this.range.end).endOf('day').toISOString());
      } 
      // Check if formData empty because of edit nothing.
      const isEdit = formData.entries().next().value ? true : false;
      if (!isEdit) {
        this.isLoading = false;
        return;
      }
      console.log(formData);
      const result = await BrandAPIConnector.updateCoupon(
        this.couponIdTarget + "",
        formData
      );
      if (result) {
        this.$store.dispatch("alert/success", "อัพเดทรีวอร์ดสำเร็จ");
        this.loadCouponData(this.couponIdTarget + "");
      } else {
        this.$store.dispatch("alert/error", "ไม่สามารถอัพเดทรีวอร์ดได้");
      }
      this.isLoading = false;
    }
  }

  redirectClickUpload(refs: string) {
    (this.$refs[refs] as any).$children[0].$el.click();
    // Fix for Ghost Case file choose broke at least show error
    this.couponData.couponImage.warning = "ไฟล์รูปไม่รองรับ/ไม่ถูกต้อง";
  }

  resetUploadField() {
    this.couponData.couponImage.data = null;
    this.couponImageUpload.preview = "";
    this.couponData.couponImage.warning = "";
    this.imageReading = false;
  }

  couponImageChange(file: File) {
    clearTimeout(this.fileReaderTimeout);
    if (!file) {
      this.resetUploadField();
      return;
    }
    if (file.size > 5000000) {
      this.$store.dispatch("alert/error", "รูปภาพมีขนาดใหญ่เกินไป");
      this.couponData.couponImage.warning = "รูปมีขนาดใหญ่เกินไป";
      this.resetUploadField();
      return;
    }

    this.imageReading = true;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      // File reading complete
      this.couponImageUpload.preview = e.target.result + "";
      this.couponData.couponImage.warning = "";
      clearTimeout(this.fileReaderTimeout);
      this.imageReading = false;
    };
    reader.onerror = (e: any) => {
      this.$store.dispatch("alert/error", "เกิดข้อผิดพลาด กรุณาลองใหม่");
      console.error(e);
      this.resetUploadField();
      clearTimeout(this.fileReaderTimeout);
    };
    reader.readAsDataURL(file);
    if (reader.readyState == 0) {
      this.$store.dispatch("alert/error", "ไฟล์รูปไม่รองรับ/ไม่ถูกต้อง");
      this.resetUploadField();
    }
    this.fileReaderTimeout = setTimeout(() => {
      this.$store.dispatch("alert/error", "ไฟล์รูปไม่รองรับ/ไม่ถูกต้อง");
      this.resetUploadField();
    }, 5000);
  }

  toggleModal (value: boolean) {
    this.isShow = value
  }
}
