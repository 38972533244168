
export const toFixedWithoutRound = (number: any, fixDigit: number, isWithComma: boolean) => {
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixDigit || -1) + '})?')

  if (!number || number === Infinity || typeof number === 'string') {
    return (0).toFixed(fixDigit + 1)
  }
  // console.log(number)
  const numString = number.toFixed(fixDigit + 1).match(re)[0] // fixDigit + 1 for not round

  // console.log("STRING", numString)
  number = parseFloat(numString)
  // console.log("NUMBER", number, "fixDigit", fixDigit)

  return isWithComma ? replaceWithComma(numString) : number
}

export const replaceWithComma = (numString: string) => {
  const numStringComma = numString.split('.')
  numStringComma[0] = numStringComma[0].replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  // console.log("numStringComma :", numStringComma)
  return numStringComma.join('.')
}
