
































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import ScanCode from "@/components/branch/ScanCode.vue";
import BranchAPIConnector from "@/service/BranchAPIConnector";
import { replaceWithComma } from "@/helper/formatHelper";

@Component({
  components: { ScanCode },
})
export default class BranchGivePoint extends Vue {
  purchaseValue!: number | string;
  pointEarn: number = 0;

  toggleScanCode: boolean = false;

  confirmDialog: boolean = false;
  receiveCode: string = "";

  isDisable: boolean = true;
  isTelNoMode: boolean = false;
  telNo: string = "";
  tabs: number = 0;

  exchangeRate: number = 0;
  givePointTo: string = "";
  isError: boolean = false;

  rules:Array<Function> = [
    (value: string) => !!(new RegExp('^([0]){1}([689]){1}([0-9]){8}$')).test(value) || '* เบอร์โทรศัพท์ของคุณไม่ถูกต้อง'
  ]

  mounted () {
    this.exchangeRate = this.$store.getters["branch/exchangePurchasePerPoint"];
  }

  get maximumValue () {
    const exchangeRate = this.$store.getters["branch/exchangePurchasePerPoint"];
    if(this.tabs) {
      return Math.floor(50000 / exchangeRate)
    }
    return 50000
  }

  @Watch('tabs')
  clearDataChangeTab () {
    this.purchaseValue = ''
    this.pointEarn = 0
  }
  
  replaceWithComma(value: string) {
    return replaceWithComma(value)
  }
  calculatePointGain() {
    this.validatePurchaseValue();
    // const exchangeRate = this.$store.getters["branch/exchangePurchasePerPoint"];
    if (this.tabs === 1) {
      this.pointEarn = +this.purchaseValue;
    } else {
      this.pointEarn = Math.floor(+this.purchaseValue / this.exchangeRate);
    }
    this.isDisable = this.pointEarn == 0;
  }

  calPointToPurchaseValue(point: number) {
    // const exchangeRate = this.$store.getters["branch/exchangePurchasePerPoint"];
    return Math.floor(point * this.exchangeRate);
  }

  startScanCode() {
    if (this.pointEarn == 0) return;
    this.toggleScanCode = true;
    //@ts-ignore
    // this.$liff.scanCodeV2().then((result) => {
    //   this.onDecode(result);
    // });
  }

  insertTelNo() {
    if (this.pointEarn == 0) return;
    this.isTelNoMode = true
    //@ts-ignore
    // this.$liff.scanCodeV2().then((result) => {
    //   this.onDecode(result);
    // });
  }

  async onDecode(receiveCode: string) {
    this.receiveCode = receiveCode;
    await this.$store.dispatch("branch/GetCustomerNameFromReceiveCode", receiveCode);
    this.isError = this.$store.getters["branch/isError"];
    if (this.isError) {
      this.givePointTo = "";
      this.confirmDialog = false;
      this.$store.dispatch("alert/error", "มีข้อผิดพลาดเกิดขึ้น");
    } else {
      this.givePointTo = this.$store.getters["branch/givePointTo"];
      if (this.givePointTo === "") {
        this.$store.dispatch("alert/error", "ไม่พบ QR Code นี้ในระบบ");
        this.confirmDialog = false;
      } else {
        this.confirmDialog = true;
      }
    }
  }

  async getCustomerFromTelNo() {
    await this.$store.dispatch("branch/GetCustomerNameFromPhoneNo", this.telNo);
    this.isError = this.$store.getters["branch/isError"];
    if (this.isError) {
      this.givePointTo = "";
      this.confirmDialog = false;
      this.$store.dispatch("alert/error", "มีข้อผิดพลาดเกิดขึ้น");
    } else {
      this.givePointTo = this.$store.getters["branch/givePointTo"];
      this.confirmDialog = true;
    }
  }
 
  cancel() {
    const isTelMode = this.isTelNoMode;
    const purchaseVal = this.purchaseValue;
    const point = this.pointEarn;
    this.clearData();
    this.isTelNoMode = isTelMode;
    this.purchaseValue = purchaseVal;
    this.pointEarn = point;
  }

  clearData() {
    this.isTelNoMode = false;
    this.telNo = "";
    this.confirmDialog = false;
    this.receiveCode = "";
    this.purchaseValue = "";
    this.pointEarn = 0;
    this.givePointTo = "";
    this.isError = false;
  }

  validatePurchaseValue() {
    const unit = this.tabs ? 'แต้ม' : 'บาท'
    // if (this.purchaseValue > this.maximumValue) {
    //   Vue.set(this, "purchaseValue", this.maximumValue);
    //   alert(`กรุณาระบุยอดซื้อไม่เกิน ${this.replaceWithComma(this.maximumValue + '')} ${unit}`);
    //   // to make input html update value
    //   this.$nextTick(() => {
    //     Vue.set(this, "purchaseValue", this.maximumValue);
    //     this.$forceUpdate();
    //   });
    // } else 
    if (this.purchaseValue < 0) {
      Vue.set(this, "purchaseValue", 0);
      alert(`กรุณาระบุยอดซื้อไม่ต่ำกว่า 0 ${unit}`);
      // to make input html update value
      this.$nextTick(() => {
        Vue.set(this, "purchaseValue", 0);
        this.$forceUpdate();
      });
    }
    this.isDisable =
      !this.purchaseValue ||
      this.purchaseValue <= 0;
      // this.purchaseValue > this.maximumValue;
  }

  async confirm() {
    const userData = this.isTelNoMode ? this.telNo :  this.receiveCode
    let calPurchase = this.purchaseValue
    if (this.tabs === 1) {
      // const exchangeRate = this.$store.getters["branch/exchangePurchasePerPoint"];
      calPurchase = Math.floor(+this.purchaseValue * this.exchangeRate)
    }
    // sending API to add point
    const givenResult = await BranchAPIConnector.giveCode(
      userData, +calPurchase
    );

    if (givenResult) {
      // success
      this.confirmDialog = false;
      this.clearData();
      this.calculatePointGain();
      this.$store.dispatch("alert/success", "ให้แต้มลูกค้าเรียบร้อยแล้ว");
    } else {
      this.$store.dispatch(
        "alert/error",
        "ไม่สามารถดำเนินการได้ กรุณาลองใหม่อีกครั้ง"
      );
    }
  }

  setCaretAtEnd(event: Event) {
    //@ts-ignore
    const target: any = event.target;
    const length = target.value.length;
    setTimeout(() => {
      target.type = "text";
      target.focus();
      target.setSelectionRange(length, length);
      target.type = "number";
    });
  }
}
