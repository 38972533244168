









































































import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import ScanCode from "@/components/branch/ScanCode.vue";

@Component({
  components: {
    ScanCode
  },
})
export default class BranchActiveQR extends Vue {
  toggleScanCode: boolean = false;

  qrPrefix: String = "";
  iconScanQrComplete: String = require(`@/assets/img/active_qr.png`);
  iconScanQr: String = require(`@/assets/img/active_qr_not.png`);
  firstPrefix: String = "";
  lastPrefix: String = "";
  scanCount: number = 0;
  activatedComplete: Boolean = true;
  scanBtnText: String = "สแกน";
  point: number = 0;
  endBtnText: string = "กลับหน้าหลัก";
  isError: boolean = false;
  errorText: String = "";
  allActivatedQr: number = 0;

  async mounted () {
    if (this.$route.query.qr) {
      this.checkQrStatus(this.$route.query.qr.toString());
      
      window.addEventListener("popstate", (event) => {
        const userData = localStorage.getItem("user");
        if (userData) {
          const data = JSON.parse(userData);
          if (data.role == "admin") {
            this.$router.push({ name: "ShopOwner_Menu" });  
          } else {
            this.$router.push({ name: "Branch_Menu" });
          }  
        }
      });
    }
  }

  async checkQrStatus (qrCode: string) {
    await this.$store.dispatch("branch/CheckQrActivate", qrCode);
      if (!this.$store.getters["branch/isError"]) {
        this.scanCount = 1;
        this.qrPrefix = this.$store.getters["branch/qrSet"];
        this.scanBtnText = "สแกนเพิ่ม"
        this.activatedComplete = false;
        this.endBtnText = "ยืนยัน";
        this.isError = false;
        this.errorText = "";
      } else {
        this.activatedComplete = true;
        this.isError = true;
        this.errorText = this.$store.getters["branch/errorText"];
        this.endBtnText = "เสร็จสิ้น";
      }
  }

  async activateQR() {
    if (this.activatedComplete) {
      const userData = localStorage.getItem("user");
      if (userData) {
        const data = JSON.parse(userData);
        if (data.role == "admin") {
          this.$router.push({ name: "ShopOwner_Menu" });  
        } else {
          this.$router.push({ name: "Branch_Menu" });
        }  
      }
    } else {
      if (this.point < 1) {
        this.isError = true;
        this.errorText = "แต้มต้องมากกว่า 0";
        return;
      }
      const data = { qrData: this.qrPrefix, point: this.point }
      await this.$store.dispatch("branch/ActivateBrandQr", data);
      this.allActivatedQr = this.$store.getters["branch/allActivatedQr"];
      this.isError = false;
      this.errorText = "";
      this.activatedComplete = true;
      this.endBtnText = "เสร็จสิ้น";
    }
  }

  onDecode(qrCode: string) {
    const codeSplit = qrCode.split("qr=");
    const code = codeSplit[1] != undefined ? codeSplit[1] : "";
    this.toggleScanCode = false;
    this.checkQrStatus(code);
  }

  beforeDestroy () {
    window.removeEventListener("popstate", (event) => {
      const userData = localStorage.getItem("user");
      if (userData) {
        const data = JSON.parse(userData);
        if (data.role == "admin") {
          this.$router.push({ name: "ShopOwner_Menu" });  
        } else {
          this.$router.push({ name: "Branch_Menu" });
        }  
      }
    });
  }
}
