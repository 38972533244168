











import { Component, Vue } from "vue-property-decorator";
import LocationIcon from "@/components/icons/LocationIcon.vue";
import MenuBranchListIcon from "@/components/icons/menu/MenuCustomerListIcon.vue";
import MenuYellowQRIcon from "@/components/icons/menu/MenuYellowQRIcon.vue";
import MenuBox from "@/components/MenuBox.vue";
import { MenuBoxData } from "@/common/type";
import { mapGetters } from "vuex";

@Component({
  components: {
    LocationIcon,
    MenuYellowQRIcon,
    MenuBranchListIcon,
    MenuBox,
  },
  computed: {
    ...mapGetters("agency", ["agencyFullName"]),
  },
})
export default class AgencyMenu extends Vue {
  menuItems: MenuBoxData[] = [
    {
      name: "active_qr",
      label: "เปิดการใช้งาน QR",
      icon: MenuYellowQRIcon,
      highlightColor: 'Yellow',
      isHighlight: true,
      routeName: "Agency_ActiveQR",
    },
    {
      name: "shop_list",
      label: "รายชื่อร้านค้า",
      icon: MenuBranchListIcon,
      routeName: "Agency_ShopList",
    },
  ];
  async mounted() {
    await this.$store.dispatch("agency/fetchAgencyProfile");
  }
}
