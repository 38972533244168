





































import { Component, Prop, Vue } from "vue-property-decorator";
import { MenuBoxData } from "@/common/type";

@Component({
  components: {},
})
export default class MenuBox extends Vue {
  @Prop({ required: true, default: [] })
  private menuItems!: MenuBoxData[];

  @Prop({ required: true })
  private isMarginTop!: boolean;

  redirect(menu: MenuBoxData) {
    if(menu.handleClick) {
      menu.handleClick()
    } else {
      this.$router.push({ name: menu.routeName });
    }
  }
}
