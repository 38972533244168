




import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
})

export default class AppBar extends Vue {
  @Prop({ default: "05" })
  private min!: string;
  @Prop({ default: "00" })
  private sec!: string;
    
  countdownMin: string = ""
  countdownSec: string = ""

  created() {
    this.countdownMin = this.min;
    this.countdownSec = this.sec;
    this.countDown();
  }

  countDown() {
    let countDownFunc = setInterval(() => {
      let numSec = +this.countdownSec - 1;
      if (numSec == -1) {
        this.countdownSec = "59";
      } else {
        if (numSec < 10) {
          this.countdownSec = "0" + numSec;
        } else {
          this.countdownSec = numSec + '';
        }
      }
      if (this.countdownMin == "00" && this.countdownSec == "00") {
        clearInterval(countDownFunc);
        setTimeout(() => {
          this.$emit("handleTimeOut", true);
        }, 1000);
      }
    }, 1000);
  }

  @Watch("countdownSec")
  watchCountdownSec() {
    if (this.countdownSec == "59") {
      let numMin = this.countdownMin == '00' ? '00' : +this.countdownMin - 1;
      if (numMin < 10) {
        this.countdownMin = "0" + +numMin;
      } else {
        this.countdownMin = numMin + '';
      }
    }
  }
};
