























































import { Vue, Prop, Component } from "vue-property-decorator";

@Component
export default class MenuBranchListIcon extends Vue {
  @Prop({ default: 53 })
  width!: number;
  @Prop({ default: 53 })
  height!: number;
  @Prop({ default: "#FFFFFF" })
  color!: string;
}
