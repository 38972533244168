




























import { Component, Vue, Prop } from "vue-property-decorator";
import BrandAPIConnector from "@/service/BrandAPIConnector";

@Component({
  components: {},
})
export default class ContactModal extends Vue {
  @Prop({ default: false })
  private isShow!: boolean;
  @Prop()
  private couponId!: string;

  toggleModal(value: boolean) {
    this.$emit("toggleModal", value);
  }

  async deleteReward() {
    const coupon = await BrandAPIConnector.deleteCoupon(this.couponId);
    if (coupon && coupon.soft_delete) {
      this.$store.dispatch("alert/success", "ลบรีวอร์ดสำเร็จ");
      this.toggleModal(true);
      this.$router.push({ name: 'ShopOwner_CouponManage' });
    } else {
        this.$store.dispatch(
          "alert/error",
          "ไม่สามารถลบรีวอร์ดได้ กรุณาลองใหม่อีกครั้ง"
        );
        this.$emit("callbackToParent", true)
      }
    console.log('🚀 ~ file: ModalDelete.vue ~ line 48 ~ ContactModal ~ deleteReward ~ coupon', coupon)
  }

}
