import { authHeader } from "@/helper/auth-header";
import router from "@/router";
import axios, { AxiosError } from "axios";
import qs from "qs";
import store from "@/store";
import APIConnector from "./APIConnector";
import {
  BranchLoginResult,
  BranchProfile,
  PointHistory,
  PointHistoryList,
  ScanCouponCodeResult,
  UseCouponResult,
} from "@/common/type";
import { CouponHistoryList } from "@/common/type/couponHistory";
import { Moment } from "moment";

import loyaltyConfig from '../../loyalty_config.json'

class BranchAPIConnector extends APIConnector {
  constructor() {
    super();
  }

  async loginWithShopCode(
    shopCode: string
  ): Promise<BranchLoginResult | undefined> {
    try {
      const authData = {
        strategy: "shopcode",
        shop_code: shopCode,
      };
      const authResult = await axios({
        method: "post",
        url: `${this.APIPath}/authentication`,
        data: qs.stringify(authData),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const { data } = authResult;
      const result: BranchLoginResult = {
        accessToken: data.accessToken,
        user: data.user,
        role: data.user.role as "staff",
      };
      return result;
    } catch (e: any) {
      console.log("Branch Login Failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async fetchBranchProfile(): Promise<BranchProfile | undefined> {
    try {
      const requestResult = await axios({
        method: "get",
        url: `${this.APIPath}/branch/profile`,
        headers: authHeader(),
      });
      const { data } = requestResult;
      return data;
    } catch (e: any) {
      console.log("Branch Fetch Profile Failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async giveCode(
    receiveCode: string,
    purchaseValue: number,
    isUpdatedPurchaseValue: boolean = true
  ): Promise<PointHistory | undefined> {
    try {
      const body = {
        receive_code: receiveCode,
        purchase_value: purchaseValue,
        is_updated_purchase_value: isUpdatedPurchaseValue
      }

      if (loyaltyConfig?.brand_id && loyaltyConfig?.brand_id !== '') {
        // @ts-ignore
        body.brand_id = loyaltyConfig.brand_id;
        // @ts-ignore
        body.type = 'CDP';
      }

      const givenData = qs.stringify(body);
      
      const requestResult = await axios({
        method: "post",
        url: `${this.APIPath}/branch/give-point`,
        headers: {
          ...authHeader(),
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: givenData,
      });
      const { data } = requestResult;
      return data;
    } catch (e: any) {
      console.log("Branch Give Code Failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getCouponData(
    couponCode: string
  ): Promise<ScanCouponCodeResult | undefined> {
    try {
      const requestResult = await axios({
        method: "get",
        url: `${this.APIPath}/branch/scan-coupon/${couponCode}`,
        headers: authHeader(),
      });
      const { data } = requestResult;
      return data;
    } catch (e: any) {
      console.log("Branch Get Coupon Code Failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async useCoupon(
    couponHistoryId: string
  ): Promise<UseCouponResult | undefined> {
    try {
      const couponData = qs.stringify({
        coupon_history_id: couponHistoryId,
      });
      const requestResult = await axios({
        method: "post",
        url: `${this.APIPath}/branch/use-coupon`,
        headers: {
          ...authHeader(),
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: couponData,
      });
      const { data } = requestResult;
      return data;
    } catch (e: any) {
      console.log("Branch use coupon Failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getPointHistory(
    start: Moment,
    end: Moment
  ): Promise<PointHistoryList | undefined> {
    try {
      const requestResult = await axios({
        method: "get",
        url: `${this.APIPath}/branch/history/point?start=${encodeURIComponent(
          start.format()
        )}&end=${encodeURIComponent(end.format())}`,
        headers: authHeader(),
      });
      const { data } = requestResult;
      return data;
    } catch (e: any) {
      console.log("Branch get point history Failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getCouponHistory(
    start: Moment,
    end: Moment
  ): Promise<CouponHistoryList | undefined> {
    try {
      const requestResult = await axios({
        method: "get",
        url: `${this.APIPath}/branch/history/coupon?start=${encodeURIComponent(
          start.format()
        )}&end=${encodeURIComponent(end.format())}`,
        headers: authHeader(),
      });
      const { data } = requestResult;
      return data;
    } catch (e: any) {
      console.log("Branch get coupon history Failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async activateQR(rawData: any) {
    try {
      const activateResult = await axios({
        method: "post",
        url: `${this.APIPath}/qr-code-generate/shop-activate`,
        data: rawData,
        headers: {
          ...authHeader(),
        },
      });
      return activateResult.data;
    } catch (e: any) {
      if (e.response.status == 400) {
        return {
          error:
            "ไม่สามารถ activate QR code set นี้ได้ เนื่องจากถูก activated ไปแล้ว",
        };
      }
      console.log("Activate QR Failed");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getQr(qrCode: string) {
    try {
      const qr = await axios({
        method: "get",
        url: `${this.APIPath}/qr-code-generate?qr_code=${qrCode}`,
      });
      return qr.data;
    } catch (e: any) {
      console.log("Error get QRCode");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async checkQrActivate(qrSet: string) {
    try {
      const qr = await axios({
        method: "get",
        url: `${this.APIPath}/qr-code-generate/shop-activate?qr_code_set=${qrSet}`,
        headers: {
          ...authHeader(),
        },
      });
      return qr.data;
    } catch (e: any) {
      this.handleAPIFailed(e);
      console.log("Error get QRCode");
      return undefined;
    }
  }

  async searchCustomerWithReceiveCode(receiveCode: string) {
    try {
      const customer = await axios({
        method: "get",
        url: `${this.APIPath}/search-with-receive-code/${receiveCode}`,
        headers: {
          ...authHeader(),
        },
      });
      return customer.data;
    } catch (e: any) {
      this.handleAPIFailed(e);
      console.log("Error get customer from Receive code");
      return undefined;
    }
  }

  async searchCustomerWithPhoneNo(phoneNo: string) {
    const params = {}
    if (loyaltyConfig?.brand_id && loyaltyConfig?.brand_id !== '') {
      // @ts-ignore
      params.brand_id = loyaltyConfig.brand_id;
      // @ts-ignore
      params.type = 'CDP';
    }

    try {
      const customer = await axios({
        method: "get",
        url: `${this.APIPath}/search-with-phone-no/${phoneNo}`,
        params,
        headers: {
          ...authHeader(),
        },
      });
      return customer.data;
    } catch (e: any) {
      this.handleAPIFailed(e);
      console.log("Error get customer from Phone Number");
      return undefined;
    }
  }

  async handleAPIFailed(e: AxiosError) {
    if (e.response?.status === 401) {
      console.log("Branch token is not valid");
      store.dispatch("alert/error", 'Session ของหมดอายุ กรุณาลงชื่อเข้าใช้อีกครั้ง', { root: true });
      store.dispatch("branch/logout");
      router.push({ name: "Login" });
    } else {
      console.log("Branch API Failed");
      console.dir(e);
    }
  }
}

export default new BranchAPIConnector();
