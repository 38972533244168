






















































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import validator from "validator";
import BrandAPIConnector from "@/service/BrandAPIConnector";
import { mapGetters } from 'vuex'

interface branchDetail {
  id: string,
  name: string
}

@Component({
  components: {},
  computed: {
    ...mapGetters("brand", ["brandProfile"]),
  },
})

export default class ShopEditStaff extends Vue {
  staffProfileData: any = {
    role: {
      data: "staff",
      warning: "",
      isChange: false,
      rules: [
        function (this: any, v: string) {
          return (
            v === "staff" || v === "admin" || "เป็นได้เฉพาะ staff หรือ admin เท่านั้น"
          );
        }
      ]
    },
    branch: {
      data: "",
      warning: "",
      isChange: false,
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุสาขา"
        },
      ],
    },
    id: {
      data: "",
      rules: []
    },
    username: {
      data: "",
      warning: "",
      isChange: false,
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุชื่อผู้ใช้";
        },
        function (this: any, v: string) {
          return (
            validator.matches(v, "^[a-zA-Z0-9_\.\-]*$") ||
            "กรุณาใช้ตัวอักษรภาษาอังกฤษหรือตัวเลข"
          );
        },
        function (this: any, v: string) {
          return (
            validator.isLength(v, { min: 4, max: undefined }) ||
            "กรุณาใช้ชื่อที่มีความยาวมากกว่า 4 ตัวอักษร"
          );
        },
      ],
    },
    password: {
      data: "",
      warning: "",
      isChange: false,
      rules: [
        function (this: any, v: string) {
          return (
            // if in edit mode and password is not change then skip
            (this.isEditMode && !this.staffProfileData.password.isChange) ||
            !!v ||
            "กรุณาระบุรหัสผ่าน"
          );
        },
        function (this: any, v: string) {
          return (
            // if in edit mode and password is not change then skip
            (this.isEditMode && !this.staffProfileData.password.isChange) ||
            validator.isLength(v, { min: 6, max: undefined }) ||
            "กรุณาใช้รหัสผ่านที่มีความยาวกว่า 6 ตัวอักษร"
          );
        },
      ],
    },
    retypePassword: {
      data: "",
      warning: "",
      isChange: false,
      rules: [
        function (this: any, v: string) {
          return (
            // if in edit mode and password is not change then skip
            (this.isEditMode && !this.staffProfileData.password.isChange) ||
            !!v ||
            "กรุณายืนยันรหัสผ่าน"
          );
        },
        function (this: any, v: string) {
          return (
            // if in edit mode and password is not change then skip
            (this.isEditMode && !this.staffProfileData.password.isChange) ||
            v === this.staffProfileData.password.data ||
            "รหัสผ่านไม่ตรงกัน"
          );
        },
      ],
    },
    firstname: {
      data: "",
      warning: "",
      isChange: false,
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุชื่อ";
        },
      ],
    },
    lastname: {
      data: "",
      warning: "",
      isChange: false,
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุนามสกุล";
        },
      ],
    },
  };

  brandId: string = "";

  isEditMode = false;
  isLoading = false;

  showPassword = false;
  showPasswordRetype = false;

  roleItems = [
    'staff',
    'admin'
  ];

  allBranchDetail: Array<branchDetail> = [];
  branchName: Array<string> = [];

  mounted() {
    this.loadShopOwnerProfile();
    if (this.$route.params.id) {
      this.staffProfileData.username.data = this.$route.params.username;
      this.staffProfileData.role.data = this.$route.params.role;
      if (this.staffProfileData.role.data === 'staff') {
        this.staffProfileData.branch.data = this.$route.params.branch;
      } else if (this.staffProfileData.role.data === 'admin') {
        this.staffProfileData.branch.data = "";
      }
      this.staffProfileData.firstname.data = this.$route.params.firstname;
      this.staffProfileData.lastname.data = this.$route.params.lastname;
      this.staffProfileData.id.data = this.$route.params.id;
      this.isEditMode = true;
    } else {
      this.isEditMode = false;      
    }
  }

  async loadShopOwnerProfile() {
    this.isLoading = true;
    const brandUser = await BrandAPIConnector.getUserBrand();
    if (brandUser) {
      this.brandId = brandUser.brand_id;
      // this.staffProfileData.username.data = brandUser.username;
      this.staffProfileData.password.data = ""; // default
      const allBranch = await BrandAPIConnector.fetchAllBranch(this.brandId);
      if (allBranch) {
        for (let i = 0; i < allBranch.length; i++) {
          const allDetail = {
            id: allBranch[i]._id,
            name: allBranch[i].branch_name
          };
          this.allBranchDetail.push(allDetail);
          this.branchName.push(allBranch[i].branch_name);
        }
        this.staffProfileData.branch.data = this.branchName[0]
      }
    } else {
      this.$store.dispatch("alert/error", "ไม่สามารถดึงข้อมูลได้");
      this.$router.push({ name: "Shop_All_Staff" });
    }
    this.isLoading = false;
  }

  toggleEditMode() {
    this.isEditMode = true;
  }

  doValidate(field: any) {
    let valid = true;
    field.rules.forEach((validateCheck: any) => {
      const result = validateCheck.bind(this)(field.data);
      if (result != true) {
        field.warning = result;
        valid = false;
      }
    });
    if (valid) field.warning = "";
    return valid;
  }

  // Be careful with this recursive function
  validateAll(data: any[] = this.staffProfileData) {
    let valid = true;
    for (const key in data) {
      if (data[key].hasOwnProperty("data")) {
        const result = this.doValidate(data[key]);
        if (!result) {
          valid = false;
        }
      } else {
        const result = this.validateAll(data[key]);
        if (!result) {
          valid = false;
        }
      }
    }
    return valid;
  }

  async save() {
    const valid = this.validateAll();
    console.log("Validate result : " + valid);
    if (valid) {
      this.isLoading = true;
      const formData = new FormData();

      this.staffProfileData.role.isChange &&
        formData.append("role", this.staffProfileData.role.data);

      if (this.staffProfileData.role.isChange && this.staffProfileData.role.data === 'admin') {
        formData.append("branch", JSON.stringify(this.allBranchDetail.map(x => x.id)))
      } else if ((this.staffProfileData.role.isChange && this.staffProfileData.role.data === 'staff') || this.staffProfileData.branch.isChange) {
        const findBranch = this.allBranchDetail.find(x => x.name === this.staffProfileData.branch.data)
        if (findBranch) {
          formData.append("branch", JSON.stringify([findBranch.id]))
        }
      }
      
      this.staffProfileData.username.isChange &&
        formData.append("username", this.staffProfileData.username.data);

      this.staffProfileData.password.isChange &&
        formData.append("password", this.staffProfileData.password.data);

      this.staffProfileData.firstname.isChange &&
        formData.append("firstname", this.staffProfileData.firstname.data);

      this.staffProfileData.lastname.isChange &&
        formData.append("lastname", this.staffProfileData.lastname.data);

      if (this.isEditMode) {
        // Check if formData empty because of edit nothing.
        const isEdit = formData.entries().next().value ? true : false;
        if (!isEdit) {
          console.log("nothing change");
          this.isLoading = false;
          return;
        }
        const patchResult = await BrandAPIConnector.editUser(
          this.staffProfileData.id.data,
          formData
        );
        if (patchResult) {
          this.$store.dispatch("alert/success", "แก้ไขข้อมูลเรียบร้อย");
          this.$router.push({ name: "Shop_All_Staff" });
        } else {
          this.$store.dispatch("alert/error", "ไม่สามารถแก้ไขข้อมูลได้");
        }
      } else {
        formData.append("brandId", this.brandId);

        !this.staffProfileData.role.isChange &&
          formData.append("role", this.staffProfileData.role.data);

        if (!this.staffProfileData.role.isChange && this.staffProfileData.role.data === 'admin') {
          formData.append("branch", JSON.stringify(this.allBranchDetail.map(x => x.id)))
        } else if ((!this.staffProfileData.role.isChange && this.staffProfileData.role.data === 'staff') && !this.staffProfileData.branch.isChange) {
          const findBranch = this.allBranchDetail.find(x => x.name === this.staffProfileData.branch.data)
          if (findBranch) {
            formData.append("branch", JSON.stringify([findBranch.id]))
          }
        }
        const createResult = await BrandAPIConnector.createUser(
          formData
        );
        if (createResult) {
          if (createResult.error) {
            this.$store.dispatch("alert/error", createResult.error);
          } else {
            this.$store.dispatch("alert/success", "สร้างผู้ใช้ใหม่สำเร็จ");
            this.$router.push({ name: "Shop_All_Staff" });
          }
        } else {
          this.$store.dispatch("alert/error", "ไม่สามารถสร้างผู้ใช้ใหม่ได้");
        }
      }
      this.isLoading = false;
    }
  }
}
