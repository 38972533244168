


























































import { Component, Vue, Prop } from "vue-property-decorator";
import CalendarIcon from "@/components/icons/CalendarIcon.vue";
import moment, { Moment } from "moment";

@Component({
  components: { CalendarIcon },
})
export default class DateRangPicker extends Vue {
  @Prop({ default: moment() })
  private dateStart!: Moment;
  @Prop({ default: moment().add('1', 'month') })
  private dateEnd!: Moment;

  modal: boolean = false;
  date_start: Moment = moment()
  date_end: Moment = moment().add('1', 'month')
  rawDateRange = [moment().format("YYYY-MM-DD"), moment().add('1', 'month').format("YYYY-MM-DD")];

  mounted() {
    if(this.dateStart && this.dateEnd){
      this.rawDateRange =[moment(this.dateStart).format("YYYY-MM-DD"), moment(this.dateEnd).format("YYYY-MM-DD")];
    }
  }

  checkIsToday (date: any) {
    return moment().isSame(moment(date, 'YYYY-MM-DD'), 'date')
  }
  onDateRangePick(to: any[], from: any[]) {
    to.sort();
    this.date_start = moment(to[0], "YYYY-MM-DD");
    this.date_end = to[1] ? moment(to[1], "YYYY-MM-DD") : moment(to[0], "YYYY-MM-DD");

    this.onSelect();
  }

  onSelect() {
    this.$emit("dateRangeSelect", {
      start: moment(this.date_start).set({ hour: 0, minute: 0 }),
      end: moment(this.date_end).set({ hour: 23, minute: 59, seconds: 59 }),
    });
  }
}
