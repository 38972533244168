export enum CrossError {
  COUPON_EXPIRED = 20,
  COUPON_INVALID_BRAND = 21,
  COUPON_IS_NOT_VALID = 22,
  COUPON_IS_OUT_OF_STOCK = 23,
  COUPON_HAS_BEEN_USED = 26,
  CUSTOMER_NOT_ENOUGH_POINT = 50,
}

const mapCrossErrorMessage: { [key: number]: string } = {
  [CrossError.COUPON_EXPIRED]: "รีวอร์ดหมดอายุแล้ว",
  [CrossError.COUPON_INVALID_BRAND]: "รีวอร์ดไม่ถูกต้อง",
  [CrossError.COUPON_IS_NOT_VALID]: "รีวอร์ดไม่ถูกต้อง",
  [CrossError.COUPON_IS_OUT_OF_STOCK]: "รีวอร์ดถูกใช้สิทธิเต็มแล้ว",
  [CrossError.COUPON_HAS_BEEN_USED]: "รีวอร์ดเคยถูกใช้แล้ว",
  [CrossError.CUSTOMER_NOT_ENOUGH_POINT]: "แต้มสะสมไม่เพียงพอ",
};

export const getErrorMessage = (
  error: number | undefined
): string | undefined => (error ? mapCrossErrorMessage[error] : undefined);
