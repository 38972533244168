




































import BrandAPIConnector from "@/service/BrandAPIConnector";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class BranchSummary extends Vue {
  loading = false;
  data: any = {
    purchase_value: 0,
    point: 0,
    exchange_point: 0,
    coupon_worth_value: 0,
  };
  branchId: string = "";
  branchName: string = "";

  mounted() {
    if (!this.$route.params.id) {
      this.$router.push({ name: "ShopOwner_BranchesSummary" });
    }
    this.branchId = this.$route.params.id;
    this.loadData();
  }

  async loadData() {
    // load Data
    this.loading = true;
    const result = await BrandAPIConnector.getBranchAllTimeSummary(
      this.branchId
    );
    if (result) {
      const { purchase_value, point, exchange_point, coupon_worth_value } =
        result.summary;
      this.branchName = result.branch_name;
      this.data = {
        purchase_value,
        point,
        exchange_point,
        coupon_worth_value,
      };
    } else {
      this.$store.dispatch("alert/error", "สาขาไม่ถูกต้อง");
      this.$router.push({ name: "ShopOwner_BranchesSummary" });
    }
    this.loading = false;
  }

  editBranch() {
    this.$router.push({
      name: "ShopOwner_BranchEdit",
      params: { id: this.branchId },
    });
  }
}
