
























































import { Component, Vue } from "vue-property-decorator";
import CustomerAPIConnector from "@/service/CustomerAPIConnector";
import Countdown from "@/components/Countdown.vue";
import { mapGetters, mapState } from "vuex";
import { CustomerProfile } from "@/common/type";
import moment from "moment";

import loyaltyConfig from '../../../loyalty_config.json'

@Component({
  components: {
    Countdown
  },
  computed: {
    ...mapGetters("customer", ["userProfile", "selectedBrandId"]),
  },
})
export default class CustomerOTP extends Vue {
  // mailImg: string = require(`@/assets/img/mail.png`);
  loading: boolean = false
  otp: string = ""
  refCode: string = "-"
  rules:Array<Function> = [
    (value: string) => !!value || '* กรุณากรอกเบอร์โทรศัพท์',
    (value: string) => !!(new RegExp('^([0]){1}([689]){1}([0-9]){8}$')).test(value) || '* เบอร์โทรศัพท์ของคุณไม่ถูกต้อง'
  ]
  userProfile!:CustomerProfile;
  snackbar: boolean = false
  OTPMin: string = '05'
  OTPSec: string = '00'
  resendMin: string = '01'
  resendSec: string = '00'
  snackbarColor: string = 'error'
  snackbarText: string = 'หมดเวลากรุณาส่ง OTP อีกครั้ง'
  disableResend: boolean = false
  selectedBrandId!: string
  //@ts-ignore
  liff: Liff = this.$liff;
  lineAccessToken: string = "";
  lineIdToken: string = "";

  get mailImg(): string {
    const backgroundColor1 = loyaltyConfig?.phone_icon?.background_color_1 || '#FFF1EF'
    const backgroundColor2 = loyaltyConfig?.phone_icon?.background_color_2 || '#FFE6E2'
    const phoneColor = loyaltyConfig?.phone_icon?.phone_color || '#FF5733'

    const phoneDynamicImg = `<svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33.5" cy="33.5" r="33.5" fill="${backgroundColor1}"/>
      <circle cx="34" cy="33" r="25" fill="${backgroundColor2}"/>
      <path d="M25 41C24.45 41 23.9792 40.8042 23.5875 40.4125C23.1958 40.0208 23 39.55 23 39V27C23 26.45 23.1958 25.9792 23.5875 25.5875C23.9792 25.1958 24.45 25 25 25H41C41.55 25 42.0208 25.1958 42.4125 25.5875C42.8042 25.9792 43 26.45 43 27V39C43 39.55 42.8042 40.0208 42.4125 40.4125C42.0208 40.8042 41.55 41 41 41H25ZM33 34L25 29V39H41V29L33 34ZM33 32L41 27H25L33 32ZM25 29V27V39V29Z" fill="${phoneColor}"/>
      </svg>
    `

    return 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(phoneDynamicImg)
  }

  mounted() {
    this.lineAccessToken = this.liff.getAccessToken() + "";
    this.lineIdToken = this.liff.getIDToken() + "";
    this.sendOTP()
  }

  beforeDestroy() {
    this.OTPMin = '05'
    this.OTPSec = '00'
  }

  async sendOTP() {
    const response = await CustomerAPIConnector.sendOTP(this.userProfile._id, this.lineAccessToken, this.lineIdToken)
    if(response?.isError) {
      this.snackbar = true
      this.snackbarText = `ไม่สามารถส่ง OTP ได้ โปรดลองอีกครั้ง`
      setTimeout(() => {
        this.snackbar = false
      }, 3000)
    }else if(response.ref_code){
      const duration = moment.duration(moment().diff(response.otp_timestamp));
      const mins = duration.asMinutes();
      const secs = duration.asSeconds();
      if(mins < 1 && secs >= 1 && !response.sendSMS) {
        this.OTPMin =  '0' + (+this.OTPMin - 1)
        this.resendMin = '00'
        const newSecs = (60 - Math.round(secs))
        this.OTPSec = newSecs < 10 ? '0' + newSecs : newSecs + ''
        this.resendSec = newSecs < 10 ? '0' + newSecs : newSecs + ''
      }
      this.refCode = response.ref_code
      this.disableResend = true
    }
  }

  async verifyOTP() {
    if(this.otp.length === 4){
      this.loading = true
      const response = await CustomerAPIConnector.verifyOTP(this.userProfile._id, this.otp, this.refCode, this.lineAccessToken, this.lineIdToken)
      this.loading = false
      if (response.verify === "SUCCESS") {
        if(this.$store.state.customer.isOldUser) {
          const oldUserData = await CustomerAPIConnector.getOldUserData(this.userProfile._id)
          this.$store.commit('customer/setUserProfile', oldUserData)
        }
        this.goTORedirectAfterVerified(this.$store.state.customer.redirectAfterVerified);
      } else if (response.verify === "TIMEOUT") {
        this.handleOTPTimeOut()
      } else {
        this.snackbar = true
        this.snackbarText = `หมายเลข OTP ไม่ถูกต้อง`
        setTimeout(() => {
          this.snackbar = false
        }, 3000)
      }
    }
  }
  
  async goTORedirectAfterVerified (redirect: string) {
    switch (redirect) {
      case 'Customer_History':
        this.$router.push({ name: redirect, params: { brandId: this.selectedBrandId } });
        break;
      case 'Customer_Profile':
        this.$router.push({ name: redirect, params: { brandId: this.selectedBrandId } });
        break;
    
      default:
        this.$router.replace({ name: redirect });
        break;
    }
  }

  handleOTPTimeOut() {
    this.snackbar = true
    this.snackbarText = `OTP หมดอายุกรุณาขอ OTP อีกครั้ง`
    this.refCode = "-"
    setTimeout(() => {
      this.snackbar = false
    }, 3000)
  }

  handleResend() {
    if(!this.disableResend){
      this.OTPMin = '05'
      this.OTPSec = '00'
      this.refCode = "-"
      this.sendOTP()
    }
  }

  handleResendTimeOut() {
    this.disableResend = false
  }
}
