





























































import BranchAPIConnector from "@/service/BranchAPIConnector";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import loyaltyConfig from '../../../../loyalty_config.json'

@Component({
  components: {},
})
export default class InsertPointModal extends Vue {
  @Prop({ default: false })
  private isShow!: boolean;
  @Prop({ default: 0 })
  private userPoint!: number;
  @Prop({ default: '' })
  private userPhone!: string;
  @Prop({ default: '' })
  private userId!: string;
  @Prop({ default: true })
  private isUpdatedPurchaseValue!: boolean;

  point: string = ''
  valid: boolean = false;
  errorMessages: string = ""
  tabs: number = 0;

  rules:Array<Function> = [
    (value: number) => !!value || '* กรุณากรอกตัวเลข',
    (value: string) => !(+value <= 0) || '* มากกว่า 0'
  ]

  @Watch("tabs")
  watchTabs() {
    if (this.tabs === 1) {
      this.rules = [
        (value: number) => !!value || '* กรุณากรอกตัวเลข',
        (value: string) => !(+value <= 0) || '* มากกว่า 0',
        (value: string) => !(this.tabs === 1 && +this.userPoint < +value) || '* ห้ามลบแต้มมากกว่าแต้มที่มีอยู่'
      ]
    } else {
      this.rules = [
        (value: number) => !!value || '* กรุณากรอกตัวเลข',
        (value: string) => !(+value <= 0) || '* มากกว่า 0'
      ]
    }
  }

  async saveEditPoint () {
    //@ts-ignore
    if(this.$refs.form.validate()){
      const exchangeRate = await this.$store.getters["branch/exchangePurchasePerPoint"]
      let calPurchase = Math.floor(+this.point * exchangeRate)
      if (this.tabs === 1) {
        calPurchase = Math.abs(+this.point) * -1
      }

      let receiveCode = this.userPhone
      if (loyaltyConfig?.brand_id) {
        receiveCode = this.userId
      }

      // sending API to add point
      const givenResult = await BranchAPIConnector.giveCode(
        receiveCode, calPurchase, this.isUpdatedPurchaseValue
      );
      

      if (givenResult) {
        const successWording = this.tabs === 1 ? "ลบแต้มลูกค้าเรียบร้อยแล้ว" : "ให้แต้มลูกค้าเรียบร้อยแล้ว"
        this.$store.dispatch("alert/success", successWording);
      } else {
        this.$store.dispatch(
          "alert/error",
          "ไม่สามารถดำเนินการได้ กรุณาลองใหม่อีกครั้ง"
        );
      }

      this.toggleModal(false)
    }
  }

  toggleModal(value: boolean) {
    this.tabs = 0
    this.point = ''
    this.$emit("toggleModal", value)
  }

  handleChange () {
    if (this.errorMessages != "") {
      this.errorMessages = ""
    }
  }

}
