





































import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters } from 'vuex'
import { BrandProfile } from "@/common/type";
import { BranchProfile } from "@/common/type";
import QrcodeVue from "qrcode.vue";
//@ts-ignore
import VueBarcode from "vue-barcode";

@Component({
  components: { QrcodeVue, barcode: VueBarcode, },
  computed: {
    ...mapGetters("brand", ["brandProfile"]),
    ...mapGetters("branch", ["branchBrandId"])
  },
})
export default class BranchQR extends Vue {
  brandProfile!: BrandProfile;
  branchBrandId!: string;
  svgElement!: any
  imageURL: string = ''
  isShow: boolean = false

  get qrCode () {
    let brandId = this.brandProfile._id !== "" ? this.brandProfile._id : this.branchBrandId
    return process.env.VUE_APP_LINELIFF_URL + '/customer/shop-list?shopId=' + brandId;
  }

  @Watch("isShow")
  watchIsShow() {
    if (this.isShow) {
      setTimeout(() => {
        this.isShow = false
      }, 800)
    }
  }

  async copyText(){
    this.isShow = true
    await this.$copyText(this.qrCode, this.$refs.copyContainer)
  }

  mounted() {
    var canvas = document.getElementsByTagName("canvas");
    //@ts-ignore
    var image = canvas[0].toDataURL();
    var objectUrl = URL.createObjectURL(this.dataURItoURL(image))
    this.imageURL = objectUrl

    //Save Image
    // var aDownloadLink = document.createElement('a');
    // aDownloadLink.download = this.brandProfile.brand_name_loyalty + '.png';
    // aDownloadLink.href = image;
    // aDownloadLink.click();
  }

  dataURItoURL(dataURI: string) {
    var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
       array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: mime});
  }
}
