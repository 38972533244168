






































import { Component, Vue } from "vue-property-decorator";
import CustomerAPIConnector from "@/service/CustomerAPIConnector";

@Component({
})
export default class CustomerCheckExternal extends Vue {
  phoneImg: string = require(`@/assets/img/phone.png`);
  valid: boolean = true
  telNo: string = ""
  errorMessages: string = ""
  //@ts-ignore
  rules:Array<Function> = [
    (value: string) => !!value || '* กรุณากรอกเลขสมาชิก',
  ]

  async confirmTelNo() {
    //@ts-ignore
    if(this.$refs.form.validate()){
      // const accessToken = this.liff.getAccessToken() + "";
      // const idToken = this.liff.getIDToken() + "";
      const response = await CustomerAPIConnector.checkExternalId(this.telNo, this.$route.params.brandId)
      // this.$store.commit('customer/SET_USER_PROFILE_PHONE', this.telNo)
      if(response){
        this.$router.push({ name: "Customer_Home", params: { brandId: this.$route.params.brandId.trim() }});
      } else {
        this.valid = false
        this.errorMessages = "เลขสมาชิกไม่ถูกต้อง"
      }
    }
  }

  handleChange () {
    if (this.errorMessages != "") {
      this.errorMessages = ""
    }
  }
}
