












import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {},  
})
export default class PrimaryButton extends Vue {
  @Prop({default: "-"})
  private displayText!: String;
  @Prop({default: "100%"})
  private width!: String;

  get style () {
    return 'width:' + this.width;
  }

  handleClick () {
    console.log("pass")
    this.$emit("handleClick")
  }
}
