import { BranchLoginResult, BranchProfile, BranchState } from "@/common/type";
import router from "@/router";
import BranchAPIConnector from "@/service/BranchAPIConnector";
import { ActionContext } from "vuex";

const state: BranchState = {
  accessToken: "",
  branchProfile: {
    _id: "",
    branchName: "",
    exchangePurchasePerPoint: 0,
  },
  brandData: {
    brandId: "",
    brandName: "",
    brandLogo: "",
  },
  isError: false,
  errorText: "",
  allActivatedQr: 0,
  qrSet: "",
  givePointTo: ""
};
const actions = {
  login: async (
    { dispatch, commit }: ActionContext<BranchState, {}>,
    loginResult: BranchLoginResult
  ) => {
    if (loginResult) {
      console.log("Branch Login");
      commit("updateLoginData", loginResult);
      dispatch("fetchBranchProfile");
      router.push({ name: "Branch_Menu" });
    }
  },
  logout: async (
    { dispatch, commit }: ActionContext<BranchState, {}>,
    isRedirect = true
  ) => {
    commit("logout", isRedirect);
    console.log("Branch Logout");
  },
  fetchBranchProfile: async (
    { dispatch, commit, state }: ActionContext<BranchState, {}>,
    onDone: (success: boolean) => void
  ) => {
    console.log(`Fetching Branch profile`);
    const branchProfile = await BranchAPIConnector.fetchBranchProfile();
    if (!branchProfile) {
      console.log("Credential is missing");
      dispatch("alert/warning", "Session หมดอายุแล้ว กรุณา login ใหม่อีกครั้ง", { root: true });
      onDone?.(false);
      router.push({
        name: "Login",
      });
    } else {
      commit("setBranchProfile", branchProfile);
      onDone?.(true);
    }
  },
  CheckQrActivate: async ({ commit }: any, qrCode: string) => {
    try {
      const qrSetData = await BranchAPIConnector.getQr(qrCode);
      const qrCheck = await BranchAPIConnector.checkQrActivate(qrSetData[0].qr_code_set)
      if (qrCheck.errors) {
        commit("SET_IS_ERROR", true);
        commit("SET_QR_SET", "");
        commit("SET_ERROR_TEXT", qrCheck.message);
      } else {
        commit("SET_IS_ERROR", false);
        commit("SET_QR_SET", qrSetData[0].qr_code_set)
        commit("SET_ERROR_TEXT", "");
      }
    } catch {
      commit("SET_IS_ERROR", true);
      commit("SET_QR_SET", "");
      commit("SET_ERROR_TEXT", "ไม่พบ QR code นี้ในระบบ");
    }
  },
  ActivateBrandQr: async ({ commit }: any, activationData: any) => {
    const data = {
      qr_code_set: activationData.qrData,
      point: activationData.point,
    }
    const setPointResult = await BranchAPIConnector.activateQR(data);
    if (setPointResult.errors) {
      commit("SET_IS_ERROR", true);
      commit("SET_ERROR_TEXT", setPointResult.message);
    } else {
      commit("SET_ALL_ACTIVE_QR", process.env.VUE_APP_QR_PER_SET);      
      commit("SET_IS_ERROR", false);
      commit("SET_ERROR_TEXT", "");
    }
  },
  GetCustomerNameFromReceiveCode: async ({ commit }: any, receiveCode: string) => {
    const customerResult = await BranchAPIConnector.searchCustomerWithReceiveCode(receiveCode);
    if (customerResult === undefined) {
      commit("SET_IS_ERROR", true);
      commit("SET_GIVE_POINT_TO", "");  
    } else if (customerResult.length === 0) {
      commit("SET_IS_ERROR", false);
      commit("SET_GIVE_POINT_TO", "");
    } else {
      const { firstname, lastname } = customerResult[0];
      commit("SET_IS_ERROR", false);
      commit("SET_GIVE_POINT_TO", `${firstname} ${lastname}`);
    }
  },
  GetCustomerNameFromPhoneNo: async ({ commit }: any, phoneNo: string) => {
    const customerResult = await BranchAPIConnector.searchCustomerWithPhoneNo(phoneNo);
    if (customerResult === undefined) {
      commit("SET_IS_ERROR", true);
      commit("SET_GIVE_POINT_TO", "");
    } else if (customerResult.length === 0) {
      commit("SET_IS_ERROR", false);
      commit("SET_GIVE_POINT_TO", "");
    } else {
      const { firstname, lastname } = customerResult[0];
      commit("SET_IS_ERROR", false);
      commit("SET_GIVE_POINT_TO", `${firstname} ${lastname}`);
    }
  }
};
const mutations = {
  updateLoginData: (state: BranchState, data: BranchLoginResult) => {
    state.accessToken = data.accessToken;
    localStorage.setItem("user", JSON.stringify(data));
    localStorage.setItem("mode", "USER");
  },
  logout: (state: BranchState, isRedirect: boolean) => {
    state.accessToken = "";
    state.branchProfile = {
      _id: "",
      branchName: "",
      exchangePurchasePerPoint: 0,
    };
    state.brandData = {
      brandId: "",
      brandName: "",
      brandLogo: "",
    };
    localStorage.removeItem("user");
    localStorage.removeItem("mode");
    isRedirect && router.push({ name: "Login" });
  },
  setBranchProfile: (state: BranchState, branchProfile: BranchProfile) => {
    state.branchProfile = {
      _id: branchProfile._id,
      branchName: branchProfile.branch_name,
      exchangePurchasePerPoint:
        branchProfile.default_exchange_purchase_per_point,
    };
    state.brandData = {
      brandId: branchProfile.brand_id,
      brandName: branchProfile.brand_name,
      brandLogo: branchProfile.brand_logo,
    };
  },
  SET_IS_ERROR: (state: any, payload: boolean) => {
    state.isError = payload
  },
  SET_ERROR_TEXT: (state: any, payload: string) => {
    state.errorText = payload
  },
  SET_ALL_ACTIVE_QR: (state: any, payload: number) => {
    state.allActivatedQr = payload
  },
  SET_QR_SET: (state: any, payload: string) => {
    state.qrSet = payload
  },
  SET_GIVE_POINT_TO: (state: any, payload: string) => {
    state.givePointTo = payload
  },
};
const getters = {
  branchProfile: (state: BranchState) => state.branchProfile,
  branchName: (state: BranchState) => state.branchProfile.branchName,
  exchangePurchasePerPoint: (state: BranchState) =>
    state.branchProfile.exchangePurchasePerPoint,
  branchBrandId: (state: BranchState) => state.brandData.brandId,
  branchBrandName: (state: BranchState) => state.brandData.brandName,
  branchBrandLogo: (state: BranchState) => state.brandData.brandLogo,
  isError: (state: BranchState) => state.isError,
  errorText: (state: BranchState) => state.errorText,
  allActivatedQr: (state: BranchState) => state.allActivatedQr,
  qrSet: (state: BranchState) => state.qrSet,
  givePointTo: (state: BranchState) => state.givePointTo,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
