
























































import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import DateRangePicker from "@/components/DateRangePicker.vue";
import HistoryList from "@/components/HistoryList.vue";
import BrandAPIConnector from "@/service/BrandAPIConnector";
import moment, { Moment } from "moment";

declare type DateRange = {
  start: Moment;
  end: Moment;
};

@Component({
  components: { DateRangePicker, HistoryList },
  computed: {
    ...mapGetters("brand", ["brandProfile"]),
  },
})
export default class ShopOwnerHistory extends Vue {
  range: DateRange = {
    start: moment().set({ hour: 0, minute: 0 }),
    end: moment(),
  };
  loading = false;
  data: any = {
    cashEarnTotal: 0,
    pointGiven: 0,
    pointRedeemTotalWithCoupon: 0,
    couponRedeemWorth: 0,
  };
  branchSelect: any = { branch_name: "ทั้งหมด", _id: -1 };
  branchList: any[] = [];
  branchListLoading: boolean = true;

  mounted() {
    this.loadingBranch();
  }

  async loadingBranch() {
    this.branchList = [{ branch_name: "ทั้งหมด", _id: -1 }];
    this.branchList.push(...((await BrandAPIConnector.getBranchList()) ?? []));
    console.log(this.branchList);

    this.branchListLoading = false;
    this.loadData();
  }

  selectDate(range: DateRange) {
    this.range = range;
    this.loadData();
  }

  async loadData() {
    this.loading = true;
    if (this.branchSelect._id == -1) {
      const dataResult = await BrandAPIConnector.getBrandSummary(
        this.range.start,
        this.range.end
      );
      if (dataResult) {
        const { summary } = dataResult;
        this.data = {
          cashEarnTotal: summary.purchase_value,
          pointGiven: summary.point,
          pointRedeemTotalWithCoupon: summary.exchange_point,
          couponRedeemWorth: summary.coupon_worth_value,
        };
      } else {
        this.$store.dispatch("alert/error", "ไม่สามารถดึงข้อมูลได้");
      }
    } else {
      const dataResult = await BrandAPIConnector.getBranchSummary(
        this.branchSelect._id,
        this.range.start,
        this.range.end
      );
      if (dataResult) {
        const { summary } = dataResult;
        this.data = {
          cashEarnTotal: summary.purchase_value,
          pointGiven: summary.point,
          pointRedeemTotalWithCoupon: summary.exchange_point,
          couponRedeemWorth: summary.coupon_worth_value,
        };
      }
    }
    this.loading = false;
  }
}
