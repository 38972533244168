








































import { Component, Vue } from "vue-property-decorator";
import DateRangePicker from "@/components/DateRangePicker.vue";
import HistoryList from "@/components/HistoryList.vue";
import BranchAPIConnector from "@/service/BranchAPIConnector";
import moment, { Moment } from "moment";

declare type DateRange = {
  start: Moment;
  end: Moment;
};

@Component({
  components: { DateRangePicker, HistoryList },
})
export default class BranchHistory extends Vue {
  mode: "point" | "redeem" = "point";
  range: DateRange = {
    start: moment().set({ hour: 0, minute: 0 }),
    end: moment(),
  };
  loading = false;
  data: any = {
    mode: this.mode,
    data: [],
  };

  mounted() {
    this.selectDate(this.range);
  }

  changeMode(mode: "point" | "redeem") {
    this.mode = mode;
    this.loadData();
  }

  selectDate(range: DateRange) {
    console.log(range.start.format());
    console.log(range.end.format());
    this.range = range;
    this.loadData();
  }

  async loadData() {
    this.loading = true;
    if (this.mode == "point") {
      this.data = {
        mode: this.mode,
        data: await BranchAPIConnector.getPointHistory(
          this.range.start,
          this.range.end
        ),
      };
      this.loading = false;
    } else {
      this.data = {
        mode: this.mode,
        data: await BranchAPIConnector.getCouponHistory(
          this.range.start,
          this.range.end
        ),
      };
      this.loading = false;
    }
  }
}
