













































import AgencyAPIConnector from "@/service/AgencyAPIConnector";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ShopList extends Vue {
  loading = false;
  keyword = "";
  userList: any = [];
  userAvatarUrl: string = require(`@/assets/img/avatar.png`);
  defaultUserLogo: string = require(`@/assets/img/sable-gray.svg`);

  mounted() {
    this.loadingData();
  }

  async loadingData() {
    this.loading = true;
    const result = await AgencyAPIConnector.getBrandList(this.keyword);
    if (result) {
      console.log(result);
      this.userList = result;
    } else {
      this.$store.dispatch("alert/error", "ไม่สามารถดึงข้อมูลลูกค้าได้");
    }
    this.loading = false;
  }

  showUserProfile(userId: string) {
    this.$router.push({
      name: "ShopOwner_CustomerProfile",
      params: { id: userId },
    });
  }
}
