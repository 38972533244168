


















import { Component, Vue, Watch } from "vue-property-decorator";
import AppBar from "@/components/AppBar.vue";
import { Route } from "vue-router";
import { mapGetters } from "vuex";

@Component({
  components: {
    AppBar,
  },
  computed: {},
})
export default class ShopOwner extends Vue {
  shopLogoUrl: string = require(`@/assets/img/sable_logo.svg`);
  sableDefault: string = require(`@/assets/img/sable_default.svg`);
  appBarMode: string = "home";
  backRouterMapping: any = {
    Agency_ActiveQR: "Agency_Menu",
    Agency_ConfirmQR: "Agency_Menu",
    Agency_ShopList: "Agency_Menu",
  };

  mounted() {
    this.updateAppBar(this.$router.currentRoute.name + "");
  }

  @Watch("$route")
  onChildChanged(to: Route, from: Route) {
    this.updateAppBar(to.name + "");
  }

  updateAppBar(routeName: string) {
    this.appBarMode = routeName == "Agency_Menu" ? "home" : "backward";
    console.log(routeName);
    console.log(this.appBarMode);
  }

  back() {
    const backRouteName = this.backRouterMapping[this.$route.name + ""];
    if (backRouteName) {
      this.$router.push({ name: backRouteName });
    } else {
      this.$router.go(-1);
    }
  }

  logout() {
    this.$store.dispatch("agency/logout");
  }
}
