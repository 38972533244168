











































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ContactModal extends Vue {
  @Prop({ default: '' })
  private headerText!: string;
  @Prop({ default: false })
  private isShow!: boolean;

  private isShowToolTip: boolean = false;

  @Watch("isShowToolTip")
  watchIsShowToolTip() {
    if (this.isShowToolTip) {
      setTimeout(() => {
        this.isShowToolTip = false
      }, 800)
    }
  }

  goToLineSupport () {
    window.open(
      'https://lin.ee/rhknVEX',
      '_blank'
    )
  }

  toggleModal(value: boolean) {
    this.$emit("toggleModal", value);
  }

  async copyText(){
    await this.$copyText("@sable_support", this.$refs.copyContainer)
  }
}
