import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

// Customer
import Customer from "@/views/Customer.vue";
import CustomerHome from "@/views/customer/CustomerHome.vue";
import CustomerReward from "@/views/customer/CustomerReward.vue";
import CouponDetail from "@/views/customer/CouponDetail.vue";
import RedeemHistory from "@/views/customer/RedeemHistory.vue";
import CustomerProfile from "@/views/customer/CustomerProfile.vue";
import CustomerRegister from "@/views/customer/CustomerRegister.vue";
import CustomerCheckPhone from "@/views/customer/CustomerCheckPhone.vue";
import CustomerCheckExternal from "@/views/customer/CustomerCheckExternal.vue";
import CustomerOTP from "@/views/customer/CustomerOTP.vue";

// Branch
import Branch from "@/views/Branch.vue";
import BranchMenu from "@/views/branch/BranchMenu.vue";
import BranchGivePoint from "@/views/branch/BranchGivePoint.vue";
import BranchRedeemCoupon from "@/views/branch/BranchRedeemCoupon.vue";
import BranchCouponDetail from "@/views/branch/BranchCouponDetail.vue";
import BranchHistory from "@/views/branch/BranchHistory.vue";
import BranchQR from "@/views/branch/BranchQR.vue";
import BranchActiveQR from "@/views/branch/BranchActiveQR.vue";
import BranchConfirmQR from "@/views/branch/BranchConfirmQR.vue";
import BranchStaff from "@/views/branch/BranchStaff.vue";
import BranchCustomerList from "@/views/branch/CustomerList.vue";
import BranchCustomerProfile from "@/views/branch/BranchCustomerProfile.vue";

// Shop Owner
import ShopOwner from "@/views/ShopOwner.vue";
import ShopOwnerMenu from "@/views/shopowner/ShopOwnerMenu.vue";
import ShopOwnerHistory from "@/views/shopowner/ShopOwnerHistory.vue";
import ShopOwnerSummary from "@/views/shopowner/ShopOwnerSummary.vue";
import ShopOwnerCouponManage from "@/views/shopowner/ShopOwnerCouponManage.vue";
import CouponEditor from "@/views/shopowner/CouponEditor.vue";
import CustomerList from "@/views/shopowner/CustomerList.vue";
import ShopOwnerCustomerProfile from "@/views/shopowner/ShopOwnerCustomerProfile.vue";
import BranchesSummary from "@/views/shopowner/BranchesSummary.vue";
import BranchSummary from "@/views/shopowner/BranchSummary.vue";
import BranchEditor from "@/views/shopowner/BranchEditor.vue";
import ShopOwnerProfile from "@/views/shopowner/ShopOwnerProfile.vue";
import ShopOwnerStaff from "@/views/shopowner/ShopOwnerStaff.vue";
import ShopEditorMenu from "@/views/shopowner/ShopEditorMenu.vue";
import ShopAllStaff from "@/views/shopowner/ShopAllStaff.vue";
import ShopEditStaff from "@/views/shopowner/ShopEditStaff.vue";
import BrandPointEditor from "@/views/shopowner/BrandPointEditor.vue";
import ShopAllBranch from "@/views/shopowner/ShopAllBranch.vue";
import ShopEditBranch from "@/views/shopowner/ShopEditBranch.vue";

// Agency
import Agency from "@/views/Agency.vue";
import AgencyMenu from "@/views/agency/AgencyMenu.vue";
import AgencyShopList from "@/views/agency/ShopList.vue";
import ActiveQR from "@/views/agency/ActiveQR.vue";
import ConfirmQR from "@/views/agency/ConfirmQR.vue";

//Login
import Login from "@/views/login/Login.vue";
import Register from "@/views/login/Register/Register.vue";
import PromoCode from "@/views/login/Register/PromoCode.vue";
import ForgetPassword from "@/views/login/ForgetPassword.vue";
import RegisterWithAgency from "@/views/login/RegisterWithAgency.vue";

//
import Index from "@/views/index.vue";
import ShopList from "@/views/ShopList.vue";
import RedeemReward from "@/views/RedeemReward.vue";
import Sable from "@/views/Sable.vue";
import GetPointFromQR from "@/views/GetPointFromQR.vue";
import LineLogin from "@/views/LineLogin.vue";
import Logout from "@/views/Logout.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/customer",
    name: "Customer",
    component: Customer,
    redirect: "/customer/shop-list",
    children: [
      { path: ":brandId/home", name: "Customer_Home", component: CustomerHome },
      {
        path: ":brandId/reward",
        name: "Customer_Reward",
        component: CustomerReward,
      },
      {
        path: ":brandId/coupon/:id",
        name: "Customer_Coupon",
        component: CouponDetail,
      },
      {
        path: ":brandId/history",
        name: "Customer_History",
        component: RedeemHistory,
      },
      {
        path: ":brandId/profile",
        name: "Customer_Profile",
        component: CustomerProfile,
      },
      {
        path: "logout",
        name: "logout",
        component: Logout,
      },
    ],
  },
  {
    path: "/branch",
    name: "Branch",
    component: Branch,
    redirect: "/branch/menu",
    children: [
      { path: "menu", name: "Branch_Menu", component: BranchMenu },
      {
        path: "give-point",
        name: "Branch_GivePoint",
        component: BranchGivePoint,
      },
      {
        path: "redeem-coupon",
        name: "Branch_RedeemCoupon",
        component: BranchRedeemCoupon,
      },
      {
        path: "redeem-coupon/:couponCode",
        name: "Branch_CouponDetail",
        component: BranchCouponDetail,
      },
      {
        path: "history",
        name: "Branch_History",
        component: BranchHistory,
      },
      {
        path: "qr",
        name: "Branch_QR",
        component: BranchQR,
      },
      {
        path: "active-qr",
        name: "Branch_ActiveQR",
        component: BranchActiveQR,
      },
      {
        path: "confirm-qr",
        name: "Branch_ConfirmQR",
        component: BranchConfirmQR,
      },
      {
        path: "branch-staff",
        name: "Branch_Staff",
        component: BranchStaff,
      },
      {
        path: "branch-customers",
        name: "Branch_CustomerList",
        component: BranchCustomerList,
      },
      {
        path: "branch-customers/:id",
        name: "Branch_CustomerProfile",
        component: BranchCustomerProfile,
      },
    ],
  },
  {
    path: "/shop-owner",
    name: "ShopOwner",
    component: ShopOwner,
    redirect: "/shop-owner/menu",
    children: [
      { path: "menu", name: "ShopOwner_Menu", component: ShopOwnerMenu },
      {
        path: "summary",
        name: "ShopOwner_Summary",
        component: ShopOwnerSummary,
      },
      {
        path: "coupons",
        name: "ShopOwner_CouponManage",
        component: ShopOwnerCouponManage,
      },
      {
        path: "coupon/new",
        name: "ShopOwner_Coupon_New",
        component: CouponEditor,
      },
      {
        path: "coupon/edit/:id",
        name: "ShopOwner_Coupon_Edit",
        component: CouponEditor,
      },
      {
        path: "history",
        name: "ShopOwner_History",
        component: ShopOwnerHistory,
      },
      {
        path: "customers",
        name: "ShopOwner_CustomerList",
        component: CustomerList,
      },
      {
        path: "customer/:id",
        name: "ShopOwner_CustomerProfile",
        component: ShopOwnerCustomerProfile,
      },
      {
        path: "summary/branches",
        name: "ShopOwner_BranchesSummary",
        component: BranchesSummary,
      },
      {
        path: "summary/branch/:id",
        name: "ShopOwner_BranchSummary",
        component: BranchSummary,
      },
      {
        path: "summary/branch/:id/edit",
        name: "ShopOwner_BranchEdit",
        component: BranchEditor,
      },
      {
        path: "branch/new",
        name: "ShopOwner_BranchNew",
        component: BranchEditor,
      },
      {
        path: "profile",
        name: "ShopOwner_Profile",
        component: ShopOwnerProfile,
      },
      {
        path: "staff",
        name: "ShopOwner_Staff",
        component: ShopOwnerStaff,
      },
      {
        path: "shop-editor",
        name: "Shop_Editor_Menu",
        component: ShopEditorMenu,
      },
      {
        path: "shop-all-staff",
        name: "Shop_All_Staff",
        component: ShopAllStaff,
      },
      {
        path: "shop-edit-staff",
        name: "Shop_Edit_Staff",
        component: ShopEditStaff,
      },
      {
        path: "brand-point-editor",
        name: "Brand_Point_Editor",
        component: BrandPointEditor,
      },
      {
        path: "shop-all-branch",
        name: "Shop_All_Branch",
        component: ShopAllBranch,
      },
      {
        path: "shop-edit-branch",
        name: "Shop_Edit_Branch",
        component: ShopEditBranch,
      }
    ],
  },
  {
    path: "/agency",
    name: "Agency",
    component: Agency,
    redirect: "/agency/menu",
    children: [
      {
        path: "menu",
        name: "Agency_Menu",
        component: AgencyMenu
      },
      {
        path: "active-qr",
        name: "Agency_ActiveQR",
        component: ActiveQR
      },
      {
        path: "confirm-qr",
        name: "Agency_ConfirmQR",
        component: ConfirmQR
      },
      { 
        path: "shop-list",
        name: "Agency_ShopList",
        component: AgencyShopList
      },
    ],
  },
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {

    path: "/register/promo-code",
    name: "Register_PromoCode",
    component: PromoCode,
  },
  {
    path: "/register-agency/:agencyCode",
    name: "RegisterWithAgency",
    component: RegisterWithAgency,
  },
  {
    path: "/forget-password",
    name: "Forget_Password",
    component: ForgetPassword,
  },
  {
    path: "/customer/register",
    name: "Customer_Register",
    component: CustomerRegister,
  },
  {
    path: "/customer/check-phone",
    name: "Customer_Check_Phone",
    component: CustomerCheckPhone,
  },
  {
    path: "/customer/check-external-id",
    name: "Customer_Check_External",
    component: CustomerCheckExternal,
  },
  {
    path: "/customer/otp",
    name: "Customer_OTP",
    component: CustomerOTP,
  },
  {
    path: "/customer/shop-list",
    name: "Customer_Shoplist",
    component: ShopList,
  },
  {
    path: "/redeem/reward/:rewardCode",
    name: "Redeem_Reward",
    component: RedeemReward,
  },
  {
    path: "/sable",
    name: "Go_To_Sable",
    component: Sable,
  },
  {
    path: "/point/qr",
    name: "Get_Point_From_QR",
    component: GetPointFromQR,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
