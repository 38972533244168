

























































































































import { Component, Vue } from "vue-property-decorator";
import BrandAPIConnector from "@/service/BrandAPIConnector";

interface Branch {
  name: string;
  id: string;
  canDelete: boolean;
}

@Component({
  components: {},
})

export default class ShopAllBranch extends Vue {
  //@ts-ignore
  liff: Liff = this.$liff;

  allBranch: Array<Branch> = [];
  showData: Array<Branch> = [];

  currentBranch: number = 1;
  maxBranch: number = 1;

  selectedId: string = "";
  selectedName: string = "";

  confirmDialog: boolean = false;
  contactDialog: boolean = false;
  isInLine: boolean = false;
  brandName: string = '';

  async mounted () {
    await this.loadAllBranch();
    this.showData = this.allBranch;
    this.isInLine = this.liff.isInClient();
  }

  search(value: string) {
    if (value === "") {
      this.showData = this.allBranch;
    } else {
      this.showData = [];
      for (let i = 0; i < this.allBranch.length; i++) {
        if (this.allBranch[i].name.includes(value)) {
          this.showData.push(this.allBranch[i]);
        }
      }
    }
  }

  async loadAllBranch() {
    const brandUser = await BrandAPIConnector.getUserBrand();
    if (brandUser) {
      const brandId = brandUser._id;
      this.brandName = brandUser.brand_name_loyalty;
      const allBranch = await BrandAPIConnector.fetchAllBranch(brandId);
      for (let i = 0; i < allBranch.length; i++) {
        const branchData = {
          name: allBranch[i].branch_name,
          id: allBranch[i]._id,
          canDelete: true
        }
        this.allBranch.push(branchData)
      }
      if (allBranch.length === 1) {
        this.allBranch[0].canDelete = false;
      }
      let defaultMaxBranch = 1;
      switch (brandUser.loyalty_plan) {
        case 'STANDARD': 
          defaultMaxBranch = 1;
          break;
        case 'PREMIUM':
          defaultMaxBranch = 3;
      }
      this.currentBranch = allBranch.length;
      const planMaxBranch = brandUser.max_branch??0;
      this.maxBranch = planMaxBranch + defaultMaxBranch;
    }
  }

  goToSetting(pathName: string) {
    this.$router.push({ name: pathName });
  }

  goToEdit(id: string) {
    if (id) {
      const branchDetail = this.allBranch.find(x => x.id === id);
      if (branchDetail) {
        this.$router.push({ name: "Shop_Edit_Branch", 
          params: { 
            id: branchDetail.id,
            name: branchDetail.name,
          }});
      }
    } else {
      this.$router.push({ name: "Shop_Edit_Branch" });
    }
  }

  toggleDelete(userId: string, name: string) {
    this.selectedName = name;
    this.selectedId = userId;
    this.confirmDialog = true;
  }

  async deleteBranch() {
    const deleteResult = await BrandAPIConnector.deleteBranch(this.selectedId)
    if (deleteResult) {
      this.$store.dispatch("alert/success", "ลบสำเร็จ");
      this.$router.go(0);
    } else {
      this.$store.dispatch("alert/error", "ไม่สามารถลบได้");
    }
  }

  cancel() {
    this.selectedName = "";
    this.selectedId = "";
    this.confirmDialog = false;
  }

  async sendContact() {
    this.liff.sendMessages(
      [
        { 
          type: "text",
          text: `${this.brandName} ต้องการเพิ่มสาขา`, 
        },
      ]
    )
    .then(() => {
      this.$store.dispatch("alert/success", "ส่งข้อความสำเร็จ");
    })
    .catch((err: any) => {
      this.$store.dispatch("alert/error", "ส่งข้อความไม่สำเร็จ");
    });
    this.contactDialog = false;
  }
}
