












































































































import { Component, Vue } from "vue-property-decorator";
import ShowCode from "@/components/customer/ShowCode.vue";
import CustomerAPIConnector from "@/service/CustomerAPIConnector";
import { mapGetters } from 'vuex'
import moment from 'moment'
@Component({
  components: { ShowCode },
  computed: {
    ...mapGetters("customer", ["selectedBrandLogo", "customerPoint"])
  }
})
export default class CouponDetail extends Vue {
  // Coupon Detail
  shopLogoUrl: string = require(`@/assets/img/sable_logo.svg`);
  couponId: string = "";
  couponImage: string = "";
  exchangePoint: number = 0;
  expiredDate = new Date(); // disable this phase
  condition = "";

  // Data
  code: string = "";
  rewardName: string = "";
  dataLoaded: boolean = false;
  showCode: boolean = false;
  canExchange: boolean = false;
  confirmDialog: boolean = false;
  exchangeType: string = "";
  expiredUseIn: string = "";
  isNoExpireLimit: boolean = false;
  isNoExpireUseDate: boolean = true;
  isRedeemed: boolean = false;
  isRestrict: boolean = false;
  customRestrict: number = 1;
  maxAmount: number = 0;
  matchCondition: boolean = true;

  errorText: string = "";
  isError = false;
  isLoading: boolean = false;

  async mounted() {
    console.log(`Loading coupon id ${this.$route.params.id}`);

    this.initCouponDetail();
  }

  async initCouponDetail() {
    this.dataLoaded = false;
    this.isLoading = true;
    // Getting Coupon detail
    const coupon = await CustomerAPIConnector.getBrandCoupon(
      this.$route.params.id,
      this.$route.params.brandId
    );
    if (coupon) {
      this.rewardName = coupon.name;
      this.couponId = coupon._id;
      this.exchangePoint = coupon.exchange_point;
      this.couponImage = coupon.img;
      this.condition = coupon.condition;
      this.isNoExpireLimit = coupon.is_no_expire_limit;
      this.expiredDate = moment(coupon.expired_date).toDate();
      this.exchangeType = coupon.exchange_type;
      this.isNoExpireUseDate = coupon.is_no_expired_use_date;
      if (this.isNoExpireUseDate !== undefined && this.isNoExpireUseDate == false) {
        this.expiredUseIn = `${coupon.expired_use_in}`
        // let checkExpiredDate
        // add expired date
        switch (coupon.expired_use_in_unit.toLowerCase()) {
          case 'day':
            this.expiredUseIn += ' วัน'
            // checkExpiredDate = moment().add(+coupon.expired_use_in, 'days').toDate()
            break;
          case 'month':
            this.expiredUseIn += ' เดือน'
            // checkExpiredDate = moment().add(+coupon.expired_use_in, 'months').toDate()
            break;
          case 'year':
            this.expiredUseIn += ' ปี'
            // checkExpiredDate = moment().add(+coupon.expired_use_in, 'years').toDate()
            break;
        }
        // if (checkExpiredDate && checkExpiredDate > this.expiredDate) {
        //   this.isNoExpireUseDate = true
        // }
      }
      this.maxAmount = coupon.max_amount
      this.dataLoaded = true;
      this.canExchange =
        (this.$store.getters["customer/customerPoint"] >= this.exchangePoint &&
        (coupon.amount > 0 || coupon.amount === -1) );
      if (coupon.match_condition !== undefined) {
        this.matchCondition = coupon.match_condition
        if (!this.matchCondition) {
          this.canExchange = false
        }
      }
      this.isRestrict = coupon.is_restrict
      if (coupon.is_restrict && coupon.is_redeemed) {
        this.canExchange = false
        this.isRedeemed = coupon.is_redeemed
      }
      if (coupon.custom_restrict && coupon.custom_restrict > 1) {
        this.customRestrict = coupon.custom_restrict
      }
    } else {
      this.popErrorSnack("ไม่สามารถโหลดรีวอร์ดได้ กรุณาลองใหม่ในภายหลัง");
      this.$router.go(-1);
    }
    this.isLoading = false;
  }

  async exchangeCoupon() {
    this.confirmDialog = false
    // redeeming coupon
    const exchangeCouponResult = await CustomerAPIConnector.exchangeCoupon(
      this.couponId,
      this.$route.params.brandId
    );
    if (exchangeCouponResult?.result) {
      if(this.isRestrict) {
        this.initCouponDetail()
      }
      // redeem complete
      this.showCouponCode(exchangeCouponResult.data.coupon_code);
    } else {
      // redeem incomplete
      console.log("Redeem code failed.");
      console.log(exchangeCouponResult?.msg);
      this.popErrorSnack("ไม่สามารถแลกแต้มได้ กรุณาลองใหม่ในภายหลัง");
    }
  }

  numberWithCommas (x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  showCouponCode(code: string) {
    this.code = code;
    this.showCode = true;
  }

  popErrorSnack(errorText: string) {
    this.$store.dispatch("alert/error", errorText);
  }

  back() {
    this.$router.go(-1);
  }
}
