export enum Gender {
  male,
  female,
  other,
  prefer_not_to_say,
}

export const GenderLabels = {
  [Gender.male]: "ชาย",
  [Gender.female]: "หญิง",
  [Gender.other]: "อื่นๆ",
  [Gender.prefer_not_to_say]: "ไม่สะดวกระบุ",
};

export const GenderItems = [
  {
    text: GenderLabels[Gender.male],
    value: Gender.male,
  },
  {
    text: GenderLabels[Gender.female],
    value: Gender.female,
  },
  {
    text: GenderLabels[Gender.other],
    value: Gender.other,
  },
  // {
  //   text: GenderLabels[Gender.prefer_not_to_say],
  //   value: Gender.prefer_not_to_say,
  // },
];

export const stringToGender: { [key: string]: Gender } = {
  male: Gender.male,
  female: Gender.female,
  other: Gender.other,
  unknown: Gender.prefer_not_to_say,
};

export const genderToString: { [key: number]: string } = {
  [Gender.male]: "male",
  [Gender.female]: "female",
  [Gender.other]: "other",
  [Gender.prefer_not_to_say]: "unknown",
};
