import { ActionContext } from 'vuex';
import router from "@/router";
import agencyAPIConnector from "@/service/AgencyAPIConnector";
import { AgencyState } from "@/common/type";
import Cookies from 'js-cookie';

const state : AgencyState = {
  accessToken: "",
  role: "USER",
  agencyUserData: {},
  activeNumber: 0,
  amountQrSet: process.env.VUE_APP_QR_PER_SET,
  isError: false,
  errorText: "",
  qrSet: "",
};

const actions = {
  login({ commit }: ActionContext<AgencyState, {}>, userData: any) {
    commit("SET_AGENCY_USER_DATA", userData)
    const qrData = Cookies.get('rod')
    if (qrData) {
      Cookies.remove('rod')
      router.push({ name: "Agency_ActiveQR", query: { qr: qrData } });
    } else {
      router.push({ name: "Agency_Menu" });
    }
  },
  logout: async (
    { dispatch, commit }: any,
    isRedirect = true
  ) => {
    commit("CLEAR_STATE");
    commit("logout", isRedirect);
    console.log("Agency Logout");
  },
  async fetchAgencyProfile({ dispatch, commit }: ActionContext<AgencyState, {}>,
    onDone: (success: boolean) => void){
    const agencyProfile = await agencyAPIConnector.fetchAgencyProfile();
    if (!agencyProfile) {
      console.log("Credential is missing");
      dispatch("alert/warning", "Session หมดอายุแล้ว กรุณา login ใหม่อีกครั้ง");
      onDone?.(false);
      router.push({
        name: "Login",
      });
    } else {
      onDone?.(true);
      agencyProfile.isFetch = true
      commit("SET_AGENCY_USER_DATA", agencyProfile);
    }
  },
  async resetState({ commit }: ActionContext<AgencyState, {}>) {
    commit("CLEAR_STATE", 0);
  },
  async activatedFirstQr({ commit }: ActionContext<AgencyState, {}>, qrCode: string) {
    const qrData = await agencyAPIConnector.getQr(qrCode);
    if (qrData.length == 0) {
      await commit("SET_ERROR_TEXT", "QR CODE ไม่ถูกต้อง");
      await commit("SET_IS_ERROR", true);
      return
    }
    const data = {
      qr_code_set_start: qrData[0].qr_code_set
    }

    const setQr = await agencyAPIConnector.activateQR(data);
    if (setQr.code == 400) { // This QR code set activated
      await commit("SET_ERROR_TEXT", "QR CODE เซตนี้ถูกเปิดใช้งานแล้ว");
      await commit("SET_IS_ERROR", true);
      return setQr
    }
    commit("SET_QRSET", qrData[0].qr_code_set);
    commit("SET_ERROR_TEXT", "");
    commit("SET_IS_ERROR", false);
    commit("SET_ACTIVE_NUMBER", 1);
  },
  async activatedRangeQr({ state, commit }: ActionContext<AgencyState, {}>, qrCode: string) {
    const qrData = await agencyAPIConnector.getQr(qrCode);
    if (qrData.length == 0) {
      await commit("SET_ERROR_TEXT", "QR CODE ไม่ถูกต้อง");
      await commit("SET_IS_ERROR", true);
      return
    } else if (qrData[0].agency_id) {
      await commit("SET_ERROR_TEXT", "QR CODE เซตนี้ถูกเปิดใช้งานแล้ว");
      await commit("SET_IS_ERROR", true);
      return
    }
    let firstQr, secondQr;
    if (parseInt(state.qrSet) <= qrData[0].qr_code_set) {
      firstQr = state.qrSet;
      secondQr = qrData[0].qr_code_set;
    } else {
      firstQr = qrData[0].qr_code_set;
      secondQr = state.qrSet;
    }
    const data = {
      qr_code_set_start: firstQr,
      qr_code_set_end: secondQr
    }

    const setQr = await agencyAPIConnector.activateQR(data);
    if (setQr.code == 400) { // This QR code set activated
      await commit("SET_ERROR_TEXT", "QR CODE เซตนี้ถูกเปิดใช้งานแล้ว");
      await commit("SET_IS_ERROR", true);
      return setQr
    }
    commit("SET_QRSET", `${firstQr} - ${secondQr}`);
    commit("SET_ERROR_TEXT", "");
    commit("SET_IS_ERROR", false);
    commit("SET_ACTIVE_NUMBER", setQr.length);
  },
};

const mutations = {
  SET_AGENCY_USER_DATA(state: AgencyState, userData: any) {
    if (userData.isFetch) {
      state.accessToken = userData.accessToken;
      state.role = userData.role
      state.agencyUserData = userData
    } else {
      userData.user.accessToken = userData.accessToken
      state.accessToken = userData.accessToken;
      state.role = userData.user.role
      state.agencyUserData = userData.user
      localStorage.setItem("agency", JSON.stringify(userData.user));
      localStorage.setItem("mode", "AGENCY");
    }
  },
  SET_ACTIVE_NUMBER(state: AgencyState, payload: number) {
    state.activeNumber = payload * state.amountQrSet + state.activeNumber;
  },
  SET_ERROR_TEXT(state: AgencyState, payload: string) {
    state.errorText = payload;
  },
  SET_IS_ERROR(state: AgencyState, payload: boolean) {
    state.isError = payload;
  },
  SET_QRSET(state: AgencyState, payload: string) {
    state.qrSet = payload;
  },
  logout: (state: AgencyState, isRedirect: boolean) => {
    localStorage.removeItem("agency");
    localStorage.removeItem("mode");
    isRedirect && router.push({ name: "Login" });
  },
  CLEAR_STATE(state: AgencyState, payload: string) {
    state.activeNumber = 0;
    state.isError = false;
    state.errorText = "";
    state.qrSet = "";
  },
};

const getters = {
  agencyFullName: (state: any) => state.agencyUserData?.firstname + ' ' + state.agencyUserData?.lastname,
  activeNumber: (state: AgencyState) => state.activeNumber,
  isError: (state: AgencyState) => state.isError,
  errorText: (state: AgencyState) => state.errorText,
  qrSet: (state: AgencyState) => state.qrSet,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
