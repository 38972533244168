























































import { Component, Vue, Prop } from "vue-property-decorator";
import BrandAPIConnector from "@/service/BrandAPIConnector";
import Countdown from "@/components/Countdown.vue";
import { mapGetters, mapState } from "vuex";
import { CustomerProfile } from "@/common/type";
import moment from "moment";
  
@Component({
  components: {
    Countdown
  },
  computed: {
    ...mapGetters("customer", ["userProfile", "selectedBrandId"]),
  },
})
export default class VerifyOTP extends Vue {
  mailImg: string = require(`@/assets/img/mail.png`);
  loading: boolean = false
  otp: string = ""
  refCode: string = "-"
  rules:Array<Function> = [
    (value: string) => !!value || '* กรุณากรอกเบอร์โทรศัพท์',
    (value: string) => !!(new RegExp('^([0]){1}([689]){1}([0-9]){8}$')).test(value) || '* เบอร์โทรศัพท์ของคุณไม่ถูกต้อง'
  ]
  userProfile!:CustomerProfile;
  snackbar: boolean = false
  OTPMin: string = '05'
  OTPSec: string = '00'
  resendMin: string = '01'
  resendSec: string = '00'
  snackbarColor: string = 'error'
  snackbarText: string = 'หมดเวลากรุณาส่ง OTP อีกครั้ง'
  disableResend: boolean = false
  selectedBrandId!: string

  @Prop({default: ''})
  private phone!: string
  @Prop()
  private brandId!: string

  mounted() {
    this.sendOTP()
  }

  beforeDestroy() {
    this.OTPMin = '05'
    this.OTPSec = '00'
  }

  async sendOTP() {
    const response = await BrandAPIConnector.generateBrandOTP(this.brandId)
    if(response?.isError) {
      this.snackbar = true
      this.snackbarText = `ไม่สามารถส่ง OTP ได้ โปรดลองอีกครั้ง`
      setTimeout(() => {
        this.snackbar = false
      }, 3000)
    }else if(response.ref_code){
      const duration = moment.duration(moment().diff(response.otp_timestamp));
      const mins = duration.asMinutes();
      const secs = duration.asSeconds();
      if(mins < 1 && secs >= 1 && !response.sendSMS) {
        this.OTPMin =  '0' + (+this.OTPMin - 1)
        this.resendMin = '00'
        const newSecs = (60 - Math.round(secs))
        this.OTPSec = newSecs < 10 ? '0' + newSecs : newSecs + ''
        this.resendSec = newSecs < 10 ? '0' + newSecs : newSecs + ''
      }
      this.refCode = response.ref_code
      this.disableResend = true
    }
  }

  async verifyOTP() {
    if(this.otp.length === 4){
      this.loading = true
      const response = await BrandAPIConnector.verifyOTP(this.brandId, this.otp, this.refCode)
      this.loading = false
      if (response.verify === "SUCCESS") {
        this.$emit('callbackToParent', response.token);
      } else if (response.verify === "TIMEOUT") {
        this.handleOTPTimeOut()
      } else {
        this.snackbar = true
        this.snackbarText = `หมายเลข OTP ไม่ถูกต้อง`
        setTimeout(() => {
          this.snackbar = false
        }, 3000)
      }
    }
  }
  
  async goTORedirectAfterVerified (redirect: string) {
    switch (redirect) {
      case 'Customer_History':
        this.$router.push({ name: redirect, params: { brandId: this.selectedBrandId } });
        break;
      case 'Customer_Profile':
        this.$router.push({ name: redirect, params: { brandId: this.selectedBrandId } });
        break;
    
      default:
        this.$router.replace({ name: redirect });
        break;
    }
  }

  handleOTPTimeOut() {
    this.snackbar = true
    this.snackbarText = `OTP หมดอายุกรุณาขอ OTP อีกครั้ง`
    this.refCode = "-"
    setTimeout(() => {
      this.snackbar = false
    }, 3000)
  }

  handleResend() {
    if(!this.disableResend){
      this.OTPMin = '05'
      this.OTPSec = '00'
      this.refCode = "-"
      this.sendOTP()
    }
  }

  handleResendTimeOut() {
    this.disableResend = false
  }
}
