










































































































import { Component, Vue } from "vue-property-decorator";
import BrandAPIConnector from "@/service/BrandAPIConnector";

interface Staff {
  username: string;
  name: string;
  role: string;
  branch: string;
  id: string;
  firstname: string;
  lastname: string;
  canDelete: boolean;
}

@Component({
  components: {},
})

export default class ShopAllStaff extends Vue {
  allStaff: Array<Staff> = [];
  showData: Array<Staff> = [];

  currentStaff: number = 1;
  maxStaff: number = 1;

  selectedId: string = "";
  selectedName: string = "";

  confirmDialog: boolean = false;

  async mounted () {
    await this.loadAllStaff();
    this.showData = this.allStaff;
  }

  search(value: string) {
    if (value === "") {
      this.showData = this.allStaff;
    } else {
      this.showData = [];
      for (let i = 0; i < this.allStaff.length; i++) {
        if (this.allStaff[i].name.includes(value)) {
          this.showData.push(this.allStaff[i]);
        }
      }
    }
  }

  async loadAllStaff() {
    const brandUser = await BrandAPIConnector.getUserBrand();
    if (brandUser) {
      const brandId = brandUser._id;
      const allStaff = await BrandAPIConnector.fetchAllStaff(brandId);
      let adminNo = 0;
      let adminIndex = -1;
      for (let i = 0; i < allStaff.length; i++) {
        const staffData = {
          username: allStaff[i].username,
          name: `${allStaff[i].firstname} ${allStaff[i].lastname}`,
          role: allStaff[i].role,
          branch: allStaff[i].branch_name,
          id: allStaff[i]._id,
          firstname: allStaff[i].firstname,
          lastname: allStaff[i].lastname,
          canDelete: true
        };
        this.allStaff.push(staffData);
        
        if (allStaff[i].role === "admin") {
          adminNo++;
          adminIndex = i;
        }
      }

      if (this.allStaff.length === 1) {
        this.allStaff[0].canDelete = false;
      } else if (adminNo === 1) {
        this.allStaff[adminIndex].canDelete = false;
      }

      let defaultMaxStaff = 1;
      switch (brandUser.loyalty_plan) {
        case 'STANDARD': 
          defaultMaxStaff = 5;
          break;
        case 'PREMIUM':
          defaultMaxStaff = 20;
      }
      this.currentStaff = allStaff.length;
      const planMaxStaff = brandUser.max_staff??0;
      this.maxStaff = planMaxStaff + defaultMaxStaff;
    }
  }

  goToSetting(pathName: string) {
    this.$router.push({ name: pathName });
  }

  goToEdit(id: string) {
    if (id) {
      const staffDetail = this.allStaff.find(x => x.id === id);
      if (staffDetail) {
        this.$router.push({ name: "Shop_Edit_Staff", 
          params: { 
            username: staffDetail.username,
            id: staffDetail.id,
            firstname: staffDetail.firstname,
            lastname: staffDetail.lastname,
            role: staffDetail.role,
            branch: staffDetail.branch
          }});
      }
    } else if (this.currentStaff < this.maxStaff){
      this.$router.push({ name: "Shop_Edit_Staff" });
    }
  }

  toggleDelete(userId: string, name: string) {
    this.selectedName = name;
    this.selectedId = userId;
    this.confirmDialog = true;
  }

  async deleteStaff() {
    const deleteResult = await BrandAPIConnector.deleteUser(this.selectedId)
    if (deleteResult) {
      this.$store.dispatch("alert/success", "ลบสำเร็จ");
      this.$router.go(0);
    } else {
      this.$store.dispatch("alert/error", "ไม่สามารถลบได้");
    }
  }

  cancel() {
    this.selectedName = "";
    this.selectedId = "";
    this.confirmDialog = false;
  }
}
