

















































import { Component, Vue, Watch } from "vue-property-decorator";
import validator from "validator";
import BrandAPIConnector from "@/service/BrandAPIConnector";
import { mapGetters } from 'vuex'

@Component({
  components: {},
  computed: {
    ...mapGetters("brand", ["brandProfile"]),
  },
})

export default class ShopEditBranch extends Vue {
  branchProfileData: any = {
    name: {
      data: "",
      warning: "",
      isChange: false,
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุชื่อสาขา"
        },
        function (this: any, v: string) {
          if(v.length > 35) return "ชื่อร้านและชื่อสาขารวมกันมีความยาวเกิน 35 ตัวอักษร"
          return true
        },
        function (this: any, v: string) {
          if(v.includes('สาขา')) return "ไม่จำเป็นต้องกรอกคำว่า 'สาขา'"
          return true
        },
      ],
    },
    id: {
      data: "",
      rules: []
    }
  };

  brandId: string = "";

  isEditMode = false;
  isLoading = false;

  branchName: Array<string> = [];

  mounted() {
    this.loadShopOwnerProfile();
    if (this.$route.params.id) {
      this.branchProfileData.name.data = this.$route.params.name;
      this.branchProfileData.id.data = this.$route.params.id;
      this.isEditMode = true;
    } else {
      this.isEditMode = false;      
    }
  }

  async loadShopOwnerProfile() {
    this.isLoading = true;
    const brandUser = await BrandAPIConnector.getUserBrand();
    if (brandUser) {
      this.brandId = brandUser.brand_id;
      const allBranch = await BrandAPIConnector.fetchAllBranch(this.brandId);
      if (allBranch) {
        for (let i = 0; i < allBranch.length; i++) {
          const allDetail = {
            id: allBranch[i]._id,
            name: allBranch[i].branch_name
          };
          this.branchName.push(allBranch[i].branch_name);
        }
      }
    } else {
      this.$store.dispatch("alert/error", "ไม่สามารถดึงข้อมูลได้");
      this.$router.push({ name: "Shop_All_Staff" });
    }
    this.isLoading = false;
  }

  toggleEditMode() {
    this.isEditMode = true;
  }

  doValidate(field: any) {
    let valid = true;
    field.rules.forEach((validateCheck: any) => {
      const result = validateCheck.bind(this)(field.data);
      if (result != true) {
        field.warning = result;
        valid = false;
      }
    });
    if (valid) field.warning = "";
    return valid;
  }

  // Be careful with this recursive function
  validateAll(data: any[] = this.branchProfileData) {
    let valid = true;
    for (const key in data) {
      if (data[key].hasOwnProperty("data")) {
        const result = this.doValidate(data[key]);
        if (!result) {
          valid = false;
        }
      } else {
        const result = this.validateAll(data[key]);
        if (!result) {
          valid = false;
        }
      }
    }
    return valid;
  }

  async save() {
    const valid = this.validateAll();
    console.log("Validate result : " + valid);
    if (valid) {
      this.isLoading = true;
      const formData = new FormData();
      
      this.branchProfileData.name.isChange &&
        formData.append("name", this.branchProfileData.name.data);

      if (this.isEditMode) {
        // Check if formData empty because of edit nothing.
        const isEdit = formData.entries().next().value ? true : false;
        if (!isEdit) {
          console.log("nothing change");
          this.isLoading = false;
          return;
        }
        console.log('edut branch');
        const patchResult = await BrandAPIConnector.editBranch(
          this.branchProfileData.id.data,
          formData
        );
        if (patchResult) {
          this.$store.dispatch("alert/success", "แก้ไขข้อมูลเรียบร้อย");
          this.$router.push({ name: "Shop_All_Branch" });
        } else {
          this.$store.dispatch("alert/error", "ไม่สามารถแก้ไขข้อมูลได้");
        }
      } else {
        formData.append("brandId", this.brandId);

        const createResult = await BrandAPIConnector.createNewBranch(
          formData
        );
        if (createResult) {
          if (createResult.error) {
            this.$store.dispatch("alert/error", createResult.error);
          } else {
            this.$store.dispatch("alert/success", "สร้างสาขาใหม่สำเร็จ");
            this.$router.push({ name: "Shop_All_Branch" });
          }
        } else {
          this.$store.dispatch("alert/error", "ไม่สามารถสร้างสาขาใหม่ได้");
        }
      }
      this.isLoading = false;
    }
  }
  back() {
    this.$router.push({ name: "Shop_All_Branch" });
  }
}
