

























































import { Component, Vue } from "vue-property-decorator";
import ScanCode from "@/components/branch/ScanCode.vue";

@Component({
  components: { ScanCode },
})
export default class BranchRedeemCoupon extends Vue {
  toggleScanCode: boolean = false;

  confirmDialog: boolean = false;

  valid: boolean = true
  rewardCode: string = ''
  errorMessages: string = ''
  rules:Array<Function> = [
    (value: string) => !!value || '* กรุณากรอกรีวอร์ด',
    (value: string) => !(value.length > 10) || '* รีวอร์ดไม่ถูกต้อง',
    (value: string) => !(value.length < 10) || '* รีวอร์ดไม่ถูกต้อง',
    (value: string) =>!!(new RegExp('^[a-z0-9_.]+$')).test(value) || '* รีวอร์ดไม่ถูกต้อง'
  ]

  mounted() {
    if(this.$route.params.rewardCode){
      this.rewardCode = this.$route.params.rewardCode
    }
  }

  handleChange () {
    if (this.errorMessages != "") {
      this.errorMessages = ""
    }
  }

  confirmRewardCode () {
    //@ts-ignore
    if (this.$refs.form.validate()) {
      this.onDecode(this.rewardCode)
    }
  }

  onDecode(couponCode: string) {
    this.$router.push(`redeem-coupon/${couponCode}`);
    this.confirmDialog = true;
  }

  // async pasteRewardCode() {
  //   const text = await navigator.clipboard.readText();
  //   this.rewardCode = text
  // }

}
