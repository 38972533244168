




























import { Component, Vue } from "vue-property-decorator";
import AppBar from "@/components/AppBar.vue";
import ResetByTel from "@/components/forgetPassword/ResetByTel.vue";
import VerifyOTP from "@/components/forgetPassword/VerifyOTP.vue";
import NewPassword from "@/components/forgetPassword/NewPassword.vue";
import BasedAPIConnector from "@/service/BasedAPIConnector";

type userData = {
  phone: string
  brandId: string
}

@Component({
  components: {
    AppBar,
    ResetByTel,
    VerifyOTP,
    NewPassword
  },
})

export default class ForgetPassword extends Vue {
  sableLogoURL: string = require(`@/assets/img/sable_logo.svg`);
  brandId: string = ''
  phone: string = ''
  token: string = ''
  step: number = 1

  nextStep2(data: userData) {
    this.brandId = data.brandId;
    this.phone = data.phone;
    this.step = 2
  }
  
  nextStep3(token: string) {
    if(token) {
      this.token = token
      this.phone = ''
      this.step = 3
    }
  }

  onDone() {
    this.back()
  }

  back() {
    this.brandId = ''
    this.phone = ''
    this.$router.push({ name: "Login" });
  }
}
