import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@/filters";
import * as liff from "@line/liff";
import vueDebounce from "vue-debounce";
//@ts-ignore
import Fragment from "vue-fragment";
import VueMeta from "vue-meta";
import VConsole from 'vconsole';
import VueClipboard from 'vue-clipboard2'

if( process.env.NODE_ENV === 'development') {
  const vConsole = new VConsole();
}

Vue.use(vueDebounce);
Vue.use(Fragment.Plugin);
Vue.use(VueMeta);
Vue.use(VueClipboard)

Vue.config.productionTip = false;
Vue.prototype.$liff = liff;


new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

export const $liff = liff;
