

























































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
import CustomerAPIConnector from "@/service/CustomerAPIConnector";
import { CustomerProfile } from "@/common/type";

@Component({
  components: {},  
  computed: {
    ...mapGetters("customer", ["userProfile"])
  },
})
export default class ConsentModal extends Vue {
  @Prop({default: false})
  private isShow!: boolean;
  @Prop({default: false})
  private isShowButton!: boolean;

  userProfile!:CustomerProfile;
  //@ts-ignore
  liff: Liff = this.$liff;
  lineAccessToken: string = "";
  lineIdToken: string = "";

  mounted() {
    this.lineAccessToken = this.liff.getAccessToken() + "";
    this.lineIdToken = this.liff.getIDToken() + "";
  }

  toggleModal (value: boolean) {
    this.$emit('toggleModal', value)
  }

  async acceptConsent () {
    const response = await CustomerAPIConnector.updateConsentAccept(this.userProfile._id, this.lineAccessToken, this.lineIdToken)
    if(response) {
      this.$store.commit('customer/setUserProfile', response)
      this.toggleModal(false)
    }
  }
}
