

















































































































import { Component, Vue } from "vue-property-decorator";
import { Gender, GenderLabels, stringToGender } from "@/common";
import { CustomerDetail } from "@/common/type";
import moment from "moment";
import BrandAPIConnector from "@/service/BrandAPIConnector";
import ModalEditPoint from "@/components/owner/customer/ModalEditPoint.vue";

@Component({
  components: {
    ModalEditPoint
  },
})
export default class ShopOwnerCustomerProfile extends Vue {
  userAvatarPlaceHolder: string = "";
  genderLabel = GenderLabels;
  userDetail = {
    userId: "",
    firstname: "",
    lastname: "",
    birthdate: "",
    phone: "",
    gender: Gender.male,
    email: "",
  };
  userAvatarUrl = this.userAvatarPlaceHolder;
  defaultUserLogo: string = require(`@/assets/img/sable-gray.svg`);
  userPoint = 0;
  purchaseValue = 0;
  isLoading: boolean = false;
  isShowModalEditPoint: boolean = false;

  mounted() {
    this.loadUserData(this.$route.params.id)
  }

  async loadUserData(userId: string) {
    this.isLoading = true;
    const result = await BrandAPIConnector.getCustomerProfile(userId);
    if (result) {
      this.userAvatarUrl = result.img;
      this.userDetail = {
        userId,
        firstname: result.firstname,
        lastname: result.lastname,
        birthdate: result.birthdate,
        phone: result.phone,
        gender: stringToGender[result.gender],
        email: result.email,
      };
      this.userPoint = result.customer_stats.point;
      this.purchaseValue = result.customer_stats.purchase_value;
    } else {
      this.$store.dispatch("alert/error", "ไม่สามารถดึงข้อมูลลูกค้าได้");
      this.$router.push({ name: "ShopOwner_CustomerList" });
    }
    this.isLoading = false;
  }

  toggleEditPointModal (value: boolean) {
    this.isShowModalEditPoint = value;
    if (!value) {
      this.loadUserData(this.$route.params.id)
    }
  }

  updatePoint () {
    this.isShowModalEditPoint = true;
  }

  replaceDefault(e: any) {
    e.target.src = this.defaultUserLogo;
  }

  back() {
    this.$router.push({ name: "ShopOwner_CustomerList"});
  }
}
